import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { t } from "i18next";
import { Card, Col, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import {
  getCorrectLanguage,
  getCorrectStatusWorkflow,
} from "../../../shared/functions/Functions";
import { IExport } from "../../exports/dto/IExports";
import { ClientService } from "../../clients/service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WorkflowStepper } from "./WorkflowStepper";
import { UserService } from "../../users/service";
import { useNavigate } from "react-router";
import { WorkflowService } from "../service";
import { IWorkflow } from "../dto/IWorkflow";

interface IWorkflowFormProps {
  firstStap?: IWorkflow;
  setFirstStap: React.Dispatch<SetStateAction<IWorkflow | undefined>>;
  workflowStatus: string[];
}

export const WorkflowCreate = ({
  firstStap,
  setFirstStap,
  workflowStatus,
}: IWorkflowFormProps) => {
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [copywriterList, setCopywriterList] = useState([]);
  const [qualityControlList, setQualityControlList] = useState([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  // const [firstStap, setFirstStap] = useState();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const navigate = useNavigate();
  const checkBoxArray = ["baseData", "attributes", "content"];
  const status = ["status 1", "status 2", "status 3"];

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFirstStap((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  function handleSelectSingle4(selectedSingle: SingleValue<any | null>) {
    setFirstStap((prev: any | undefined) => ({
      ...prev,
      language: prev?.language?.filter(
        (lang: string) => lang !== selectedSingle?.value
      ),
      sourceLang: selectedSingle?.value as string,
    }));
  }
  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: string;
      nesto: string;
    }>,
    title: string
  ) {
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.value as string,
    }));
  }
  function handleSelectSingle2(selectedSingle: any, title: string) {
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(value as unknown as Date);

    setFirstStap((prev: any) => ({
      ...prev,
      deadlineData: value,
    }));
  }

  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const checkBoxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    setFirstStap((prev: any) => ({
      ...prev,
      [item]: event.target.checked,
    }));
  };
  useEffect(() => {
    UserService.getAllUsers({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        // setCopywriterList(
        //   data?.filter((copywriter) => copywriter.isCopywriter) as any
        // );
        setCopywriterList(
          data?.filter((copywriter) =>
            copywriter?.roles?.includes("ROLE_MANAGER")
          ) as any
        );
        setQualityControlList(
          data?.filter((copywriter) => copywriter.qualityControlPerson) as any
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <Row>
        <Col md={4}>
          <label htmlFor="name" className="required-field">
            {`${t("global.name")}`}
          </label>
          <input
            type="text"
            name="name"
            placeholder={`${t("mapping.type")}...`}
            className="form-control"
            // value={(firstStap?.name) as string ?? ""}
            required
            onChange={changeHandler}
          />
        </Col>

        <Col md={4}>
          <label htmlFor="name" className="">
            {`${t("global.manager")}`}
          </label>
          <Select
            className="react-select-field"
            theme={themeSelect}
            isMulti={true}
            onChange={(e) => handleSelectSingle2(e as any, "copywriter")}
            options={copywriterList?.map((value: any) => ({
              label:
                value?.firstName?.concat(" ", value?.lastName) +
                ` (${value.copywriterAssignment})`,
              value: value?.id,
            }))}
          />
        </Col>
        <Col md={4}>
          <label htmlFor="client" className="required-field">
            {`${t("export_client.client")}`}
          </label>
          <Select
            className="react-select-field"
            theme={themeSelect}
            onChange={(e) => {
              handleSelectSingle(e as any, "client");
            }}
            options={clientList as any}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4} className="mt-3">
          <label htmlFor="language" className="">
            {`${t("export_client.language")}`}
          </label>
          <Select
            key={firstStap?.id}
            className="react-select-field"
            theme={themeSelect}
            value={firstStap?.language?.map((language: string) => ({
              value: language,
              label: getCorrectLanguage(language, t),
            }))}
            onChange={(e) => {
              handleSelectSingle2(e as any, "language");
            }}
            isMulti={true}
            options={(translationLanguageEnum as any)
              ?.map((language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              }))
              .filter((lang: { label: string; value: string }) => {
                return lang.value !== firstStap?.sourceLang;
              })}
          />
        </Col>
        <div className="col-md-4 mt-3">
          <label htmlFor="language" className="">
            {`${t("import.deadline_date")}`}
          </label>
          <div className="datepicker-container">
            <DatePicker
              className="date-picker"
              selected={startDate}
              onChange={(date) => handleDate(date as any)}
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText="Choose deadline date..."
            />
          </div>
        </div>
        <Col md={4} className="mt-3">
          <label htmlFor="language" className="required-field">
            {`${t("global.source_lang")}`}
          </label>
          <Select
            isDisabled={firstStap?.id ? true : false}
            className="react-select-field"
            theme={themeSelect}
            key={firstStap?.id}
            defaultValue={
              firstStap?.sourceLang && {
                label: getCorrectLanguage(firstStap?.sourceLang, t),
                value: firstStap?.sourceLang,
              }
            }
            onChange={(e) => {
              handleSelectSingle4(e as any);
            }}
            options={(translationLanguageEnum as any)?.map(
              (language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              })
            )}
          />
        </Col>
        <Col md={4} className="mt-3">
          <label htmlFor="roles">{t("export_client.productType")}</label>
          <div className="d-flex min-input-height justify-content-center align-items-center">
            {checkBoxArray.map((item) => (
              <>
                <input
                  className="form-check-input m-0 cursor-pointer"
                  type="checkbox"
                  onChange={(e) => checkBoxHandler(e, item)}
                />
                <label className="m-0 px-3">{t(`export_client.${item}`)}</label>
              </>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};
