// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProductVariation } from "../dto/IProducts";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { BlockService } from "../../block/service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _debounce from "lodash/debounce";
import { ContentWordsChart } from "../content/components/ContentWordsChart";
import "../../../ckeditor.css";
// kreiranje variacije

interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}
interface IEditorCounter {
  [key: string]: ICounts;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
interface IProductVariationFormProps {
  currentVariation: IProductVariation;
  setCurrentVariation: any;
  changeBlocknHandler: (value: string, blockId: number) => void;
  changeVariationHandler: (
    event: ContentEditableEvent | React.ChangeEvent<HTMLInputElement>
  ) => void;
  certainBlocks: any;
  productStaticts?: any;
}

export const ProductVariationForm = ({
  setCurrentVariation,
  currentVariation,
  changeVariationHandler,
  certainBlocks,
  changeBlocknHandler,
  productStaticts = {},
}: IProductVariationFormProps) => {
  const { t } = useTranslation();
  const language = "en";
  //set langauge for create variation
  const [allBlocks, setAllBlocks] = useState([] as any);
  const [activeBlocks, setActiveBlocks] = useState([] as any);
  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  useEffect(() => {
    if (!!allBlocks.length) {
      const currentlyBlocksForProduct = getCertainBlocks(certainBlocks as any);
      setActiveBlocks(currentlyBlocksForProduct);
    }
  }, [allBlocks.length]);

  const getCertainBlocks = (block: number[]) => {
    const data: any[] = [];
    for (let i = 0; i < block?.length; i++) {
      data?.push(allBlocks?.find((el: { id: number }) => el?.id === block[i]));
    }
    return data;
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setCurrentVariation((prev: any) => ({ ...prev, [eventName]: value }));
  };

  return (
    <div className="row gy-3">
      <div className="col-md-6">
        <label htmlFor="title" className="required-field">
          {t("products.title")}
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          onChange={changeVariationHandler}
          value={currentVariation?.title ?? null}
          placeholder={t("products.enter_title")}
        />
      </div>

      <div className="col-12 ">
        <label htmlFor="introText">{t("products.description")}</label>
        {/* {currentVariation && (
          <> */}
        <CKEditor
          config={editorConfigurationCustom}
          editor={Editor}
          data={currentVariation?.description ?? ""}
          onReady={(editor) => {
            editor.ui.view.element.setAttribute(
              "id",
              String(Math.random().toString(16).slice(2))
            );
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            setWordCount((prev) => ({
              ...prev,
              [language]: {
                ...prev?.[language],
                description: {
                  ...prev?.[language]?.["description"],
                  words: wordsCount,
                  characters: charactersCount,
                },
              },
            }));
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            changeHandlerEditor(data, "description");
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            updateWordsCound(
              {
                wordsCount,
                charactersCount,
                language,
              },
              "description"
            );
          }}
        />
        {/* <div className="d-flex justify-content-start text-align-center mt-3">
          <div className="ck ck-word-count">
            <div className="ck-word-count__words">
              {t("global.words")}:{" "}
              {wordCount?.[language]?.["description"]?.words}
            </div>
            <div className="ck-word-count__characters">
              {t("global.characters")}:{" "}
              {wordCount?.[language]?.["description"]?.characters}
            </div>
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="ck ck-word-count">
            <div className="ck-word-count__words">
              {t("global.words")}:
              {wordCount?.[language]?.["description"]?.words}
            </div>
            <div className="ck-word-count__characters">
              {t("global.characters")}:{" "}
              {wordCount?.[language]?.["description"]?.characters}
            </div>
          </div>
          <div>
            {productStaticts?.contentRequirements?.[language]?.["description"]
              ?.wordCountHigh > 0 && (
              <ContentWordsChart
                words={wordCount?.[language]?.["description"]?.words}
                medium={
                  productStaticts?.contentRequirements?.[language]?.[
                    "description"
                  ]?.wordCountMedium
                }
                high={
                  productStaticts?.contentRequirements?.[language]?.[
                    "description"
                  ]?.wordCountHigh
                }
              />
            )}
          </div>
        </div>
        {/* </>
        )} */}
      </div>
      <div className="col-12 ">
        <label htmlFor="introText">{t("products.introText")}</label>
        {/* {currentVariation && (
          <> */}
        <CKEditor
          config={editorConfigurationCustom}
          editor={Editor}
          data={currentVariation?.introText ?? ""}
          onReady={(editor) => {
            editor.ui.view.element.setAttribute(
              "id",
              String(Math.random().toString(16).slice(2))
            );
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            setWordCount((prev) => ({
              ...prev,
              [language]: {
                ...prev?.[language],
                introText: {
                  ...prev?.[language]?.["introText"],
                  words: wordsCount,
                  characters: charactersCount,
                },
              },
            }));
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            changeHandlerEditor(data, "introText");
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            updateWordsCound(
              {
                wordsCount,
                charactersCount,
                language,
              },
              "introText"
            );
          }}
        />
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="ck ck-word-count">
            <div className="ck-word-count__words">
              {t("global.words")}:{wordCount?.[language]?.["introText"]?.words}
            </div>
            <div className="ck-word-count__characters">
              {t("global.characters")}:{" "}
              {wordCount?.[language]?.["introText"]?.characters}
            </div>
          </div>
          <div>
            {productStaticts?.contentRequirements?.[language]?.["introText"]
              ?.wordCountHigh > 0 && (
              <ContentWordsChart
                words={wordCount?.[language]?.["introText"]?.words}
                medium={
                  productStaticts?.contentRequirements?.[language]?.[
                    "introText"
                  ]?.wordCountMedium
                }
                high={
                  productStaticts?.contentRequirements?.[language]?.[
                    "introText"
                  ]?.wordCountHigh
                }
              />
            )}
          </div>
        </div>
        {/* </>
        )} */}
      </div>
      <div className="col-12 ">
        <label htmlFor="bullets">{t("products.bullets")}</label>
        {/* {currentVariation && (
          <> */}
        <CKEditor
          // config={{ removePlugins: ["Title"] }}
          config={editorConfigurationCustom}
          editor={Editor}
          data={currentVariation?.bullets ?? ""}
          onReady={(editor) => {
            editor.ui.view.element.setAttribute(
              "id",
              String(Math.random().toString(16).slice(2))
            );
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            setWordCount((prev) => ({
              ...prev,
              [language]: {
                ...prev?.[language],
                bullets: {
                  ...prev?.[language]?.["bullets"],
                  words: wordsCount,
                  characters: charactersCount,
                },
              },
            }));
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            changeHandlerEditor(data, "bullets");
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            updateWordsCound(
              {
                wordsCount,
                charactersCount,
                language,
              },
              "bullets"
            );
          }}
        />
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="ck ck-word-count">
            <div className="ck-word-count__words">
              {t("global.words")}:{wordCount?.[language]?.["bullets"]?.words}
            </div>
            <div className="ck-word-count__characters">
              {t("global.characters")}:{" "}
              {wordCount?.[language]?.["bullets"]?.characters}
            </div>
          </div>
          <div>
            {productStaticts?.contentRequirements?.[language]?.["bullets"]
              ?.wordCountHigh > 0 && (
              <ContentWordsChart
                words={wordCount?.[language]?.["bullets"]?.words}
                medium={
                  productStaticts?.contentRequirements?.[language]?.["bullets"]
                    ?.wordCountMedium
                }
                high={
                  productStaticts?.contentRequirements?.[language]?.["bullets"]
                    ?.wordCountHigh
                }
              />
            )}
          </div>
        </div>
        {/* </>
        )} */}
      </div>
      {!!activeBlocks?.length &&
        activeBlocks?.map((block: any) => (
          <div className="col-12">
            <label htmlFor="value">{block.name}</label>
            {/* {currentVariation && (
              <> */}
            <CKEditor
              config={{ removePlugins: ["Title", "Markdown"] }}
              // config={editorConfigurationCustom}
              editor={Editor}
              data={currentVariation?.blocks?.[block?.id] ?? ""}
              onReady={(editor) => {
                editor.ui.view.element.setAttribute(
                  "id",
                  String(Math.random().toString(16).slice(2))
                );
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                setWordCount((prev) => ({
                  ...prev,
                  [language]: {
                    ...prev?.[language],
                    [block.name]: {
                      ...prev?.[language]?.[block.name],
                      words: wordsCount,
                      characters: charactersCount,
                    },
                  },
                }));
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeBlocknHandler(data, block?.id);
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                updateWordsCound(
                  {
                    wordsCount,
                    charactersCount,
                    language,
                  },
                  block.name
                );
              }}
            />
            <div className="d-flex justify-content-start text-align-center mt-3">
              <div className="ck ck-word-count">
                <div className="ck-word-count__words">
                  {t("global.words")}:{" "}
                  {wordCount?.[language]?.[block.name]?.words}
                </div>
                <div className="ck-word-count__characters">
                  {t("global.characters")}:{" "}
                  {wordCount?.[language]?.[block.name]?.characters}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProductVariationForm;
