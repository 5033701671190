import { Card } from "react-bootstrap";
import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICategoryCreate } from "../dto/ICategory";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import Select, { MultiValue, SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { ProductsService } from "../../../products/service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useNavigate } from "react-router-dom";

interface ICategoryFormProps {
  currentCategory: ICategoryCreate;
  isMenuOpened: boolean;
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  handleCreate: () => void;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeTranslationHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => void;
  setCurrentCategory: React.Dispatch<SetStateAction<ICategoryCreate>>;
}

export const CategoryForm = ({
  currentCategory,
  handleCreate,
  changeTranslationHandler,
  isMenuOpened,
  setIsMenuOpened,
  changeHandler,
  setCurrentCategory,
}: ICategoryFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState("");
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentCategory?.translation?.[sourceLanguage]?.title,
        sourceLanguage: sourceLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentCategory((prev: any) => ({
            ...prev,
            translation: {
              ...prev?.translation,
              [langArr[i]]: {
                ...prev?.translation?.[langArr[i]],
                title: result,
              },
            },
            parent: 0,
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }
  return (
    <Card>
      <Card.Body>
        {!isMenuOpened ? (
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">{t("global.categories")}</h4>
            <div>
              <>
                {accessRights?.includes("category_can_manage") && (
                  <button
                    className="btn btn-secondary me-2"
                    onClick={() => navigate("/admin/category/values/assign")}
                  >
                    {t("global.manage")}
                  </button>
                )}
              </>

              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex">
                <Select
                  className="react-select-field me-2"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />
                <Select
                  className="custom_zindex react-select-field me-2"
                  theme={themeSelect}
                  placeholder={`${t("workflow.translate_from")}`}
                  isClearable={true}
                  options={translationLanguageEnum?.map((lang) => ({
                    value: lang,
                    label: getCorrectLanguage(lang, t),
                  }))}
                  //   key={state?.id}
                  onChange={(e) => {
                    handleSelectSingle(e as any);
                  }}
                />
                <button className="btn btn-secondary" onClick={handleTranslate}>
                  {t("global.translate")}
                </button>
              </div>
            </div>
            <div className="row">
              {translationLanguageEnum?.map((language, index) => {
                return (
                  <div className="col-md-3 my-1" key={`Language - ${index}`}>
                    <label htmlFor={`title-${language}`}>
                      {t("category.enterTitle")}{" "}
                      {getCorrectLanguage(language, t)}
                    </label>
                    <input
                      type="text"
                      id={`title-${language}`}
                      name="title"
                      value={
                        currentCategory?.translation?.[language]?.title ?? ""
                      }
                      onChange={(e) =>
                        changeTranslationHandler(e, language as string)
                      }
                      className="form-control"
                    />
                  </div>
                );
              })}
              <div className="col-12">
                <span className="text-danger">
                  * {t("category.mandatoryField")}
                </span>
              </div>
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <button
                className="btn btn-info me-2"
                onClick={() => setIsMenuOpened(false)}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary" onClick={handleCreate}>
                {t("global.create")}
              </button>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
