// @ts-nocheck
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DefaultEditor } from "react-simple-wysiwyg";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IAllStates } from "../../../store/rootReducer";
import i18n from "../../../utils/translations/i18next";
import { ContentService } from "../service";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { ProductsService } from "../../products/service";
// import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { getCorrectFormality } from "../../../shared/functions/Functions";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _debounce from "lodash/debounce";
import "../../../ckeditor.css";

interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}
interface IEditorCounter {
  [key: string]: ICounts;
}

interface ContentUpdateVariationProps {
  setCurrentVariation: any;
  currentVariation: any;
  showVariationModal: boolean;
  handleClose: any;
  variationId?: number;
  setTriggerUpdate?: any;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};

export const ContentUpdateVariation = ({
  currentVariation,
  showVariationModal,
  handleClose,
  variationId,
  setCurrentVariation,
  setTriggerUpdate,
}: ContentUpdateVariationProps) => {
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  let titleTranslate = "";
  let textTranslate = "";
  const { t } = useTranslation();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const currentLanguage = i18n?.language;
  const [activeTab, setActiveTab] = useState("en");
  const [formality, setFormality] = useState(null);
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  const changeVariationTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    const { value, name } = event.target;
    setCurrentVariation((prev: any) => ({
      ...prev,
      [language]: {
        ...prev[language],
        [name]: value,
      },
    }));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }

  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
  }

  const handleTranslate = () => {
    ContentService.postTranslation({
      text: currentVariation?.[sourceLanguage]?.text,
      title: currentVariation?.[sourceLanguage]?.title,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response: any) => {
        if (
          currentVariation?.[targetLanguage]?.text === "" ||
          currentVariation?.[targetLanguage]?.text === undefined ||
          currentVariation?.[targetLanguage]?.text === null
        ) {
          textTranslate = response[1];
        } else {
          textTranslate = currentVariation?.[targetLanguage]?.text.concat(
            " ",
            response[1]
          );
        }
        if (
          currentVariation?.[targetLanguage]?.title === "" ||
          currentVariation?.[targetLanguage]?.title === undefined ||
          currentVariation?.[targetLanguage]?.title === null
        ) {
          titleTranslate = response[0];
        } else {
          titleTranslate = currentVariation?.[targetLanguage]?.title.concat(
            " ",
            response[0]
          );
        }
        setCurrentVariation((prev: any) => ({
          ...prev,
          [targetLanguage]: {
            ...prev[targetLanguage],
            title: titleTranslate,
            text: textTranslate,
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error: any) => ErrorToast(error));
  };
  const handleUpdateVariation = (event: React.FormEvent, language: string) => {
    event.preventDefault();
    ContentService.updateVariation(
      {
        lang: language,
        text: currentVariation?.[language]?.text,
        title: currentVariation?.[language]?.title,
      } as any,
      Number(variationId)
    )
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
        setTriggerUpdate((prev: any) => !prev);
      })
      .catch((error) => ErrorToast(error));
  };
  const changeHandlerEditor = (
    value: string,
    language: string,
    eventName: string
  ) => {
    setCurrentVariation((prev: any) => ({
      ...prev,
      [language]: {
        ...prev[language],
        [eventName]: value,
      },
    }));
  };

  return (
    <div>
      <Modal show={showVariationModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("products.variation.updateVariation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul
            className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
          >
            {translationLanguageEnum?.map((language) => {
              return (
                <li
                  className="nav-item"
                  key={language}
                  onClick={() => changeActiveTab(language)}
                >
                  <a
                    className={`nav-link ${
                      activeTab === language ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href={`#${language}`}
                    role="tab"
                    aria-selected={language === currentLanguage}
                  >
                    {t(`products.content_${language}`)}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content">
            {translationLanguageEnum?.map((language) => {
              return (
                <>
                  <div
                    className={`tab-pane  ${
                      activeTab === language ? "active" : ""
                    }`}
                    id={language}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-12 pt-3">
                        <div className="d-flex justify-content-end align-items-center">
                          <>
                            <Select
                              className="custom_zindex react-select-field me-2"
                              name="content"
                              theme={themeSelect}
                              placeholder={`${t("formality.select_formality")}`}
                              options={deeplFormality?.map((formality) => ({
                                value: formality,
                                label: getCorrectFormality(formality, t),
                              }))}
                              // key={state?.id}
                              onChange={(e) => {
                                handleSelectSingleFormality(e as any);
                              }}
                            />
                            <div className="mt-1">
                              <Select
                                className="custom_zindex react-select-field"
                                name="content"
                                theme={themeSelect}
                                placeholder={`${t("workflow.translate_from")}`}
                                options={translationLanguageEnum
                                  .filter((lang) => lang !== language)
                                  ?.map((lang) => ({
                                    value: lang,
                                    label: getCorrectLanguage(lang, t),
                                  }))}
                                // key={state?.id}
                                onChange={(e) => {
                                  handleSelectSingle(
                                    e as any,
                                    language as string
                                  );
                                }}
                              />
                            </div>
                            <button
                              className="btn btn-secondary ms-3"
                              onClick={handleTranslate}
                            >
                              {t("global.translate")}
                            </button>
                          </>
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="m-0 required-field" htmlFor="content">
                          {t("global.title")}
                        </label>
                        <div className="d-flex  align-items-center justify-content-between">
                          <div className="col-md-6">
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              value={currentVariation?.[language]?.title ?? ""}
                              required
                              onChange={(e) =>
                                changeVariationTranslationHandler(e, language)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            className="m-0 required-field"
                            htmlFor="content"
                          >
                            {t("products.content")}
                          </label>
                        </div>
                        <CKEditor
                          // config={{ removePlugins: ["Title"] }}
                          config={editorConfigurationCustom}
                          editor={Editor}
                          data={currentVariation?.[language]?.text ?? ""}
                          onReady={(editor) => {
                            editor.ui.view.element.setAttribute(
                              "id",
                              String(Math.random().toString(16).slice(2))
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            setWordCount((prev) => ({
                              ...prev,
                              [language]: {
                                ...prev?.[language],
                                text: {
                                  ...prev?.[language]?.["text"],
                                  words: wordsCount,
                                  characters: charactersCount,
                                },
                              },
                            }));
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(
                              data,
                              language as string,
                              "text"
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "text"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-start text-align-center mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              {t("global.words")}:{" "}
                              {wordCount?.[language]?.["text"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              {t("global.characters")}:{" "}
                              {wordCount?.[language]?.["text"]?.characters}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Modal.Footer>
                        <Button variant="info" onClick={handleClose}>
                          {t("global.cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={(e) => handleUpdateVariation(e, language)}
                        >
                          {t("category.save")}
                        </Button>
                      </Modal.Footer>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
