import React, { useEffect, useState } from "react";
import { ContentStepper } from "./ContentStepper";
import { useParams } from "react-router";
import { Button, Card, Row } from "react-bootstrap";
import { WorkflowStep7List } from "../../workflow/components/WorkflowStep7List";
import WorkflowStep7Chart from "../../workflow/components/WorkflowStep7Chart";
import { ContentWorkflowService } from "../service";
import { useTranslation } from "react-i18next";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { Table } from "react-bootstrap";
import { EmptyState } from "../../../shared/components/EmptyState";

export const ContentStep5 = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [copywriterList, setCopywriterList] = useState([]);
  const [translatorList, setTranslatorList] = useState([]);
  const [stats, setStats] = useState<any>();

  useEffect(() => {
    ContentWorkflowService.getWorkflowCharts(+id!)
      .then((res) => {
        setStats(res);
        setCopywriterList(res.copywritersData);
        setTranslatorList(res.translatorsData);
      })
      .catch((err) => ErrorToast(err));
  }, []);
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper step={"OVERVIEW"} id={id}></ContentStepper>
          <div className="row mb-5">
            <WorkflowStep7Chart
              name="Copywriting"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.copyWritingData?.total}
              done={stats?.copyWritingData?.done}
            />
            <WorkflowStep7Chart
              name="Translation"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.translationData?.total}
              done={stats?.translationData?.done}
            />
          </div>
          <>
            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-header pb-0">
                    <div className="d-flex justify-content-start">
                      <h4 className="card-title mg-b-0">
                        {t("workflow.copywriters")}
                      </h4>
                    </div>
                  </div>
                  {copywriterList?.length !== 0 ? (
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">{`${t("global.name")}`}</th>
                              <th scope="col">{`${t("global.email")}`}</th>
                              <th scope="col">{`${t("global.assigned")}`}</th>
                              <th scope="col">{`${t(
                                "status_workflow.DONE"
                              )}`}</th>
                              <th scope="col">{`${t("users.status")}`}</th>
                              {/* <th scope="col">{`${t(
                                "workflow.assignment"
                              )}`}</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {copywriterList?.map((user: any) => {
                              return (
                                <tr>
                                  <td>{user?.user?.id}</td>
                                  <td>
                                    {user?.user?.firstName +
                                      " " +
                                      user?.user?.lastName}
                                  </td>
                                  <td>{user?.user?.email}</td>
                                  <td>{user?.assigned}</td>
                                  <td>{user?.done}</td>
                                  <td
                                    className={`text-${
                                      user?.user?.isActive
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {user?.user?.isActive ? (
                                      <span>
                                        <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                                        {t("global.active")}
                                      </span>
                                    ) : (
                                      <span>
                                        <i className="ri-close-circle-line fs-17 align-middle" />
                                        {t("global.inactive")}
                                      </span>
                                    )}
                                  </td>
                                  {/* <td>{user.copywriterAssignment}</td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <EmptyState />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header pb-0">
                    <div className="d-flex justify-content-start">
                      <h4 className="card-title mg-b-0">
                        {t("workflow.translators")}
                      </h4>
                    </div>
                  </div>
                  {translatorList?.length !== 0 ? (
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">{`${t("global.name")}`}</th>
                              <th scope="col">{`${t("global.email")}`}</th>
                              <th scope="col">{`${t("global.assigned")}`}</th>
                              <th scope="col">{`${t(
                                "status_workflow.DONE"
                              )}`}</th>
                              <th scope="col">{`${t("users.status")}`}</th>
                              {/* <th scope="col">{`${t("workflow.assignment")}`}</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {translatorList?.map((user: any) => {
                              return (
                                <tr>
                                  <td>{user.user.id}</td>
                                  <td>
                                    {user.user.firstName +
                                      " " +
                                      user.user.lastName}
                                  </td>
                                  <td>{user.user.email}</td>
                                  <td>{user?.assigned}</td>
                                  <td>{user?.done}</td>
                                  <td
                                    className={`text-${
                                      user?.user?.isActive
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {user?.user?.isActive ? (
                                      <span>
                                        <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                                        {t("global.active")}
                                      </span>
                                    ) : (
                                      <span>
                                        <i className="ri-close-circle-line fs-17 align-middle" />
                                        {t("global.inactive")}
                                      </span>
                                    )}
                                  </td>
                                  {/* <td>{user.user.translatorAssignment}</td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <EmptyState />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </Card.Body>
      </Card>
    </>
  );
};
