import { t } from "i18next";
import React from "react";
import CountUp from "react-countup";
const WorkflowStep7Chart = ({
  iconClass,
  name,
  total,
  done,
  copywriters,
  translators,
  activeInProgress = true,
}: any) => {
  return (
    <div className={`col  border-end`}>
      <div className="py-4 px-3 d-flex justify-content-center align-items-center flex-column">
        <h5 className="text-muted text-uppercase fs-13">{name}</h5>
        {activeInProgress && (
          <div className="d-flex  align-items-center gap-2">
            <h5 className="text-muted text-uppercase fs-13 pb-0 mb-0">
              {t("global.total")}:
            </h5>
            <h2 className="mb-0">
              <span className="counter-value">
                <CountUp start={0} duration={1} end={total}>
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              </span>
            </h2>
            {/* </div> */}
          </div>
        )}

        <div className="d-flex  align-items-center gap-2 mb-2">
          <h5 className="text-muted text-uppercase fs-13 pb-0 mb-0">
            {t("global.done")}:
          </h5>
          <h2 className="mb-0">
            <span className="counter-value">
              <CountUp start={0} duration={1} end={done}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};
export default WorkflowStep7Chart;
