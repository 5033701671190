import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { IExport } from "../exports/dto/IExports";
import { SingleValue } from "react-select";
import { Form } from "react-bootstrap";
import { GoogleSearchPromptService } from "./service";
import { ChatGptPromptList } from "./components/ChatGptPromptList";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { PromptCreate } from "./components/PromptCreate";

export const GoogleSearchPrompt = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState<any>();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [promptsList, setPromptsList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (promptId: number) => {
    setIsMenuOpened(true);
    GoogleSearchPromptService.getPrompt(promptId)
      .then((response) => {
        setCurrentPrompt(response);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    if (currentPrompt.id) {
      GoogleSearchPromptService.updatePrompt(currentPrompt, currentPrompt.id)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")}`);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentPrompt({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      GoogleSearchPromptService.createPrompt({
        ...currentPrompt,
        pages: currentPrompt?.pages ?? 1,
      })
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")}`);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentPrompt({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts(pagination)
      .then((response) => {
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setPromptsList(response?.data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    // <PageTemplate title={`${t("side_bar.chatGpt_prompt")}`}>
    <>
      {accessRights?.includes("google_search_prompt_can_create") &&
        !isMenuOpened && (
          <div className="col-12">
            <div className="card">
              <div className="card-body d-flex align-items-center justify-content-between">
                <h4 className="card-title mg-b-0">
                  {t("google_search.google_search_create_prompt")}
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setIsMenuOpened(true)}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <PromptCreate
              setIsMenuOpened={setIsMenuOpened}
              state={currentPrompt}
              setState={setCurrentPrompt}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <ChatGptPromptList
          accessRights={accessRights}
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          promptsList={promptsList}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
    // </PageTemplate>
  );
};
