export const sidebarLinks = {
  home: {
    title: "side_bar.home",
    links: [
      {
        linkTo: "/app/dashboard",
        icon: () => <i className="ri-dashboard-line" />,
        translation: "side_bar.dashboard",
        access: true,
      },
      {
        linkTo: "/app/workflow",
        icon: () => <i className="ri-suitcase-line" />,
        translation: "side_bar.workflow",
        // onlyAdmin: true,
        access: "workflow_can_view",
      },
      {
        linkTo: "/app/content-workflow",
        icon: () => <i className="ri-keyboard-box-line" />,
        translation: "side_bar.content_workflow",
        // onlyAdmin: true,
        access: "workflow_content_can_view",
      },
      {
        linkTo: "/app/products",
        icon: () => <i className="ri-shopping-bag-3-line" />,
        translation: "side_bar.products",
        isNested: true,
        children: [
          {
            id: 1,
            linkTo: "/app/products/base-data?page=1",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.base_data",
            access: "product_base_data_can_view",
          },
          {
            id: 2,
            linkTo: "/app/products/attributes?page=1",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.attributes",
            access: "product_attributes_data_can_view",
          },
          {
            id: 3,
            linkTo: "/app/products/content?page=1",
            icon: () => <i className="ri-user-search-line" />,
            translation: "side_bar.content",
            access: "product_content_can_view",
          },
        ],
      },
      {
        linkTo: "/app/translate",
        icon: () => <i className="ri-translate" />,
        translation: "side_bar.translate",
        access: "translate_section",
      },
      {
        linkTo: "/app/glossary",
        icon: () => <i className="ri-book-open-line" />,
        translation: "side_bar.glossary",
        access: "glossary_can_view",
      },
      {
        linkTo: "/app/page-content",
        icon: () => <i className="ri-file-user-line" />,
        translation: "side_bar.content",
        access: "content_page_can_view",
      },
      {
        linkTo: "/app/pending-products",
        icon: () => <i className="ri-download-2-line" />,
        translation: "side_bar.pending_products",
        isNested: true,
        children: [
          {
            id: 1,
            linkTo: "/app/pending-products/imported?page=1",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.imported",
            access: "product_imported_can_view",
          },
          {
            id: 2,
            linkTo: "/app/pending-products/crawled?page=1",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.crawled",
            access: "product_crawled_can_view",
          },
        ],
      },
      {
        linkTo: "/app/import",
        icon: () => <i className="ri-upload-line" />,
        translation: "side_bar.import",
        access: "import_file_section",
      },
      {
        linkTo: "/app/export",
        icon: () => <i className="ri-swap-box-line" />,
        translation: "side_bar.client_export",
        access: "export_can_view",
      },
      {
        linkTo: "/admin/brand",
        icon: () => <i className="ri-vip-crown-fill" />,
        translation: "side_bar.brand",
        access: "brand_can_view",
      },
      {
        linkTo: "/admin/block",
        icon: () => <i className="ri-layout-row-line" />,
        translation: "side_bar.block",
        isNested: true,
        children: [
          {
            id: 1,
            linkTo: "/admin/content-block",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.content",
            access: "block_can_view",
          },
          {
            id: 2,
            linkTo: "/admin/document-block",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.document",
            access: "block_can_view",
          },
        ],
      },
    ],
  },
  company: {
    title: "side_bar.company",
    links: [
      {
        linkTo: "/app/company/details",
        icon: () => <i className="ri-hotel-line" />,
        translation: "side_bar.company_details",
        access: "company_details_can_view",
      },
      {
        linkTo: "/app/clients",
        icon: () => <i className="ri-briefcase-5-fill" />,
        translation: "side_bar.clients",
        access: "client_can_view",
      },
      {
        linkTo: "/app/users",
        icon: () => <i className="ri-user-search-line" />,
        translation: "side_bar.users",
        access: "user_can_view",
      },
      {
        linkTo: "/admin/block",
        icon: () => <i className="ri-message-3-line" />,
        translation: "side_bar.enrichix_ai",
        isNested: true,
        children: [
          {
            id: 1,
            linkTo: "/app/chatgpt-prompt",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.chatGpt_prompt",
            access: "chat_gpt_prompts_can_view",
          },
          {
            id: 2,
            linkTo: "/app/google-search-prompt",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "side_bar.google_search_prompt",
            access: "google_search_prompt_can_view",
          },
          {
            id: 3,
            linkTo: "/app/data-enrichment",
            icon: () => <i className="ri-briefcase-5-fill" />,
            translation: "data_enrichment.data_enrichment",
            access: "google_search_prompt_can_view",
          },
        ],
      },
      // {
      //   linkTo: "/app/chatgpt-prompt",
      //   icon: () => <i className="ri-message-3-line" />,
      //   translation: "side_bar.chatGpt_prompt",
      //   access: "chat_gpt_prompts_can_view",
      // },
      {
        linkTo: "/app/overview",
        icon: () => <i className="ri-eye-fill" />,
        translation: "workflow.OVERVIEW",
        access: "overview_can_view",
        // access: true,
      },
    ],
  },
  admin: {
    onlyAdmin: true,
    access: "api_can_view",
    title: "side_bar.admin",
    links: [
      {
        linkTo: "#",
        icon: () => <i className="ri-stack-fill" />,
        translation: "side_bar.category_title",
        isNested: true,
        children: [
          {
            linkTo: "/admin/category/values",
            icon: () => <i className="ri-stack-fill" />,
            translation: "side_bar.category",
            access: "category_can_view",
          },
          {
            linkTo: "/admin/category/attributes",
            icon: () => <i className="ri-bar-chart-horizontal-fill" />,
            translation: "side_bar.categoryAttributes",
            access: "category_attribute_can_edit",
          },
          {
            linkTo: "/admin/attributes/names",
            icon: () => <i className="ri-file-edit-line" />,
            translation: "side_bar.attributes",
            access: "attribute_can_view",
          },
        ],
      },
      {
        linkTo: "/admin/companies",
        icon: () => <i className="ri-bank-line" />,
        translation: "side_bar.companies",
      },
      {
        linkTo: "/admin/crawlers",
        icon: () => <i className="ri-file-code-line" />,
        translation: "side_bar.crawlers",
      },
      {
        linkTo: "/admin/api",
        icon: () => <i className="ri-shape-line" />,
        translation: "side_bar.api",
        access: "api_can_view",
      },
      {
        linkTo: "/admin/access-rights",
        icon: () => <i className="ri-error-warning-line"></i>,
        translation: "side_bar.access_rights",
      },
    ],
  },
  info: {
    title: "",
    access: true,
    links: [
      {
        linkTo: "/app/help",
        icon: () => <i className="ri-question-fill" />,
        translation: "side_bar.help",
        access: "help_section_can_view",
      },
    ],
  },
};
