import {
  formatDateWorkflow,
  formatRole,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { IUser } from "../../../model/authentification/IRegister";
import React from "react";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import Switch from "react-switch";
import { UserService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";
import { AccessService } from "../../accessRights/service";
import DatePicker from "react-datepicker";
interface IUserFormProps {
  state: IUser;
  setState: any;
  selectTypesHandler: (event: any) => void;
  changeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact?: boolean
  ) => void;
  imageRef: any;
  selectRoleHandler: (event: any) => void;
  selectAccessHandler: (event: any) => void;
  handleCancel: () => void;
  accessRightsList: any[];
  selectLanguagesHandler: (event: any) => void;
  companiesList: ICompaniesDTO[];
  handleSelectCountry: (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => void;
  handleSelectCompany: (arg: any) => void;
  correctAccessRightList: any;
}

export const UserForm = ({
  state,
  setState,
  selectTypesHandler,
  selectLanguagesHandler,
  changeHandler,
  imageRef,
  selectRoleHandler,
  handleCancel,
  handleSelectCountry,
  accessRightsList,
  selectAccessHandler,
  companiesList,
  handleSelectCompany,
  correctAccessRightList,
}: IUserFormProps) => {
  const types = ["base_data", "attributes", "content"];
  const { t } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const [imagePath, setImagePath] = useState<string>("");
  const [visibilityPassword, setVisibility] = useState(false);
  const [startDate, setStartDate] = useState(
    (state as any)?.expiresAt ? new Date((state as any)?.expiresAt) : null
  );
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_SUPER_ADMIN"
  );
  const isAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_ADMIN"
  );
  const isCompanyAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_COMPANY_ADMIN"
  );
  const isManager = currentUser?.roles?.includes("ROLE_MANAGER");

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setImagePath(URL.createObjectURL(evt.target.files[0]));
    }
  }
  const { copywriterRole } = useSelector((state: IAllStates) => state.enums);
  const { country } = useSelector((state: IAllStates) => state.enums);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { userRoles } = useSelector((state: IAllStates) => state.enums);
  const { userCompanyRoles } = useSelector((state: IAllStates) => state.enums);
  function handleDate(date: any) {
    if (date === null) {
      setStartDate(date);
      setState((prev: any) => ({
        ...prev,
        expiresAt: null,
      }));
    } else {
      setStartDate(date);
      setState((prev: any) => ({
        ...prev,
        expiresAt: date,
      }));
    }
  }
  const handleChangeSwitch = (id: number) => {
    UserService.updateUserStatusByID(id)
      .then((response) => {
        setState((prev: any) => ({ ...prev, isActive: !prev.isActive }));
        SuccessToast(
          `${t("toasts.successfully_change_status")} ${state?.firstName}`
        );
      })
      .catch((error) => ErrorToast(error));
  };
  const handleChangeSwitchCopywriter = (key: string, isChecked: boolean) => {
    setState((prev: any) => ({
      ...prev,
      [key]: isChecked,
    }));
  };
  const checkForShowRoles = () => {
    if (isSuperAdmin) {
      return ["ROLE_ADMIN", "ROLE_COMPANY_ADMIN", "ROLE_MANAGER"];
    } else if (isAdmin) {
      return ["ROLE_COMPANY_ADMIN", "ROLE_MANAGER"];
    } else if (isCompanyAdmin) {
      return ["ROLE_MANAGER"];
    } else if (isManager) {
      return ["ROLE_MANAGER"];
    } else {
      return;
    }
  };
  console.log((state as any)?.expiresAt);

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                {state?.profilePicture?.path || imagePath ? (
                  <CustomImageHandler
                    path={
                      !!imagePath?.length
                        ? imagePath
                        : `${state?.profilePicture?.path}`
                    }
                    classes="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                  />
                ) : (
                  <img
                    src="/assets/images/img-placeholder.png"
                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                  />
                )}
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    id="profile-img-file-input"
                    type="file"
                    accept="image/*"
                    ref={imageRef}
                    className="profile-img-file-input"
                    onChange={handleImageInput}
                  />
                  <label
                    htmlFor="profile-img-file-input"
                    className="profile-photo-edit avatar-xs"
                  >
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="ri-camera-fill" />
                    </span>
                  </label>
                </div>
              </div>

              <div>
                <h5 className="fs-16 mb-1">
                  {state?.firstName} {state?.lastName}
                </h5>
                <p className="text-muted mb-0">{state?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="card">
          <div className="card-body">
            <div className="row">
              {isAdmin && (
                <div className="col-md-6 my-2">
                  <label className="required-field" htmlFor="company">
                    {t("users.company")}
                  </label>
                  <Select
                    className="react-select-field "
                    theme={themeSelect}
                    isClearable={true}
                    isDisabled={state?.id ? true : false}
                    placeholder={t("users.company")}
                    defaultValue={
                      (state as any)?.company && {
                        label: (state as any)?.company?.title ?? "-",
                        value: (state as any)?.company?.id,
                      }
                    }
                    // defaultValue={currentUser?.company?.title}
                    options={companiesList?.map((option) => ({
                      label: option.title ?? "-",
                      value: option.id,
                    }))}
                    onChange={(e) => {
                      handleSelectCompany(e as any);
                    }}
                  />
                </div>
              )}

              <div className="col-md-6 my-2">
                <label htmlFor="firstName" className="required-field">
                  {t("users.firstName")}
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="firstName"
                  required
                  value={state?.firstName}
                  placeholder={t("users.firstName")}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="lastName">{t("users.lastName")}</label>
                <input
                  type="text"
                  name="lastName"
                  value={state?.lastName}
                  onChange={changeHandler}
                  placeholder={t("users.lastName")}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="email" className="required-field">
                  {t("users.email")}
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="email"
                  value={state?.email}
                  required
                  placeholder={t("users.email")}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>
              {!state?.id && (
                <div className="col-md-6 my-2">
                  <label htmlFor="password" className="required-field">
                    {t("users.password")}
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type={`${visibilityPassword ? "text" : "password"}`}
                      className="form-control"
                      id="password"
                      autoComplete="off"
                      name="password"
                      onChange={changeHandler}
                      placeholder={t("forgot_password.enter_password")}
                    />
                    {visibilityPassword ? (
                      <i
                        className="ri-eye-fill align-middle cursor-pointer text-muted"
                        onClick={() => setVisibility(!visibilityPassword)}
                        style={{ marginLeft: "-1.5rem" }}
                      />
                    ) : (
                      <i
                        className="ri-eye-off-fill cursor-pointer text-muted"
                        onClick={() => setVisibility(!visibilityPassword)}
                        style={{ marginLeft: "-1.5rem" }}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="col-md-3 my-2">
                <label htmlFor="phone">{t("users.phone")}</label>
                <input
                  type="tel"
                  name="phone"
                  value={state?.contact?.phone}
                  placeholder={t("users.phone")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              {isAdmin && (
                <div className="col-md-3 my-2">
                  <label htmlFor="country">{t("users.country")}</label>
                  <Select
                    className="react-select-field"
                    theme={themeSelect}
                    options={country?.map((singleCountry) => ({
                      value: singleCountry,
                      label: getCorrectLanguage(singleCountry, t),
                    }))}
                    key={state?.id}
                    defaultValue={
                      state?.contact?.country && {
                        label: getCorrectLanguage(state?.contact?.country, t),
                        value: state?.contact?.country,
                      }
                    }
                    onChange={(e) => {
                      handleSelectCountry(
                        e as SingleValue<{ label: string; value: string }>
                      );
                    }}
                  />
                </div>
              )}

              <div className="col-md-3 my-2">
                <label htmlFor="city">{t("users.city")}</label>
                <input
                  type="text"
                  name="city"
                  value={state?.contact?.city}
                  placeholder={t("users.city")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="zip">{t("users.zip")}</label>
                <input
                  type="text"
                  name="zip"
                  value={state?.contact?.zip}
                  placeholder={t("users.zip")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="address">{t("users.address")}</label>
                <input
                  type="text"
                  name="address"
                  value={state?.contact?.address}
                  placeholder={t("users.address")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="language" className="">
                  {`${t("users.expiry_date")}`}
                </label>
                <div className="datepicker-container">
                  <DatePicker
                    className="date-picker"
                    isClearable
                    selected={startDate as any}
                    value={
                      (state as any)?.expiresAt
                        ? (formatDateWorkflow(
                            (state as any)?.expiresAt as any
                          ) as any)
                        : null
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleDate(date as any)}
                    placeholderText="Choose expiry date..."
                  />
                </div>
              </div>
              {state?.id && (
                <div className="col-md-3 my-2 d-flex flex-column align-items-center ">
                  {/* d-flex flex-column align-items-center justify-content-center */}
                  <label className="d-block me-auto">
                    {t("global.active_status")}
                  </label>
                  <Switch
                    className="my-auto me-auto"
                    onChange={() => handleChangeSwitch(state?.id as number)}
                    checked={state?.isActive as boolean}
                  />
                </div>
              )}
              <div className="row">
                <div className="col-md-2 my-2">
                  <label htmlFor="roles" className="">
                    {t("users.roles")}
                  </label>
                  {checkForShowRoles()?.map((role) => (
                    <div className="d-flex align-items-center" key={role}>
                      <input
                        className="form-check-input cursor-pointer m-0"
                        key={role}
                        value={role}
                        checked={state?.roles?.includes(role)}
                        type="checkbox"
                        onChange={selectRoleHandler}
                        id={role}
                        autoComplete="false"
                      />
                      <label htmlFor={role} className="m-0 px-3">
                        {formatRole(role)}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-md-2 my-2">
                  <label htmlFor="roles" className="required-field">
                    {t("users.custom_roles")}
                  </label>
                  {correctAccessRightList?.length !== 0 ? (
                    correctAccessRightList?.map((access: any) => (
                      <div
                        className="d-flex align-items-center"
                        key={access?.id}
                      >
                        <input
                          className="form-check-input cursor-pointer m-0"
                          key={access?.id}
                          value={access?.name}
                          checked={state?.customRoles?.some(
                            (item: number) => item === access?.id
                          )}
                          type="checkbox"
                          onChange={selectAccessHandler}
                          id={access?.id}
                          autoComplete="false"
                        />
                        <label htmlFor={access} className="m-0 px-3">
                          {/* {formatRole(role)} */}
                          {access?.name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div>{t("users.selected_company_has_no_roles")}</div>
                  )}
                </div>

                <div className="col-md-2 my-2">
                  <label className="d-block">{t("global.copywriter")}</label>
                  <Switch
                    onChange={(checked) =>
                      handleChangeSwitchCopywriter("isCopywriter", checked)
                    }
                    checked={state?.isCopywriter as boolean}
                  />
                  <div className="">
                    <label htmlFor="roles">{t("export_client.types")}</label>
                    {copywriterRole?.map((type: string) => (
                      <div className="d-flex align-items-center" key={type}>
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          key={type}
                          value={type}
                          checked={state?.copywriterRole?.includes(type)}
                          type="checkbox"
                          onChange={selectTypesHandler}
                          id={type}
                          autoComplete="false"
                        />
                        <label htmlFor={type} className="m-0 px-3">
                          {getCorrectType(type)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {true && (
                  <>
                    <div className="col-md-2 my-2">
                      <label className="d-block">
                        {t("workflow.translator")}
                      </label>
                      <Switch
                        onChange={(checked) =>
                          handleChangeSwitchCopywriter("translator", checked)
                        }
                        checked={state?.translator as boolean}
                      />
                      <div className="">
                        <label htmlFor="roles">
                          {t("export_client.languages")}
                        </label>
                        {translationLanguageEnum?.map((language) => (
                          <div
                            className="d-flex align-items-center"
                            key={language}
                          >
                            <input
                              className="form-check-input m-0 cursor-pointer"
                              key={language}
                              value={language}
                              checked={state?.languages?.includes(language)}
                              type="checkbox"
                              onChange={selectLanguagesHandler}
                              id={language}
                              autoComplete="false"
                            />
                            <label htmlFor={language} className="m-0 px-3">
                              {getCorrectLanguageTitle(language)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {false && (
                      <div className="col-md-2 my-2">
                        <label className="d-block">
                          {t("global.quality_control")}
                        </label>
                        <Switch
                          onChange={(checked) =>
                            handleChangeSwitchCopywriter(
                              "qualityControlPerson",
                              checked
                            )
                          }
                          checked={state?.qualityControlPerson as boolean}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="col-12 my-2 d-flex justify-content-end">
                <button className="btn btn-info" onClick={handleCancel}>
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary ms-3" type="submit">
                  {state?.id
                    ? `${t("global.update")}`
                    : `${t("global.create")}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const getCorrectLanguageTitle = (args: string) => {
  switch (args) {
    case "de":
      return "German";
    case "fr":
      return "France";
    case "en":
      return "English";
    case "it":
      return "Italian";
    default:
      return `NO KEY ${args}`;
  }
};
const getCorrectType = (args: string) => {
  switch (args) {
    case "base_data_copywriter":
      return "Base data";
    case "attributes_copywriter":
      return "Attributes";
    case "content_copywriter":
      return "Content";
    default:
      return `NO KEY ${args}`;
  }
};
