import React, { useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { themeSelect } from "../../shared/components/Select2";
import Select, { SingleValue } from "react-select";
import { getCorrectLanguage } from "../../shared/functions/Functions";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { searchPlugin } from "@react-pdf-viewer/search";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";

export const Help = () => {
  const { t } = useTranslation();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );

  const [state, setState] = useState({
    document: "",
    language: "",
  });
  const [showPdf, setShowPdf] = useState(false);
  const documentArr = ["Overview"];

  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { GoToNextPage, GoToPreviousPage, CurrentPageInput } =
    pageNavigationPluginInstance;
  const { EnterFullScreenButton } = fullScreenPluginInstance;
  const searchPluginInstance = searchPlugin();

  const viewerRef = useRef(null);

  function handleSelectSingle(
    selectedSingle: SingleValue<any | null>,
    name: string
  ) {
    if (!selectedSingle?.value) {
      setState((prev: any) => ({
        ...prev,
        [name]: "",
      }));
      setShowPdf(false);
    } else {
      setState((prev: any) => ({
        ...prev,
        [name]: selectedSingle?.value,
      }));
    }
  }

  return (
    <>
      <div
        className="col-md-12 d-flex align-items-end py-1 sticky-form"
        style={{
          backgroundColor: "#F4F4F6",
          position: "sticky",
          zIndex: "998",
          top: "70px",
        }}
      >
        <Col md={4} className="me-3">
          <label htmlFor="client" className="required-field">
            {`${t("global.document")}`}
          </label>
          <Select
            isClearable
            className="react-select-field"
            theme={themeSelect}
            onChange={(e) => {
              handleSelectSingle(e as any, "document");
            }}
            options={documentArr?.map((item: any) => {
              return {
                label: item,
                value: item,
              };
            })}
          />
        </Col>
        <Col md={4} className="me-3">
          <label htmlFor="client" className="required-field">
            {`${t("global.language")}`}
          </label>
          <Select
            isClearable
            className="react-select-field"
            theme={themeSelect}
            onChange={(e) => {
              handleSelectSingle(e as any, "language");
            }}
            options={translationLanguageEnum?.map((lang) => ({
              value: lang,
              label: getCorrectLanguage(lang, t),
            }))}
          />
        </Col>
        <Col md={3} className="">
          <button
            onClick={() => setShowPdf(true)}
            className="btn btn-primary me-2"
            disabled={state?.document === "" || state?.language === ""}
          >
            {t("global.preview")}
          </button>
        </Col>
      </div>
      <div className="row">
        {showPdf && (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
          >
            <div
              className="d-flex py-1 toolbar-pdf"
              style={{
                backgroundColor: "#F4F4F6",
                position: "sticky",
                zIndex: "998",
                top: "140px",
              }}
            >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
              {/* <GoToPreviousPage />
            <CurrentPageInput />
            <GoToNextPage /> */}
              <EnterFullScreenButton />
            </div>
            <div className="rpv-core__viewer p-0">
              <Viewer
                ref={viewerRef}
                fileUrl={"/ContentToolOverviewSerbianV5.pdf"}
                defaultScale={1}
                plugins={[
                  zoomPluginInstance,
                  pageNavigationPluginInstance,
                  fullScreenPluginInstance,
                  searchPluginInstance,
                ]}
              />
            </div>
          </Worker>
        )}
      </div>
    </>
  );
};
