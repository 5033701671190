import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Col, Form, Row } from "react-bootstrap";
import { DataEnrichmentService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
const DataEnrichment = () => {
  const [currentPrompt, setCurrentPrompt] = useState<any>();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setCurrentPrompt(value);
  };
  const submitHandler = () => {
    DataEnrichmentService.updateDataEnrichment({ prompt: currentPrompt ?? "" })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_updated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    DataEnrichmentService.getDataEnrichment()
      .then((response: any) => {
        setCurrentPrompt(response?.prompt ?? "");
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <Row>
            <Col md={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitHandler();
                }}
              >
                <label htmlFor="text" className="required-field">
                  {`${t("google_search.prompt")}`}
                </label>
                <textarea
                  name="text"
                  onChange={(e) => changeHandler(e as any)}
                  value={currentPrompt ?? ""}
                  className="w-100 dark-text-area"
                  placeholder={`${t("mapping.type")}...`}
                  rows={12}
                />
                <div
                  className="alert alert-warning alert-borderless m-0 p-1"
                  role="alert"
                >
                  <p className="mb-0">
                    <strong>{t("data_enrichment.html_necessary_tag")}!</strong>
                  </p>
                </div>

                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                  <div>
                    <button className="btn btn-primary ms-3" type="submit">
                      {t("global.save")}
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DataEnrichment;
