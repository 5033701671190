import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IAllStates } from "../../../../store/rootReducer";
import Select from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import {
  IProducts,
  IProductsList,
  IProductVariation,
} from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import VariationUpdateForm from "./VariationUpdateForm";
import { BlockService } from "../../../block/service";
import { Pagination } from "../../../../shared/paginations/Paginations";

const variationInitial = {
  title: "",
  lang: "",
  bullets: "",
  introText: "",
  subtitle: "",
  blocks: {},
};

const getAllVariations = ({
  variationPagination,
  id,
  setVariationList,
  setTotalPages,
  setVariationPagination,
  setPage,
}: any) => {
  ProductsService.getAllVariations({
    ...variationPagination,
    product: Number(id),
  })
    .then((response) => {
      if (
        variationPagination?.page > 1 &&
        variationPagination?.page > response?.totalPages
      ) {
        setPage(response?.totalPages);
        setVariationPagination({
          ...variationPagination,
          page: response?.totalPages,
        });
      }
      setVariationList(response.data);
      setTotalPages(Math.ceil(response?.count / response?.perPage));
    })
    .catch((error) => ErrorToast(error));
};
interface IProductsViewProps {
  currentProduct: IProductsList | IProducts;
}

export const ContentView = ({ currentProduct }: IProductsViewProps) => {
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const isView = true;
  const [variationId, setVariationId] = useState(0 as number);
  const [variationList, setVariationList] = useState<IProductVariation[]>([]);
  const [activeBlocks, setActiveBlocks] = useState([] as any);
  const [activeTab, setActiveTab] = useState("en");
  const { id } = useParams();
  const [currentVariation, setCurrentVariation] =
    useState<IProductVariation>(variationInitial);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [allBlocks, setAllBlocks] = useState([] as any);
  const [certainBlocks, setCertainBlocks] = useState([]);
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial);
  };
  // const handleShow = () => {
  //   setShowVariationModal(true);
  //   setCertainBlocks(currentProduct?.block as any);
  // };
  const getCertainBlocks = (block: any, listOfAllBlocks: any) => {
    const data: any[] = [];
    listOfAllBlocks?.forEach((item: any) => {
      if (block?.some((a: number) => a === item.id)) {
        data.push(item);
      }
    });
    return data;
  };
  const viewVariation = (id: number) => {
    setVariationId(id);
    setShowVariationModal(true);
  };

  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (!!allBlocks.length && currentProduct?.block) {
      const currentlyBlocksForProduct = getCertainBlocks(
        currentProduct?.block as any,
        allBlocks
      );
      setActiveBlocks(currentlyBlocksForProduct);
    }
  }, [allBlocks.length, currentProduct?.block]);

  useEffect(() => {
    id &&
      getAllVariations({
        variationPagination: variationPagination,
        id: id,
        setVariationList: setVariationList,
        setTotalPages: setTotalPages,
        setPage: setPage,
        setVariationPagination: setVariationPagination,
      });
  }, [id, JSON.stringify(variationPagination)]);

  return (
    <>
      <div className="col-8">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <ul
                className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                {translationLanguageEnum?.map((language) => {
                  return (
                    <li
                      className="nav-item"
                      key={language}
                      onClick={() => changeActiveTab(language)}
                    >
                      <a
                        className={`nav-link ${
                          activeTab === language ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href={`#${language}`}
                        role="tab"
                        aria-selected={language === currentLanguage}
                      >
                        {t(`products.content_${language}`)}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div className="tab-content">
                {translationLanguageEnum?.map((language) => {
                  return (
                    <div
                      className={`tab-pane  ${
                        activeTab === language ? "active" : ""
                      }`}
                      id={language}
                      role="tabpanel"
                    >
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between align-items-center my-1">
                            <label className="m-0" htmlFor="description">
                              {t("products.description")}
                            </label>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                currentProduct?.translation?.[language]
                                  ?.description ?? "Empty...",
                            }}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="d-flex justify-content-between align-items-center my-1">
                            <label className="m-0" htmlFor="introText">
                              {t("products.introText")}
                            </label>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                currentProduct?.translation?.[language]
                                  ?.introText ?? "Empty...",
                            }}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="d-flex justify-content-between align-items-center my-1">
                            <label className="m-0" htmlFor="bullets">
                              {t("products.bullets")}
                            </label>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                currentProduct?.translation?.[language]
                                  ?.bullets ?? "Empty...",
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                          <button
                            className="btn btn-info"
                            onClick={() => navigate(-1)}
                          >
                            {t("products.close")}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {!!allBlocks.length && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 my-3">
                  <label htmlFor="blocks">{t("products.blocks")}</label>
                  <Select
                    className="react-select-field"
                    isMulti
                    isDisabled
                    theme={themeSelect}
                    value={activeBlocks?.map(
                      (activeBlocks: { name: string; id: number }) => ({
                        label: activeBlocks?.name,
                        value: activeBlocks?.id,
                      })
                    )}
                    key={currentProduct?.id}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <>
        <div className="col-4">
          {variationList?.map((variation) => (
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h5>{variation?.title ?? "-"}</h5>
                    </div>
                    <div className="col-4">
                      <h5>{variation?.subtitle ?? "-"}</h5>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <button
                        className="btn btn-sm btn-soft-primary me-2"
                        onClick={() => viewVariation(variation?.id as number)}
                      >
                        <i className="ri-eye-fill" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </>
      {showVariationModal && (
        <VariationUpdateForm
          setTriggerUpdate={setTriggerUpdate}
          currentVariation={currentVariation}
          showVariationModal={showVariationModal}
          handleClose={handleClose}
          certainBlocks={certainBlocks}
          isView={isView}
          variationId={variationId}
          setCurrentVariation={setCurrentVariation}
        />
      )}
    </>
  );
};
