import { useTranslation } from "react-i18next";
import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { ClientService } from "./service";
import { IClientsDTO } from "./dto/IClients";
import { ClientsList } from "./components/ClientsList";
import _debounce from "lodash/debounce";
import { ClientForm } from "./components/ClientForm";
import { Form } from "react-bootstrap";
import {
  IClient,
  ICurrentClient,
} from "../../model/authentification/IRegister";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";

const initialState = {
  id: 0,
  name: "",
  country: "",
  company: {
    title: "",
    email: "",
    id: 0,
    contact: {
      phone: "",
      country: "",
      city: "",
      zip: "",
      address: "",
    },
  },
};

export const Clients = () => {
  const { t } = useTranslation();
  const [clientList, setClientList] = useState<IClientsDTO[] | undefined>();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentClient, setCurrentClient] = useState<
    ICurrentClient | undefined
  >();
  const [inputField, setInputField] = useState(false);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentClient?.id) {
      ClientService.updateClientById(
        currentClient?.id,
        currentClient as unknown as IClient
      )
        .then((response) => {
          if (response?.id) {
            setInputField(false);
            setCurrentClient(initialState);
            setInputField(!inputField);
            SuccessToast(
              `${t("toasts.successfully_updated")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      ClientService.createClient(currentClient as unknown as IClient)
        .then((response) => {
          if (response?.id) {
            setInputField(false);
            setCurrentClient(initialState);
            setInputField(!inputField);
            SuccessToast(
              `${t("toasts.successfully_created")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setCurrentClient((prev: any) => ({ ...prev, name: value }));
  }

  const editHandler = (clientId: number) => {
    ClientService.getClientById(clientId)
      .then((response) => {
        setInputField(true);
        setCurrentClient(response);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    ClientService.getAllClients(pagination)
      .then((response) => {
        const { data } = response;
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setClientList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, inputField]);

  return (
    // <PageTemplate title={`${t("side_bar.clients")}`}>
    <div className="col-xl-12">
      {accessRights?.includes("client_can_create") && !inputField && (
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {currentClient?.id
                ? `${t("client.update")}`
                : `${t("clients.createClient")}`}
            </h4>
            <button
              className="btn btn-primary"
              onClick={() => setInputField(true)}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      )}
      {inputField && (
        <div className="card">
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <ClientForm
                setTotalPages={setTotalPages}
                handleChange={handleChange}
                state={currentClient as ICurrentClient}
                setState={
                  setCurrentClient as React.Dispatch<
                    SetStateAction<ICurrentClient | undefined>
                  >
                }
                clientsList={clientList}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-info me-2"
                  onClick={() => {
                    setInputField(false);
                    setCurrentClient({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary" type="submit">
                  {currentClient?.id
                    ? `${t("global.update")}`
                    : `${t("global.create")}`}
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
      <ClientsList
        accessRights={accessRights}
        clientsList={clientList}
        changeFilterHandler={changeFilterHandler}
        editHandler={editHandler}
        setPagination={setPagination}
        page={page}
        totalPages={totalPages}
        handlePages={handlePages}
        setTriggerUpdate={setTriggerUpdate}
      />
    </div>
    // </PageTemplate>
  );
};
