import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { selectClasses, styled } from "@mui/material";
import { IAttributes } from "../../app/attributes/names/dto/IAttributes";
import { useTranslation } from "react-i18next";

const StyledFormControl = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid hsl(0, 0%, 80%)",
  maxHeight: "38px",
  borderRadius: "5px",
  [`.${selectClasses.select}`]: {
    padding: "7.5px 9px",
    fontSize: "13px",
  },
  fieldset: {
    display: "none",
  },
}));

interface IGroupedMultiselectProps {
  items?: IAttributes[];
  handleChangeCategory?: (name: string, value: string) => void;
  name: string;
  pagination: any;
}

const GroupedMultiselect = ({
  items,
  handleChangeCategory,
  name,
  pagination,
}: IGroupedMultiselectProps) => {
  const [categoryFilter, setCategoryFilter] = React.useState<string[]>([]);
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value, name },
    } = event;
    if (handleChangeCategory) {
      handleChangeCategory(name, value as string);
    }
    setCategoryFilter(value as string[]);
  };

  const transformArray = (Array: any) => {
    return Array.map((obj: any) => {
      const options = [
        { option: obj.title },
        ...obj.attributeValues.map((value: any) => {
          return { option: value.title, id: value.id };
        }),
      ];
      return { options };
    });
  };
  const transformedArray = transformArray(items);

  const searchValue = (arr: any, idsToFind: number[]): string[] => {
    let titles: string[] = [];
    arr?.forEach((product: any) => {
      product?.attributeValues?.forEach((attr: any) => {
        if (idsToFind.includes(attr.id)) {
          titles.push(attr.title);
        }
      });
    });
    return titles;
  };
  return (
    <div>
      <StyledFormControl>
        <Select
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          name={name}
          value={pagination?.attributeValues ?? categoryFilter}
          onChange={handleChange}
          renderValue={(selected: unknown) => {
            const selectedOptions = searchValue(
              items,
              selected as number[]
            ) as string[];
            if (selectedOptions.length === 0) {
              return <span>{t("workflow.filter")}</span>;
            }
            return selectedOptions.join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {transformedArray.map((item: any) => {
            return item.options.map((i: any, index: number) => (
              <MenuItem key={i.id} value={i.id} disabled={index === 0}>
                {index !== 0 && (
                  <Checkbox
                    checked={
                      pagination?.attributeValues?.includes(i?.id) ??
                      categoryFilter?.includes(i.id)
                    }
                  />
                )}
                <ListItemText primary={i.option} />
              </MenuItem>
            ));
          })}
        </Select>
      </StyledFormControl>
    </div>
  );
};

export default GroupedMultiselect;
