import { Button, Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IExport } from "../dto/IExports";
import React, { SetStateAction, useEffect, useState } from "react";
import { ExportService } from "../service";
import { ErrorToast, WarningToast } from "../../../shared/toasters/toasters";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { Modals } from "../../../shared/modals/Modals";
interface IExportListProps {
  onClickFormat: (e: any) => void;
  exportsList: IExport[] | undefined;
  exportFormatEnum: string[];
  accessRights: string[];
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalPages: number;
  page: number;
  handlePages: (page: number) => void;
  viewChangeHandler: any;
  formatChangeHandler: any;
  editHandler: (expId: number) => void;
  changeClientHandler: (value: string) => void;
  exportView: any;
  clientList: IExport[];
  changeFilterClientHandler: (
    selectedSingle: SingleValue<IExport | null>,
    inputKey: string
  ) => void;
  exportHandler: (expId: number) => void;
}

export const ExportList = ({
  exportsList,
  clientList,
  changeFilterHandler,
  totalPages,
  page,
  handlePages,
  changeFilterClientHandler,
  changeClientHandler,
  setTriggerUpdate,
  editHandler,
  accessRights,
  exportHandler,
  exportFormatEnum,
  exportView,
  onClickFormat,
  viewChangeHandler,
  formatChangeHandler,
}: IExportListProps) => {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowExport(false);
  };
  const handleShow = (modalName: string) => {
    if (modalName === "default") {
      setShow(true);
    } else if (modalName === "export") {
      setShowExport(true);
    }
  };

  const { t } = useTranslation();

  const [selectedExport, setSelectedExport] = useState<IExport>();
  const deleteHandler = () => {
    selectedExport?.id &&
      ExportService.deleteExport(selectedExport?.id)
        .then((response) => {
          if (response === "") {
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  const listProducts = (
    isVariations: boolean,
    idAtt: number | undefined,
    num: any,
    titleAtt: string
  ) => {
    if (!isVariations && num == 0) {
      WarningToast("Number of products is 0");
    } else if (!isVariations) {
      navigate(`/app/export/list/${idAtt}`, { state: { title: titleAtt } });
    } else if (isVariations && num == 0) {
      WarningToast("Number of variations is 0");
    } else {
      navigate(`/app/variations/list/${idAtt}`, { state: { title: titleAtt } });
    }
  };

  return (
    <>
      {" "}
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {`${t("export_client.listOfExports")}`}
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <input
              className="form-control mb-3 me-1"
              name={"name"}
              onChange={changeFilterHandler}
              placeholder={`${t("export_client.searchName")}...`}
              type="text"
              style={{ width: `${windowWidth?.width > 767 ? `25%` : `50%`}` }}
              maxLength={30}
            />
            <Select
              theme={themeSelect}
              isClearable
              className={`${
                windowWidth?.width > 767 ? `w-25` : `w-50`
              } ms-1 react-select-field`}
              onInputChange={(e) => changeClientHandler(e)}
              onChange={(e) => {
                changeFilterClientHandler(
                  e as SingleValue<IExport | null>,
                  "client"
                );
              }}
              options={clientList as any}
            />
          </div>
          {exportsList == undefined ? (
            <LoadingAnimation />
          ) : exportsList?.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.name")}`}</th>
                    <th scope="col">{`${t("export_client.client")}`}</th>
                    <th scope="col">{`${t("export_client.languages")}`}</th>
                    <th scope="col">{`${t("export_client.productType")}`}</th>
                    <th scope="col">{`${t(
                      "export_client.number_of_variations"
                    )}`}</th>
                    <th scope="col">{`${t(
                      "attributes.number_of_products"
                    )}`}</th>
                    {(accessRights?.includes("export_can_edit") ||
                      accessRights?.includes("export_can_delete")) && (
                      <th scope="col">{`${t("global.actions")}`}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {exportsList?.map((exp) => {
                    const selectedProjectTypes = [];
                    if (exp?.baseData) {
                      selectedProjectTypes.push("Base data");
                    }
                    if (exp?.attributes) {
                      selectedProjectTypes.push("Attributes");
                    }
                    if (exp?.content) {
                      selectedProjectTypes.push("Content");
                    }
                    return (
                      <tr key={exp?.id}>
                        <td>
                          <Link to="#" className="fw-medium">
                            #{exp?.id}
                          </Link>
                        </td>
                        <td>{exp?.name ?? "-"}</td>
                        <td>{exp?.client?.name ?? "-"}</td>
                        <td>
                          {!!exp?.language?.length
                            ? exp?.language
                                ?.map(
                                  (language) =>
                                    `${getCorrectLanguage(language, t)}`
                                )
                                .join(", ")
                            : "-"}
                        </td>
                        <td>
                          {exp?.baseData && "Base data"}
                          {exp?.attributes && exp?.baseData && ", "}
                          {exp?.attributes && "Attributes"}
                          {exp?.content &&
                            (exp?.baseData || exp?.attributes) &&
                            ", "}
                          {exp?.content && "Content"}
                          {selectedProjectTypes.length === 0 && "-"}
                        </td>
                        <td>
                          <span
                            className="custom_pointer"
                            onClick={() =>
                              listProducts(
                                true,
                                exp?.id,
                                exp?.numberOfVariations as any,
                                exp?.name as string
                              )
                            }
                          >
                            {exp?.numberOfVariations ?? "-"}
                          </span>
                        </td>
                        <td>
                          <span
                            className="custom_pointer"
                            onClick={() =>
                              listProducts(
                                false,
                                exp?.id,
                                exp?.numberOfProducts as any,
                                exp?.name as string
                              )
                            }
                          >
                            {exp?.numberOfProducts ?? "-"}
                          </span>
                        </td>
                        {(accessRights?.includes("export_can_edit") ||
                          accessRights?.includes("export_can_delete")) && (
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              {/* <button
                                  className="btn btn-sm btn-secondary d-flex align-items-center me-2"
                                  onClick={() => {
                                    // console.log("click export");
                                    exportHandler(exp?.id!)
                                  }}
                                >
                                  <i className="ri-play-circle-fill me-1"></i>
                                  {`${t("global.export")}`}
                                </button> */}
                              {/* <div className="dropdown">
                              <button
                                className="btn btn-sm btn-secondary dropdown-toggle d-flex align-items-center me-2"
                                onClick={() => {
                                  exportHandler(exp?.id!);
                                }}
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="ri-play-circle-fill me-1"></i>
                                {`${t("global.export")}`}
                              </button>
                              <ul className="dropdown-menu">
                                {exportFormatEnum?.map((format: string) => (
                                  <li>
                                    <span
                                      onClick={onClickFormat}
                                      className="dropdown-item"
                                    >
                                      {format.toUpperCase()}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div> */}
                              <button
                                className="btn btn-sm btn-primary d-flex align-items-center me-2"
                                // onClick={() => editHandler(exp?.id!)}
                                onClick={() => {
                                  handleShow("export");
                                  exportHandler(exp?.id!);
                                }}
                              >
                                {`${t("global.export")}`}
                              </button>

                              {/* </div> */}
                              {accessRights?.includes("export_can_edit") && (
                                <button
                                  className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                  onClick={() => editHandler(exp?.id!)}
                                >
                                  <i className="ri-edit-2-fill me-1" />
                                  {`${t("global.edit")}`}
                                </button>
                              )}
                              {accessRights?.includes("export_can_delete") && (
                                <button
                                  className="btn btn-sm btn-danger d-flex align-items-center"
                                  onClick={() => {
                                    handleShow("default");
                                    setSelectedExport(exp);
                                  }}
                                >
                                  <i className="ri-delete-bin-fill me-1" />
                                  {`${t("global.delete")}`}
                                </button>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
          <ConfirmDelete
            show={show}
            setShow={setShow}
            itemName={selectedExport?.name}
            deleteHandler={deleteHandler}
            product={selectedExport}
            selectedProduct={selectedExport}
          />
        </div>
      </div>
      <Modal
        show={showExport}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("global.export")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row  ">
              <div className="col-6 ">
                <h5>{t("workflow.format")}:</h5>
                {exportFormatEnum?.map((format: string, idx: number) => (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="format"
                      id={`flexRadioDefault${idx}`}
                      value={format}
                      onChange={formatChangeHandler}
                    />
                    <label className="form-check-label" htmlFor="format">
                      {format.toUpperCase()}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-6">
                <h5>{t("global.view")}:</h5>
                {exportView?.map((view: string, idx: number) => (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="view"
                      value={view}
                      id={`flexRadioDefault${idx}`}
                      onChange={viewChangeHandler}
                    />
                    <label className="form-check-label" htmlFor="view">
                      {view.toUpperCase().replace("_", " ")}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* <span onClick={onClickFormat}></span> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("modals.close")}
          </Button>
          <Button variant="primary" onClick={onClickFormat}>
            {t("global.export")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
