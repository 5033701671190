import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import categorySlice from "../../../../store/category/categoryRedux";
import { IAllStates } from "../../../../store/rootReducer";
import i18n from "../../../../utils/translations/i18next";
import { CategoryService } from "../../service";
import { ICategoryCreate } from "../dto/ICategory";
import Select, { MultiValue, SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { ProductsService } from "../../../products/service";

export const CategoryEdit = () => {
  const [currentCategory, setCurrentCategory] = useState({} as ICategoryCreate);
  const { id } = useParams();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const currentLanguage = i18n?.language;
  const [activeTab, setActiveTab] = useState("en");
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [field, setField] = useState("");
  const parentCategoryId = useSelector((state: IAllStates) => state.parentId);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }

  useEffect(() => {
    id &&
      CategoryService.getCategoryById(Number(id))
        .then((response) => {
          setCurrentCategory(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);
  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCurrentCategory((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [activeTab]: {
          ...prev?.translation?.[activeTab],
          lang: activeTab,
          [name]: value,
        },
      },
    }));
  };
  const changeCatalog = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentCategory((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [activeTab]: {
          ...prev?.translation?.[activeTab],
          lang: activeTab,
          [name]: value,
        },
      },
    }));
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }
  const handleTranslate = () => {
    ProductsService.postTranslation({
      text: currentCategory?.translation?.[sourceLanguage]?.title,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        const result = response;
        setCurrentCategory((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              title: result,
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleSubmit = () => {
    if (id) {
      CategoryService.updateCategoryById({
        translation: currentCategory?.translation,
        category: currentCategory?.category,
      })
        .then((response) => {
          SuccessToast(
            `${t("toasts.successfully_updated")} ${
              currentCategory?.translation?.en?.title
            }`
          );
          dispatch(categorySlice.actions.setCategory(response));
          // navigate(-1);
        })
        .catch((error) => ErrorToast(error));
    } else {
      CategoryService.addCategory({
        ...(currentCategory as any),
        parent: parentCategoryId,
      })
        .then((response) => {
          //@ts-ignore
          dispatch(categorySlice.actions.setCategory(response));
          SuccessToast(
            `${t("toasts.successfully_created")} ${
              currentCategory?.translation?.en
            }`
          );
          setCurrentCategory({} as any);
          navigate(-1);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    if (currentLanguage) {
      setActiveTab(currentLanguage);
    }
  }, [currentLanguage]);
  return (
    // <PageTemplate
    //   title={
    //     id
    //       ? `${t("category.edit_category")}`
    //       : `${t("category.create_category")}`
    //   }
    // >
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <ul
                className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                {translationLanguageEnum?.map((language) => {
                  return (
                    <li
                      className="nav-item"
                      key={language}
                      onClick={() => changeActiveTab(language)}
                    >
                      <a
                        className={`nav-link ${
                          activeTab === language ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href={`#${language}`}
                        role="tab"
                        aria-selected={language === currentLanguage}
                      >
                        {t(`products.content_${language}`)}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div className="tab-content">
                {translationLanguageEnum?.map((language) => {
                  return (
                    <div
                      className={`tab-pane  ${
                        activeTab === language ? "active" : ""
                      }`}
                      id={language}
                      role="tabpanel"
                    >
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between align-items-center my-1">
                            <label htmlFor="title">
                              {t("category.enterTitle")}
                              {getCorrectLanguage(language, t)}
                            </label>
                            <div className="d-flex justify-content-end align-items-center my-1">
                              <>
                                <Select
                                  className="react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    name="description"
                                    theme={themeSelect}
                                    placeholder={`${t(
                                      "workflow.translate_from"
                                    )}`}
                                    options={translationLanguageEnum
                                      .filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    //   key={state?.id}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "text"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslate}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            </div>
                          </div>
                          {/* <div className="d-flex flex-column justify-content-start align-items-start my-1">
                              <label htmlFor="title">
                                {t("category.enterTitle")}
                                {getCorrectLanguage(language, t)}
                              </label>
                               </div> */}
                          <div className="col-md-4 col-sm-12">
                            <input
                              type="text"
                              name="title"
                              value={
                                (currentCategory as any)?.translation?.[
                                  language
                                ]?.title ?? ""
                              }
                              onChange={changeTranslationHandler}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mt-2">
                          <label htmlFor="catalog">
                            {t("category.catalog")}{" "}
                          </label>
                          <textarea
                            className="form-control dark-text-area"
                            id="catalog"
                            rows={4}
                            name="catalog"
                            onChange={changeCatalog as any}
                            value={
                              currentCategory?.translation?.[language]
                                ?.catalog ?? ""
                            }
                          />
                        </div>

                        <div className="row">
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-info"
                              onClick={() => navigate(-1)}
                            >
                              {t("products.close")}
                            </button>
                            <button
                              className="btn btn-primary ms-2"
                              onClick={() => handleSubmit()}
                            >
                              {id
                                ? `${t("products.save")}`
                                : `${t("global.create")}`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // </PageTemplate>
  );
};
