import React, { ChangeEvent, useState } from "react";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { t } from "i18next";
import Select, { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { themeSelect } from "../../shared/components/Select2";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../shared/functions/Functions";
import { ActualFileObject, FilePondFile } from "filepond";
import { FilePond } from "react-filepond";
import {
  ErrorToast,
  SuccessToast,
  ErrorToastFileTranslate,
} from "../../shared/toasters/toasters";
import { ProductsService } from "../products/service";
import { TRANSLATION_URL } from "../../utils/helpers/api.routes";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ChatGptContent } from "../products/content/components/ChatGptContent";
interface IMessage {
  content: string;
  role: "assistant" | "user";
  sent?: Date;
}
export const Translate = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [textActive, setTextActive] = useState(true);
  const [languages, setLanguages] = useState({});
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [textForTranslate, setTextForTranslate] = useState("");
  // const [textTranslated, setTextTranslated] = useState("");
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const [formality, setFormality] = useState(null);
  const navigate = useNavigate();

  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setTextForTranslate(value);
  }
  function handleChangeTranslate(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setTranslatedText(value);
  }
  function handleSelectSingle1(
    selectedSingle: SingleValue<{ label: string; value: string }>
    // language: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }
  function handleSelectSingle2(
    selectedSingle: SingleValue<{ label: string; value: string }>
    // language: string
  ) {
    setTargetLanguage(selectedSingle?.value as string);
  }
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const handleTranslate = () => {
    ProductsService.postTranslation({
      text: textForTranslate,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response: any) => {
        setMessages([]);
        setTranslatedText(response);
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const showRes = (url: string) => {
    const xhr = new XMLHttpRequest();
    const fileName = url.split("/").at(-1);
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: "application/*" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName ?? "";
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }, 0);
      }
    };
    xhr.send();
  };
  // function toBinaryString(data: any) {
  //   let ret = [];
  //   let len = data.length;
  //   let byte;
  //   for (var i = 0; i < len; i++) {
  //     byte = (data.charCodeAt(i) & 0xff) >>> 0;
  //     ret.push(String.fromCharCode(byte));
  //   }
  //   return ret.join("");
  // }
  return (
    // <PageTemplate title={`${t("side_bar.translate")}`}>
    <div className="col-12">
      <div className="card ">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <ul className="nav nav-pills nav-fill">
                  <li className="nav-item">
                    <span
                      onClick={() => setTextActive((prev) => !prev)}
                      className={`cursor-pointer nav-link ${
                        textActive ? "active" : ""
                      }`}
                    >
                      {t("translate.translate_text")}
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => setTextActive((prev) => !prev)}
                      className={`cursor-pointer ms-3 nav-link ${
                        !textActive ? "active" : ""
                      }`}
                    >
                      {t("translate.translate_files")}
                    </span>
                  </li>
                </ul>
                <div className="">
                  <button
                    className="btn btn-success d-flex align-items-center me-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target={`#offcanvasRight-26`}
                    aria-controls={`offcanvasRight-26`}
                  >
                    <i className="ri-message-3-line me-1" />
                    {t("products.chatGpt")}
                  </button>
                </div>
              </div>
              <>
                <div
                  className="offcanvas offcanvas-end main-height w-50"
                  id={`offcanvasRight-26`}
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">{t("products.chatGpt")}</h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <ChatGptContent
                      messages={messages}
                      setMessages={setMessages}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="card ">
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="d-flex justify-content-between align-items-center col-12">
              <div className="">
                <Select
                  className="w-translate react-select-field"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("global.source_lang")}`}
                  options={translationLanguageEnum?.map((lang) => ({
                    value: lang,
                    label: getCorrectLanguage(lang, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingle1(e as any);
                  }}
                />
              </div>
              <div className="d-flex">
                <Select
                  className="react-select-field me-3"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />
                <Select
                  className={`${
                    targetLanguage
                      ? "w-translate"
                      : "border border-danger w-translate "
                  } react-select-field me-3`}
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("global.target_lang")}`}
                  options={translationLanguageEnum
                    ?.filter((lang) => lang !== sourceLanguage)
                    ?.map((lang) => ({
                      value: lang,
                      label: getCorrectLanguage(lang, t),
                    }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingle2(e as any);
                  }}
                />
                <div className="text-center">
                  {textActive && (
                    <button
                      disabled={targetLanguage === ""}
                      className="btn btn-secondary"
                      onClick={handleTranslate}
                    >
                      {t("global.translate")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {textActive ? (
            <div className="row">
              <div className=" col-md-6 pe-0 m-0">
                <textarea
                  onChange={(e) => handleChange(e as any)}
                  value={textForTranslate}
                  className="w-100 dark-text-area"
                  placeholder={`${t("mapping.type")}...`}
                  rows={10}
                />
              </div>
              <div className=" col-md-6 ps-0 m-0">
                <textarea
                  className="w-100 dark-text-area"
                  // disabled={true}
                  value={translatedText}
                  rows={10}
                  onChange={(e) => handleChangeTranslate(e as any)}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 bg-white">
                <FilePond
                  disabled={targetLanguage === ""}
                  className="my-0 bg-white"
                  files={files}
                  allowReorder={true}
                  labelFileProcessingError={`${t(
                    "toasts.filePondImport.error_mess"
                  )}`}
                  allowMultiple={true}
                  server={{
                    url: TRANSLATION_URL,
                    process: {
                      url: "/upload",

                      headers: {
                        "app-version": localStorage.getItem("appVersion")!,
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                        "Content-Disposition": "attachment",
                        fileName: "nesto",
                      },
                      onload: (response: any) => {
                        showRes(JSON.parse(response).path);
                        setIsLoading(false);
                        return response;
                      },
                      onerror: (response) => {
                        const error = JSON.parse(response);
                        setIsLoading(false);
                        ErrorToastFileTranslate(error.message);
                        return response;
                        // refreshPage();
                      },
                      ondata(data: FormData) {
                        setIsLoading(true);
                        data.append(
                          "body",
                          JSON.stringify({
                            sourceLanguage: sourceLanguage,
                            targetLanguage: targetLanguage,
                            formality: formality,
                          })
                        );
                        return data;
                      },
                    },
                  }}
                  labelIdle={`<div className:"d-flex">
                    <img
                     width="100" height="100"
                      src="../../images/translate/icon_docx_v3.svg"
                      alt=""
                    />
                    <img
                     width="100" height="100"
                      src="../../images/translate/icon_pdf.svg"
                      alt=""
                    />
                    <img
                     width="100" height="100"
                      src="../../images/translate/icon_pptx_v3.svg"
                      alt=""
                    />
                    <img
                    width="100" height="100"
                      src="../../images/translate/xlsx_icon.svg.png"
                      alt=""
                    />
                    </div>
                  <p>Drag and drop to translate PDF, Word (.docx), PowerPoint (.pptx) and Excel (.xlsx) documents</p><n>
                  <span>or</spam>
                   <span class=\"filepond--label-action\">Browse</span>`}
                />
                {isLoading && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    // </PageTemplate>
  );
};
