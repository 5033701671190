import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { IBlock } from "./dto/IBlock";
import { Form } from "react-bootstrap";
import { BlockForm } from "./components/BlockForm";
import { BlockList } from "./components/BlockList";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { DocumentBlockService } from "./service";

const initialState = {
  translation: {
    en: "",
    de: "",
    fr: "",
    it: "",
  },
};

export const BlockTest = () => {
  const [name, setName] = useState({});
  const { t } = useTranslation();
  const [blockList, setBlockList] = useState<IBlock[] | undefined>();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<IBlock | undefined>();
  const [inputField, setInputField] = useState(false);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentBlock?.id) {
      DocumentBlockService.updateBlockById(
        currentBlock?.id,
        currentBlock as unknown as IBlock
      )
        .then((response) => {
          if (response?.id) {
            setInputField(false);
            setCurrentBlock(initialState as any);
            setInputField(!inputField);
            SuccessToast(
              `${t("toasts.successfully_updated")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      DocumentBlockService.createBlock(currentBlock as IBlock)
        .then((response) => {
          if (response?.id) {
            setInputField(false);
            setCurrentBlock(initialState as any);
            setInputField(!inputField);
            SuccessToast(
              `${t("toasts.successfully_created")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination({
        page: 1,
        perPage: 10,
        [name]: value,
      });
    },
    200
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setCurrentBlock({
      ...currentBlock,
      name: value,
      translation: {
        en: value,
        de: value,
        fr: value,
      },
    } as any);
    setName({
      translation: {
        en: value,
        de: value,
        fr: value,
      },
    });
  }

  const editHandler = (BlockId: number) => {
    DocumentBlockService.getBlockById(BlockId)
      .then((response) => {
        setInputField(true);
        setCurrentBlock(response);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    DocumentBlockService.getAllBlocks(pagination)
      .then((response) => {
        const { data } = response;
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setBlockList(data as any);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, inputField]);
  return (
    <>
      <div className="col-xl-12">
        {accessRights?.includes("block_can_create") && !inputField && (
          <div className="card ">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {currentBlock?.id
                  ? `${t("client.update")}`
                  : `${t("document_block.create_new_block")}`}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setInputField(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        )}
        {inputField && (
          <div className="card">
            <div className="card-body">
              <Form onSubmit={submitHandler}>
                <BlockForm
                  handleChange={handleChange}
                  state={currentBlock as IBlock}
                  setState={setCurrentBlock as unknown as IBlock}
                />
                <div className="mt-2 d-flex justify-content-end">
                  <button
                    className="btn btn-info me-2"
                    onClick={() => {
                      setInputField(false);
                      setCurrentBlock({} as any);
                    }}
                  >
                    {t("global.cancel")}
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {currentBlock?.id
                      ? `${t("global.update")}`
                      : `${t("global.create")}`}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
        <BlockList
          accessRights={accessRights}
          blocksList={blockList}
          changeFilterHandler={changeFilterHandler}
          editHandler={editHandler}
          setPagination={setPagination}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
        />
      </div>
    </>
  );
};
