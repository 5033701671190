import React, { SetStateAction, useState } from "react";
import { Spinner } from "react-bootstrap";
import { t } from "i18next";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { ContentService } from "../service";
import { IContentVariationParams } from "../dto/IContent";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { useLocation } from "react-router";
interface ICategoryVariationProps {
  variation: IContentVariationParams;
  setCurrentVariation: React.Dispatch<SetStateAction<IContentVariationParams>>;
  setCurrentVariationTranslation?: React.Dispatch<SetStateAction<any>>;
  setVariationList: React.Dispatch<SetStateAction<IContentVariationParams[]>>;
  handleShow: () => void;
  isContentCopywrite?: boolean;
  handleCheckVariation?: (id: number) => void;
  currentContent?: any;
  setTriggerUpdate: any;
}

export const ContentVariation = ({
  variation,
  setCurrentVariation,
  setCurrentVariationTranslation,
  handleShow,
  setVariationList,
  isContentCopywrite = false,
  handleCheckVariation,
  currentContent,
  setTriggerUpdate,
}: ICategoryVariationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { title, id } = variation;
  const [show, setShow] = useState(false);
  const [variationToDelete, setVariationToDelete] =
    useState<IContentVariationParams>();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const location = useLocation();
  const editHandler = (id: number) => {
    ContentService.getVariation(id)
      .then((response) => {
        handleShow();
        setCurrentVariation(response);
        setCurrentVariationTranslation &&
          setCurrentVariationTranslation(response?.translation);
      })
      .catch((error) => ErrorToast(error));
  };

  const chatGtpHandler = (variationId: number, language: any) => {
    setIsLoading(true);
    ContentService.addNewAiVaraitonFromVariation({
      lang: language !== "" ? language : currentContent?.currentLanguage,
      id: Number(variationId),
    })
      .then((response) => {
        setVariationList(response.data);
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };

  const cloneHandler = (variationId: number) => {
    ContentService.addCloneVaraiton(variationId)
      .then((response) => {
        setVariationList(response.data);
      })
      .catch((error) => ErrorToast(error));
  };

  const deleteHandler = () => {
    ContentService.deleteVariation(variationToDelete?.id as number)
      .then((response) => {
        setTriggerUpdate((prev: boolean) => !prev);
        // if (response.data) {
        //   setVariationList(response.data);
        SuccessToast(
          `${t("toasts.successfully_deleted")} ${variationToDelete?.title}`
        );
        // }
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            {isContentCopywrite && (
              <div className="col-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="format"
                    id={`variation-${id}`}
                    checked={
                      id === currentContent?.variation ||
                      id === currentContent?.variation?.id
                    }
                    onChange={() =>
                      handleCheckVariation &&
                      handleCheckVariation(Number(id) ?? 0)
                    }
                  />
                </div>
              </div>
            )}
            <div className={`${isContentCopywrite ? "col-5" : "col-6"}`}>
              <p className="title-variation">{title ?? "-"}</p>
            </div>
            <div className="col-6">
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-soft-success me-2"
                  onClick={() => cloneHandler(id as number)}
                >
                  <i className="ri-file-copy-line"></i>
                </button>
                {location?.pathname?.includes("content-workflow") ? (
                  <button
                    className="btn btn-sm btn-soft-primary me-1"
                    onClick={() => chatGtpHandler(id as number, "")}
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" role="status" />
                    ) : (
                      <span>{t("global.ai")}</span>
                    )}
                  </button>
                ) : (
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-primary me-1 dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("global.ai")}
                    </button>
                    <ul className="dropdown-menu">
                      {translationLanguageEnum?.map((language: string) => {
                        return (
                          <li>
                            <span
                              className="dropdown-item"
                              onClick={() =>
                                chatGtpHandler(id as number, language as string)
                              }
                            >
                              {getCorrectLanguage(language, t)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <button
                  className="btn btn-sm btn-soft-warning me-2"
                  onClick={() => editHandler(id as number)}
                >
                  <i className="ri-pencil-line" />
                </button>
                <button
                  className={"btn btn-sm btn-soft-danger me-2"}
                  onClick={() => {
                    setShow(true);
                    setVariationToDelete(variation);
                  }}
                >
                  <i className="ri-delete-bin-line" />
                </button>
              </div>
            </div>
          </div>
          <ConfirmDelete
            show={show}
            setShow={setShow}
            itemName={variationToDelete?.title}
            deleteHandler={deleteHandler}
            selectedProduct={variationToDelete}
          />
        </div>
      </div>
    </>
  );
};
