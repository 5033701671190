import React, { useEffect, useState } from "react";
import { Card, Col, Spinner, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ContentUpdate } from "../../products/content/components/ContentUpdate";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ContentStepper } from "./ContentStepper";
import { ContentWorkflowService } from "../service";
import { IWorkflow } from "../../workflow/dto/IWorkflow";
import { EditCopywrite } from "./EditCopywrite";
import { ProductsListView } from "../../products/components/ProductsListView";
import {
  formatDate,
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { themeSelect } from "../../../shared/components/Select2";
import contentTranslationPaginationSlice from "../../../store/contentWorkflow/contentTranslationPagination";

interface IProgressData {
  done: number;
  total: number;
  percent: number;
}
interface IProgressBar {
  assigment: IProgressData;
  translation: IProgressData;
}
const progressArr = ["assigment", "translated"];
export const ContentStep4 = () => {
  // const { id } = useParams();
  const { search } = useLocation();
  const mainTable = true;
  // const currentLang = localStorage.getItem("language");
  const dispatch = useDispatch();
  const stepPagination = useSelector(
    (state: IAllStates) => state.contentTranslationPagination as any
  );
  const [langTab, setLangTab] = useState("");
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [contentCopywrite, setContentCopywrite] = useState({} as any);
  const currenTheme = localStorage.getItem("currentTheme") as string;
  const [updateCopywriteList, setUpdateCopywriteList] = useState(false);
  const [workflowLanguage, setWorkflowLanguage] = useState<string[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const [activeTab, setActiveTab] = useState(currentLanguage ?? "");
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [translatorList, setTranslatorList] = useState<any[]>([]);
  const [staticsView2, setStaticsView2] = useState<any[]>([]);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [isTranslator, setIsTranslator] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [isTranslationDone, setIsTranslationDone] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const [currentWorkflow, setCurrentWorkflow] = useState<any | null>(null);
  const [progressBarData, setProgressBarData] = useState<any | undefined>(
    undefined
  );
  const [defaultTabDisabled, setDefaultTabDisabled] = useState("");
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const [translatorData, setTranslatorData] = useState<any>({ contents: [] });
  const { t } = useTranslation();
  const [productsList, setProductsList] = useState<any[]>([]);
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  // const [pagination, setPagination] = useState({
  //   page: currentPage,
  //   perPage: 10,
  //   category: null,
  //   status: "",
  //   assigned: "",
  //   addedWay: "",
  //   lang: currentLanguage,
  //   brand: null,
  //   attribute: null,
  // });
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showTranslateDialog, setShowTranslateDialog] = useState(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const handlePages = (updatePage: number) => {
    // navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      contentTranslationPaginationSlice.actions.setContentTranslationPagination(
        {
          ...(stepPagination as any),
          page: updatePage,
        }
      )
    );
    setPage(updatePage);
  };
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [userList, setUserList] = useState([]);
  const [formality, setFormality] = useState(null);
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const changeActiveTab = (lng: string) => {
    setActiveTab(lng);
    setCurrentLanguage(lng);
    setPagination({
      ...pagination,
      lang: lng as any,
    });
    dispatch(
      contentTranslationPaginationSlice.actions.setContentTranslationPagination(
        {
          ...(stepPagination as any),
          lang: lng as any,
        }
      )
    );
  };
  // const changeFilterHandler = (

  //   name: string,
  //   value: string,
  //   resetFilters?: boolean
  // ) => {
  //   if (resetFilters) {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       status: "",
  //       assigned: "",
  //       page: 1,
  //     }));
  //   } else if (
  //     pagination?.status === "" &&
  //     (value === "yes" || value === "no")
  //   ) {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       [name]: value,
  //       status: null,
  //       page: 1,
  //     }));
  //   } else {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       [name]: value,
  //       page: 1,
  //     }));
  //   }
  // };
  const changeFilterHandler = (name: string, value: string) => {
    if (value === "") {
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        translator: null,
        assigned: "",
      }));
      dispatch(
        contentTranslationPaginationSlice.actions.setContentTranslationPagination(
          {
            ...(stepPagination as any),
            [name]: value,
            translator: null,
            assigned: "",
          }
        )
      );
    } else if (name === "assigned") {
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        translator: null,
      }));
      dispatch(
        contentTranslationPaginationSlice.actions.setContentTranslationPagination(
          {
            ...(stepPagination as any),
            [name]: value,
            translator: null,
          }
        )
      );
    } else {
      setPagination((prev: any) => ({ ...prev, [name]: value }));
      dispatch(
        contentTranslationPaginationSlice.actions.setContentTranslationPagination(
          {
            ...(stepPagination as any),
            [name]: value,
          }
        )
      );
    }
  };
  const selectTranslatorHandler = (event: any) => {
    setTranslatorData((prev: any) => ({ ...prev, user: +event?.value }));
  };
  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(productsList?.map((product) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const assignTranslatorHandler = async () => {
    id &&
      (await ContentWorkflowService.assignTranslator(Number(id), {
        ...translatorData,
        contents: checkedValues,
        lang: activeTab,
      })
        .then((response) => {
          if (response === "") {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await ContentWorkflowService.changeFinishedStatus(
        Number(id),
        {
          isDone: finishedOrUnfinished,
          contents: checkedValues,
          language: activeTab,
        },
        "translation"
      )
        .then((response) => {
          if (response) {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      contentTranslationPaginationSlice.actions.setContentTranslationPagination(
        {
          ...(stepPagination as any),
          page: 1,
          perPage: e.value,
        }
      )
    );
  };
  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };
  // const editHandler = (copywriteId: number) => {
  //   setSelectedProductId(copywriteId);
  //   ContentWorkflowService.getTranslationById(+id!, copywriteId)
  //     .then((res) => {
  //       setContentCopywrite(res);
  //     })
  //     .catch((err) => ErrorToast(err));
  // };
  const editHandler = (e: any, copywriteId: number) => {
    e.preventDefault();
    if (e.type === "click") {
      navigate(`/app/content-workflow/translation/${id}/${copywriteId}`);
    } else {
      window.open(
        `/app/content-workflow/translation/${id}/${copywriteId}`,
        "_blank"
      );
    }

    // setSelectedProductId(copywriteId);
    // ContentWorkflowService.getTranslationById(+id!, copywriteId)
    // .then((res) => {
    // setContentCopywrite(res);
    // })
    // .catch((err) => ErrorToast(err));
  };
  const handleFilterCopywritingStatus = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      page: 1,
      copywritingStatus: e.value,
    }));
    dispatch(
      contentTranslationPaginationSlice.actions.setContentTranslationPagination(
        {
          ...(stepPagination as any),
          page: 1,
          copywritingStatus: e.value,
        }
      )
    );
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };
  const handleFilterUser = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      translator: e?.value,
      assigned: "",
      page: 1,
    }));
    dispatch(
      contentTranslationPaginationSlice.actions.setContentTranslationPagination(
        {
          ...(stepPagination as any),
          translator: e?.value,
          assigned: "",
          page: 1,
        }
      )
    );
  };
  useEffect(() => {
    ContentWorkflowService.getAllTranslation(pagination, +id!)
      .then((response: any) => {
        const {
          list,
          data,
          count,
          perPage,
          currentLanguage,
          view,
          workflow,
          translators,
          assigment,
          translation,
          isTranslationDone,
          statistics,
          page,
          assignedTranslators,
          sourceLang,
        } = (response as any) ?? {};
        //omly edit action
        setCurrentLanguage(currentLanguage);
        localStorage.setItem(
          "contentTranslationLanguage",
          currentLanguage ?? ""
        );
        setLangTab(currentLanguage);
        setActiveTab(currentLanguage);
        setWorkflowLanguage(workflow?.language ?? []);
        setProductsList(data);
        setIsTranslationDone(isTranslationDone);
        setCurrentWorkflow(workflow as IWorkflow);
        setStaticsView2(response?.statistics ?? []);
        setProgressBarData(statistics);
        window.scrollTo(0, 0);
        setIsTranslator(+view !== 1);
        setTranslatorList(translators);
        setTotalPages(Math.ceil(count / perPage));
        setPage(page);
        setDefaultTabDisabled(workflow?.sourceLang);
        setUserList(assignedTranslators);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, updateList, activeTab]);
  // useEffect(() => {
  //   if (currentPage) {
  //     setPagination((prev: any) => ({ ...prev, page: currentPage }));
  //     dispatch(
  //       contentTranslationPaginationSlice.actions.setContentTranslationPagination(
  //         {
  //           ...(stepPagination as any),
  //           page: currentPage,
  //         }
  //       )
  //     );
  //     setPage(currentPage);
  //   }
  // }, [currentPage, updateCopywriteList]);

  return (
    <>
      <>
        {!isTranslator ? (
          <>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <ContentStepper
                    pagination={pagination}
                    handleFilterUser={handleFilterUser}
                    userList={userList}
                    isDisabledComplete={isTranslationDone}
                    step={"TRANSLATION"}
                    handleFilterCopywritingStatus={
                      handleFilterCopywritingStatus
                    }
                    id={id}
                    copywriteId={0}
                    changeFilterHandler={changeFilterHandler}
                    precent={progressBarData?.["translated"]?.percent}
                    showNextButton={isTranslator}
                    showMarkAsComplete={
                      currentWorkflow?.status === "TRANSLATION" &&
                      (isAdmin || isCompanyAdmin || isManager)
                    }
                    status={pagination?.status}
                    assigned={!isTranslator && (pagination?.assigned ?? "")}
                    // setSelectedProductId={
                    //   !selectedProductId && setSelectedProductId
                    // }
                    handlePerPage={handlePerPage}
                  />
                  {true && (
                    <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-2">
                      {workflowLanguage?.map((language: string) => {
                        return (
                          <li
                            className="nav-item custom-basis"
                            key={language}
                            onClick={() => changeActiveTab(language as string)}
                          >
                            <a
                              className={`nav-link ${
                                activeTab === language ? "active" : ""
                              }`}
                              data-bs-toggle="tab"
                              href={`#${language}`}
                              role="tab"
                              aria-selected={language === currentLanguage}
                            >
                              {t(`products.content_${language}`)}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="tab-content">
                    {workflowLanguage?.map((language, index) => {
                      return (
                        <div
                          key={index}
                          className={`tab-pane  ${
                            activeTab === language ? "active" : ""
                          }`}
                          id={language}
                          role="tabpanel"
                        >
                          <div className="mt-3">
                            <Card>
                              <Card.Body>
                                {progressArr?.map((data) => {
                                  if (data === "assigment" && isTranslator) {
                                    return null;
                                  }
                                  return (
                                    <div
                                      className="card bg-light overflow-hidden shadow-none"
                                      key={data}
                                    >
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <h6 className="mb-0">
                                              {t("workflow.current")} {data}:
                                              <b className="text-success">
                                                {" "}
                                                {
                                                  progressBarData?.[
                                                    data as keyof IProgressBar
                                                  ]?.percent
                                                }
                                                %
                                              </b>
                                            </h6>
                                          </div>
                                          <div className="flex-shrink-0">
                                            <h6 className="mb-0">
                                              {
                                                progressBarData?.[
                                                  data as keyof IProgressBar
                                                ]?.done
                                              }
                                              /
                                              {
                                                progressBarData?.[
                                                  data as keyof IProgressBar
                                                ]?.total
                                              }{" "}
                                              {t("workflow.finished")}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="progress">
                                        {/*//@ts-ignore*/}
                                        <div
                                          className="progress-bar bg-success"
                                          style={{
                                            width: `${
                                              progressBarData?.[
                                                data as keyof IProgressBar
                                              ]?.percent ?? 0
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            progressBarData?.[
                                              data as keyof IProgressBar
                                            ]?.percent as number
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}

                                <Col xs={12}>
                                  <div className="table-responsive">
                                    {
                                      // !isTranslator
                                      true && (
                                        // true
                                        <>
                                          <div className="d-flex align-items-center gap-2 mb-3">
                                            <h6 className="m-0 p-0 ">
                                              {t("workflow.translator")}:
                                            </h6>

                                            {lightOrDarkMode === "dark" ? (
                                              <Select
                                                className="react-select-field"
                                                styles={{
                                                  option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor:
                                                      state.isSelected
                                                        ? "#007aff"
                                                        : "#262a2f",
                                                    ":hover": {
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#007aff"
                                                          : "black",
                                                    },
                                                  }),
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    width: 250,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                    ":hover": {
                                                      backgroundColor: "black",
                                                    },
                                                  }),

                                                  menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: "#262a2f",
                                                  }),
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                }}
                                                menuPortalTarget={document.body}
                                                onChange={
                                                  selectTranslatorHandler
                                                }
                                                isClearable={true}
                                                options={translatorList?.map(
                                                  (translator) => ({
                                                    label: `${
                                                      translator?.firstName
                                                    } ${translator?.lastName}
                                                    ${
                                                      translator.translatorAssignment
                                                        ? `(${
                                                            translator.translatorAssignment
                                                          }${t(
                                                            "products.products"
                                                          ).toLowerCase()})`
                                                        : ""
                                                    } 
                                                  `,
                                                    value: translator.id,
                                                  })
                                                )}
                                              />
                                            ) : (
                                              <Select
                                                className="react-select-field"
                                                styles={{
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    width: 250,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                }}
                                                menuPortalTarget={document.body}
                                                onChange={
                                                  selectTranslatorHandler
                                                }
                                                isClearable={true}
                                                options={translatorList?.map(
                                                  (translator) => ({
                                                    label: `${
                                                      translator?.firstName
                                                    } ${translator?.lastName}
                                                    ${
                                                      translator.translatorAssignment
                                                        ? `(${
                                                            translator.translatorAssignment
                                                          }${t(
                                                            "products.products"
                                                          ).toLowerCase()})`
                                                        : ""
                                                    } 
                                                  `,
                                                    value: translator.id,
                                                  })
                                                )}
                                              />
                                            )}
                                            <button
                                              onClick={assignTranslatorHandler}
                                              className="btn  btn-secondary d-flex align-items-center me-2"
                                              disabled={
                                                !translatorData?.user ||
                                                !checkedValues?.length
                                              }
                                            >
                                              <i className="ri-share-box-fill me-1" />
                                              {t("global.assign")}
                                            </button>

                                            {/* <div> */}
                                            {checkForMainRoles() && (
                                              <>
                                                <button
                                                  onClick={() =>
                                                    changeFinishHandler(true)
                                                  }
                                                  className="btn  btn-success d-flex align-items-center me-2"
                                                  disabled={
                                                    translatorData?.user ||
                                                    !checkedValues?.length
                                                  }
                                                >
                                                  <i className="ri-share-box-fill me-1" />
                                                  {t("global.finished")}
                                                </button>
                                                <button
                                                  onClick={() =>
                                                    changeFinishHandler(false)
                                                  }
                                                  className="btn  btn-danger d-flex align-items-center me-2"
                                                  disabled={
                                                    translatorData?.user ||
                                                    !checkedValues?.length
                                                  }
                                                >
                                                  <i className="ri-share-box-fill me-1" />
                                                  {t("global.unfinished")}
                                                </button>
                                              </>
                                            )}
                                            {accessRights?.includes(
                                              "workflow_content_can_translate_all"
                                            ) && (
                                              <div className="d-flex ms-auto">
                                                {lightOrDarkMode === "dark" ? (
                                                  <Select
                                                    className="custom_zindex react-select-field me-2"
                                                    styles={{
                                                      option: (
                                                        base,
                                                        state
                                                      ) => ({
                                                        ...base,
                                                        backgroundColor:
                                                          state.isSelected
                                                            ? "#007aff"
                                                            : "#262a2f",
                                                        ":hover": {
                                                          backgroundColor:
                                                            state.isSelected
                                                              ? "#007aff"
                                                              : "black",
                                                        },
                                                      }),
                                                      control: (
                                                        baseStyles,
                                                        state
                                                      ) => ({
                                                        ...baseStyles,
                                                        width: 180,
                                                        zIndex:
                                                          showTranslateDialog
                                                            ? 0
                                                            : 9999,
                                                        ":hover": {
                                                          backgroundColor:
                                                            "black",
                                                        },
                                                      }),

                                                      menu: (base) => ({
                                                        ...base,
                                                        backgroundColor:
                                                          "#262a2f",
                                                      }),
                                                      menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex:
                                                          showTranslateDialog
                                                            ? 0
                                                            : 9999,
                                                      }),
                                                    }}
                                                    menuPortalTarget={
                                                      document.body
                                                    }
                                                    name="content"
                                                    theme={themeSelect}
                                                    placeholder={`${t(
                                                      "formality.select_formality"
                                                    )}`}
                                                    options={deeplFormality?.map(
                                                      (formality) => ({
                                                        value: formality,
                                                        label:
                                                          getCorrectFormality(
                                                            formality,
                                                            t
                                                          ),
                                                      })
                                                    )}
                                                    // key={state?.id}
                                                    onChange={(e) => {
                                                      handleSelectSingleFormality(
                                                        e as any
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Select
                                                    styles={{
                                                      control: (
                                                        baseStyles,
                                                        state
                                                      ) => ({
                                                        ...baseStyles,
                                                        width: 180,
                                                        zIndex:
                                                          showTranslateDialog
                                                            ? 0
                                                            : 9999,
                                                      }),
                                                      menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex:
                                                          showTranslateDialog
                                                            ? 0
                                                            : 9999,
                                                      }),
                                                    }}
                                                    menuPortalTarget={
                                                      document.body
                                                    }
                                                    className="custom_zindex react-select-field me-2"
                                                    name="content"
                                                    theme={themeSelect}
                                                    placeholder={`${t(
                                                      "formality.select_formality"
                                                    )}`}
                                                    options={deeplFormality?.map(
                                                      (formality) => ({
                                                        value: formality,
                                                        label:
                                                          getCorrectFormality(
                                                            formality,
                                                            t
                                                          ),
                                                      })
                                                    )}
                                                    // key={state?.id}
                                                    onChange={(e) => {
                                                      handleSelectSingleFormality(
                                                        e as any
                                                      );
                                                    }}
                                                  />
                                                )}

                                                <button
                                                  onClick={(e) =>
                                                    setShowTranslateDialog(true)
                                                  }
                                                  className="btn btn-secondary d-flex align-items-center mx-2"
                                                >
                                                  {isLoading ? (
                                                    <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      role="status"
                                                    />
                                                  ) : (
                                                    <span>
                                                      {t("global.translateAll")}
                                                    </span>
                                                  )}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                          {/* </div> */}
                                        </>
                                      )
                                    }
                                    {!!productsList?.length ? (
                                      <Table className="align-middle table-nowrap mb-0">
                                        <thead className="table-light">
                                          <tr>
                                            <th scope="col">
                                              <input
                                                className="form-check-input m-0 cursor-pointer"
                                                checked={
                                                  checkedValues.length ===
                                                  productsList?.length
                                                }
                                                type="checkbox"
                                                onChange={checkAll}
                                              />
                                            </th>
                                            <th scope="col">{`${t(
                                              "global.id"
                                            )}`}</th>
                                            <th scope="col">{`${t(
                                              "global.title"
                                            )}`}</th>
                                            <th scope="col">
                                              {t("global.finished")}
                                            </th>
                                            <th scope="col">{`${t(
                                              "global.assign"
                                            )}`}</th>
                                            <th scope="col">{`${t(
                                              "global.action"
                                            )}`}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {productsList?.map(
                                            (copywrite: any) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input m-0 cursor-pointer"
                                                      onChange={(e) =>
                                                        checkboxHandler &&
                                                        checkboxHandler(e)
                                                      }
                                                      value={copywrite?.id}
                                                      checked={checkedValues?.includes(
                                                        Number(copywrite?.id)
                                                      )}
                                                    />
                                                  </td>
                                                  <td>{copywrite.id}</td>
                                                  <td>
                                                    {copywrite?.title ?? ""}
                                                  </td>
                                                  <>
                                                    {copywrite && (
                                                      <td>
                                                        <span
                                                          className={`badge badge-outline-${
                                                            copywrite
                                                              ?.translator
                                                              ?.isDone
                                                              ? "success"
                                                              : "danger"
                                                          } badge-soft-${
                                                            copywrite
                                                              ?.translator
                                                              ?.isDone
                                                              ? "success"
                                                              : "danger"
                                                          }`}
                                                        >
                                                          {copywrite?.translator
                                                            ?.isDone
                                                            ? "Yes"
                                                            : "No"}
                                                        </span>
                                                      </td>
                                                    )}
                                                  </>
                                                  <td>
                                                    {copywrite?.translator?.user
                                                      ? copywrite?.translator
                                                          ?.user?.firstName +
                                                        `${" "}` +
                                                        copywrite?.translator
                                                          ?.user?.lastName
                                                      : "-"}
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      {
                                                        // accessRights?.includes(
                                                        //   "brand_can_update"
                                                        // )
                                                        true && (
                                                          <button
                                                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                            onClick={(e) =>
                                                              editHandler(
                                                                e,
                                                                copywrite?.id as number
                                                              )
                                                            }
                                                            onContextMenu={(
                                                              e
                                                            ) =>
                                                              editHandler(
                                                                e,
                                                                copywrite?.id as number
                                                              )
                                                            }
                                                          >
                                                            <i className="ri-edit-2-fill me-1" />
                                                            {`${t(
                                                              "global.edit"
                                                            )}`}
                                                          </button>
                                                        )
                                                      }
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <EmptyState />
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="d-flex justify-content-end ">
                                        {totalPages <= 1 ? (
                                          ""
                                        ) : (
                                          <Pagination
                                            page={page}
                                            totalPages={totalPages}
                                            handlePagination={handlePages}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* ======================================================================================================================================        */}
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <ContentStepper
                    pagination={pagination}
                    isDisabledComplete={isTranslationDone}
                    step={"TRANSLATION"}
                    handleFilterCopywritingStatus={
                      handleFilterCopywritingStatus
                    }
                    id={id}
                    copywriteId={0}
                    changeFilterHandler={changeFilterHandler}
                    precent={progressBarData?.["translation"]?.percent}
                    showNextButton={isTranslator}
                    showMarkAsComplete={
                      currentWorkflow?.status === "TRANSLATION" &&
                      (isAdmin || isCompanyAdmin || isManager)
                    }
                    status={pagination?.status}
                    assigned={!isTranslator && pagination?.assigned}
                    // setSelectedProductId={
                    //   !selectedProductId && setSelectedProductId
                    // }
                    handlePerPage={handlePerPage}
                  />
                  {staticsView2?.map((data) => {
                    return (
                      <div
                        className="card bg-light overflow-hidden shadow-none"
                        key={data?.lang}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h6 className="mb-0">
                                {t("workflow.current")} {data?.lang}:
                                <b className="text-success">
                                  {" "}
                                  {data?.percent}%
                                </b>
                              </h6>
                            </div>
                            <div className="flex-shrink-0">
                              <h6 className="mb-0">
                                {data?.done} {t("workflow.finished")}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="progress">
                          {/*//@ts-ignore*/}
                          <div
                            className="progress-bar bg-success"
                            style={{ width: `${data?.percent ?? 0}%` }}
                            aria-valuenow={data?.percent as number}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                      </div>
                    );
                  })}

                  <>
                    <div className="table-responsive">
                      {!!productsList?.length ? (
                        <Table className="align-middle table-nowrap mb-0 ">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">
                                {`${t("export_client.languages")}`}
                              </th>
                              {staticsView2?.map((lang: any) => (
                                <th scope="col" key={lang?.lang}>{`Finsihed ${
                                  lang?.lang as string
                                }`}</th>
                              ))}
                              <th scope="col">{`${t("global.title")}`}</th>
                              <th scope="col">{`${t(
                                "products.createdAt"
                              )}`}</th>
                              {isTranslator && (
                                <th scope="col">{`${t("global.action")}`}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {productsList?.map((copywrite: any) => {
                              return (
                                <tr>
                                  <td>{copywrite.id}</td>
                                  <td>
                                    {Object.keys((copywrite as any)?.languages)
                                      ?.map(
                                        (lang: string) =>
                                          `${getCorrectLanguage(
                                            lang as string,
                                            t
                                          )}`
                                      )
                                      .join(", ")}
                                  </td>
                                  {staticsView2?.length &&
                                    staticsView2?.map((item: any) => {
                                      return (
                                        <td key={item?.lang}>
                                          {(copywrite as any)?.languages?.[
                                            item?.lang as string
                                          ]?.isDone !== undefined ? (
                                            (copywrite as any)?.languages?.[
                                              item?.lang as string
                                            ]?.isDone ? (
                                              <span className="badge badge-outline-success badge-soft-success">
                                                {t("global.yes")}
                                              </span>
                                            ) : (
                                              <span className="badge badge-outline-danger badge-soft-danger">
                                                {t("global.no")}
                                              </span>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      );
                                    })}
                                  <td>{copywrite?.title ?? ""}</td>
                                  <td>{formatDate(copywrite?.createdAt)}</td>
                                  {/* <td>
                                      {copywrite?.translator?.user
                                        ? copywrite?.translator?.user
                                            ?.firstName +
                                          copywrite?.translator?.user?.lastName
                                        : "-"}
                                    </td> */}
                                  <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                      {
                                        // accessRights?.includes(
                                        //   "brand_can_update"
                                        // )
                                        true && (
                                          <button
                                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                            onClick={(e) =>
                                              editHandler(
                                                e,
                                                copywrite?.id as number
                                              )
                                            }
                                          >
                                            <i className="ri-edit-2-fill me-1" />
                                            {`${t("global.edit")}`}
                                          </button>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <EmptyState />
                      )}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-end ">
                          {totalPages <= 1 ? (
                            ""
                          ) : (
                            <Pagination
                              page={page}
                              totalPages={totalPages}
                              handlePagination={handlePages}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};
