import { useTranslation } from "react-i18next";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import React, { SetStateAction, useEffect, useState } from "react";
import { AttributeValueForm } from "./components/AttributeValueForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  IAttributeValue,
  IAttributeValueList,
  IAttributeValueQuery,
} from "./dto/IAttributeValue";
import { useSelector } from "react-redux";
import { AttributesService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AttributeValueList } from "./components/AttributeValueList";
import { IAllStates } from "../../../store/rootReducer";

const getAllAttributeValues = (
  pagination: IAttributeValueQuery,
  setValueList: React.Dispatch<
    SetStateAction<IAttributeValueList[] | undefined>
  >,
  setTotalPages: React.Dispatch<SetStateAction<number>>,
  setPagination: any,
  setPage: any
) => {
  AttributesService.getAllAttributeValues(pagination)
    .then((response) => {
      if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
        setPage(response?.totalPages);
        setPagination({ ...pagination, page: response?.totalPages });
      }
      setValueList(response.data);
      setTotalPages(Math.ceil(response?.count / response?.perPage));
    })
    .catch((error) => ErrorToast(error));
};

const initialState = {
  translation: {},
};

export const AttributeValue = () => {
  const { id } = useParams();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [currentValue, setCurrentValue] = useState<IAttributeValue>();
  const [valueList, setValueList] = useState<
    IAttributeValueList[] | undefined
  >();
  const [pagination, setPagination] = useState<IAttributeValueQuery>({
    page: 1,
    perPage: 10,
    attribute: Number(id),
  });
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setCurrentValue((prev) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    if (currentValue?.id) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    AttributesService.createAttributeValue({
      translation: {
        ...currentValue?.translation,
      },
      attribute: Number(id),
    })
      .then((response) => {
        if (response?.id) {
          SuccessToast(
            `${t("toasts.successfully_created_value_for")} ${response?.title}`
          );
          handleClose();
          getAllAttributeValues(
            pagination,
            setValueList,
            setTotalPages,
            setPagination,
            setPage
          );
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const deleteHandler = (id: number, title: string) => {
    AttributesService.deleteAttributeValue(Number(id))
      .then((response) => {
        getAllAttributeValues(
          pagination,
          setValueList,
          setTotalPages,
          setPagination,
          setPage
        );
        SuccessToast(`${t("toasts.successfully_deleted")}  ${title}`);
      })
      .catch((error) => ErrorToast(error));
  };

  const handleUpdate = () => {
    AttributesService.updateAttributeValue(
      {
        ...(currentValue as IAttributeValue),
        attribute: Number(id),
      },
      Number(currentValue?.id)
    )
      .then((response) => {
        if (response?.id) {
          SuccessToast(
            `${t("toasts.successfully_updated")} ${response?.title}`
          );
          getAllAttributeValues(
            pagination,
            setValueList,
            setTotalPages,
            setPagination,
            setPage
          );
          handleClose();
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const handleClose = () => {
    setIsMenuOpened(false);
    setCurrentValue(initialState);
  };

  useEffect(() => {
    !!Number(id) &&
      getAllAttributeValues(
        pagination,
        setValueList,
        setTotalPages,
        setPagination,
        setPage
      );
  }, [id, pagination]);
  return (
    // <PageTemplate title={`${t("side_bar.attributes")}`}>
    <div className="col-12">
      <button
        className="btn btn-info d-flex align-items-center mb-3"
        onClick={() => navigate(-1)}
      >
        <i className="ri-arrow-left-line" />
        {t("attributes.back")}
      </button>
      <div className="card">
        <div className="card-body">
          <AttributeValueForm
            changeHandler={changeHandler}
            isMenuOpened={isMenuOpened}
            setIsMenuOpened={setIsMenuOpened}
            handleClose={handleClose}
            currentValue={currentValue as IAttributeValue}
            setCurrentValue={setCurrentValue as any}
            handleCreate={handleSubmit}
          />
        </div>
      </div>

      <AttributeValueList
        valueList={valueList}
        page={page}
        totalPages={totalPages}
        handlePages={handlePages}
        setCurrentValue={setCurrentValue}
        setIsMenuOpened={setIsMenuOpened}
        deleteHandler={deleteHandler}
      />
    </div>
    // </PageTemplate>
  );
};
