import { PageTemplate } from "../../shared/layout/PageTemplate";
import { CrawlerList } from "./components/CrawlerList";
import _debounce from "lodash/debounce";
import { ICrawlersDTO } from "./dto/ICrawler";
import { CrawlerForm } from "./components/CrawlerForm";
import { useTranslation } from "react-i18next";
import { useEffect, useState, ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { CrawlerService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { ICrawler } from "../../model/authentification/IRegister";
import { ICategoryChildren } from "../category/values/dto/ICategory";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { CategoryServicee } from "../../store/category/categoryService";
import categorySlice from "../../store/category/categoryRedux";

const initialState = {
  category: "",
  url: "",
  website: "",
};

export const Crawlers = () => {
  const { t } = useTranslation();
  const [crawlerList, setCrawlerList] = useState<ICrawlersDTO[] | undefined>();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentCrawler, setCurrentCrawler] = useState<ICrawler>();
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const categories = useSelector((state: IAllStates) => state.category);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination((prev) => ({ ...prev, [name]: value }));
    },
    200
  );
  const dispatch = useDispatch();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentCrawler?.website === "") {
      setIsMenuOpened(!isMenuOpened);
    } else {
      if (currentCrawler?.id) {
        CrawlerService.updateCrawlerById(currentCrawler?.id, {
          ...currentCrawler,
          category: (currentCrawler?.category as any)?.id
            ? (currentCrawler?.category as any)?.id
            : currentCrawler.category,
        })
          .then((response) => {
            if (response?.id) {
              setIsMenuOpened(false);
              setCurrentCrawler(initialState as any);
              SuccessToast(
                `${t("toasts.successfully_updated_crawler")} ${
                  response?.website
                }`
              );
            }
          })
          .catch((error) => ErrorToast(error));
      } else {
        CrawlerService.createCrawler(currentCrawler!)
          .then((response) => {
            if (response?.id) {
              setIsMenuOpened(false);
              setCurrentCrawler(initialState as any);
              SuccessToast(
                `${t("toasts.successfully_created")} ${response.name}`
              );
            }
          })
          .catch((error) => {
            ErrorToast(error);
          });
      }
    }
  };
  const closeHandler = () => {
    setIsMenuOpened(false);
    setCurrentCrawler(initialState as any);
  };
  const editHandler = (crawlerId: number) => {
    setIsMenuOpened(true);
    CrawlerService.getCrawlerById(crawlerId)
      .then((response) => {
        setCurrentCrawler(response);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const name = event.target.name;
    setCurrentCrawler((prev) => ({ ...prev, [name]: value }));
  }

  useEffect(() => {
    CrawlerService.getAllCrawlers(pagination)
      .then((response) => {
        const { data } = response;
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setCrawlerList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, isMenuOpened]);

  useEffect(() => {
    // if (currentCrawler?.id) {
    const parentId = currentCrawler?.parents?.find(
      (category: { level: number }) => category.level === 1
    )?.id;
    const correctCategory = categories?.find(
      (category: { id: number }) => category.id === parentId
    );
    setSelectedMainCategory(correctCategory?.children as any);
    // }
  }, [currentCrawler?.id]);

  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    // <PageTemplate title={`${t("side_bar.crawlers")}`}>
    <>
      <div className="col-xl-12 mb-3">
        {!isMenuOpened && (
          <div className="card ">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("crawlers.create_new_crawler")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        )}
        {isMenuOpened && (
          <Form onSubmit={submitHandler}>
            <CrawlerForm
              selectedMainCategory={selectedMainCategory}
              setSelectedMainCategory={setSelectedMainCategory}
              state={currentCrawler}
              setState={setCurrentCrawler}
              handleChange={handleChange}
              closeHandler={closeHandler}
              categories={categories}
            />
          </Form>
        )}
      </div>
      <div className="col-xl-12">
        <CrawlerList
          crawlerList={crawlerList}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
          setPagination={setPagination}
          changeFilterHandler={changeFilterHandler}
          editHandler={editHandler}
          categories={categories}
          state={currentCrawler}
        />
      </div>
    </>
    // </PageTemplate>
  );
};
