import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={showDialog}>
      <Modal.Header>
        <Modal.Title>{t("global.warning")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          <b>{t("modals.some_changes")}</b>
        </h4>
        <br />
        <h5>{t("modals.want_navigate")}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={cancelNavigation}>
          {t("global.no")}
        </Button>
        <Button variant="danger" onClick={confirmNavigation}>
          {t("global.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DialogBox;
