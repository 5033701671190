import React, { ChangeEvent, useEffect, useState } from "react";
import { ClientMappingService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useLocation, useNavigate, useParams } from "react-router";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ListItemIcon, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { t } from "i18next";
import { TreeView } from "@mui/x-tree-view/TreeView";
import categorySlice from "../../../store/category/categoryRedux";
import { CategoryServicee } from "../../../store/category/categoryService";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { EmptyState } from "../../../shared/components/EmptyState";
import { MappingStepper } from "./MappingStepper";
interface InterfaceState {
  state: { id: number };
}
export const UpdateMapping = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state }: InterfaceState = useLocation() as any;
  const clientId = useSelector((state: IAllStates) => state.clientId);
  const [arrayOfCategory, setArrayOfCategory] = useState<any[]>([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const categoryList = useSelector((state: IAllStates) => state.category);
  const [currentClient, setCurrentClient] = useState<any | undefined>();
  const [arrayOfCategoryName, setArrayOfCategoryName] = useState<string[]>([]);
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    ClientMappingService.updateClientMappingById(
      Number(state?.id),
      currentClient?.id,
      {
        name: currentClient?.name,
        categories: arrayOfCategory,
      }
      // currentClient as unknown as any
    )
      .then((response) => {
        if (response?.id) {
          setCurrentClient({});
          SuccessToast(`${t("toasts.successfully_updated")} ${response.name}`);
          navigate(-1);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const handleCategoryArray = (id: number, title: string) => {
    if (arrayOfCategory.includes(id)) {
      const removeItem = arrayOfCategory.filter((number) => number !== id);
      const removeItemName = arrayOfCategoryName.filter(
        (name: string) => name !== title
      );
      setArrayOfCategory(removeItem);
      setArrayOfCategoryName(removeItemName);
    } else {
      setArrayOfCategory([...arrayOfCategory, id]);
      setArrayOfCategoryName([...arrayOfCategoryName, title]);
    }
  };

  const backNavigate = (e: any) => {
    e.preventDefault();
    navigate(`/app/clients/mapping-list/${clientId}`);
  };
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCurrentClient({
      ...currentClient,
      [name]: value,
    });
  }
  const handleAllCategoryArray = (ids: number[], titles: string[]) => {
    if (
      arrayOfCategory.length > ids?.length
        ? ids.some((element: number) => arrayOfCategory.includes(element))
        : arrayOfCategory.some((element: number) => ids.includes(element)) &&
          arrayOfCategory.length > 0
    ) {
      const removeItems = arrayOfCategory.filter((item) => !ids.includes(item));
      const removeItemNames = arrayOfCategoryName.filter(
        (item) => !titles.includes(item)
      );
      setArrayOfCategory(removeItems);
      setArrayOfCategoryName(removeItemNames);
    } else {
      setArrayOfCategory([...arrayOfCategory, ...ids]);
      setArrayOfCategoryName([...arrayOfCategoryName, ...titles]);
    }
  };
  const handleMainCategoryArray = (ids: any[], titles: any[]) => {
    let checkedIds = [] as number[];
    for (let i = 0; i < ids?.length; i++) {
      checkedIds = [...checkedIds, ...ids[i]];
    }
    let checkedTitles = [] as string[];
    for (let i = 0; i < titles?.length; i++) {
      checkedTitles = [...checkedTitles, ...titles[i]];
    }
    if (
      arrayOfCategory.length > checkedIds?.length
        ? checkedIds.some((element: number) =>
            arrayOfCategory.includes(element)
          )
        : arrayOfCategory.some((element: number) =>
            checkedIds.includes(element)
          ) && arrayOfCategory.length > 0
    ) {
      const removeItems = arrayOfCategory.filter(
        (item) => !checkedIds.includes(item)
      );
      const removeItemNames = arrayOfCategoryName.filter(
        (item) => !checkedTitles.includes(item)
      );
      setArrayOfCategory(removeItems);
      setArrayOfCategoryName(removeItemNames);
    } else {
      let selectedValue = [] as number[];
      for (let i = 0; i < ids?.length; i++) {
        selectedValue = [...selectedValue, ...ids[i]];
      }
      let selectedValueName = [] as string[];
      for (let i = 0; i < titles?.length; i++) {
        selectedValueName = [...selectedValueName, ...titles[i]];
      }
      setArrayOfCategory([...arrayOfCategory, ...selectedValue]);
      setArrayOfCategoryName([...arrayOfCategoryName, ...selectedValueName]);
    }
  };
  function mainCatColor(array: any[]) {
    let test = [] as number[];
    for (let i = 0; i < array?.length; i++) {
      if (!!array[i]?.length) {
        test = [...test, ...array[i]];
      }
    }
    return test?.map((item: any) => item?.id);
  }
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    ClientMappingService.getClientMappingPerProfileById(
      Number(clientId),
      Number(id)
    )
      .then((response) => {
        const categoryParent = response?.profileCategories?.map(
          (category: any) => category?.parent?.id
        );
        const categoryGrandParent = response?.profileCategories?.map(
          (category: any) => category?.parent?.parent?.id
        );
        setExpandedItems(categoryParent.concat(categoryGrandParent));
        const idsArray = response?.profileCategories
          ? response?.profileCategories?.map((category: any) => category.id)
          : [];
        const titlesArray = response?.profileCategories
          ? response?.profileCategories?.map((category: any) => category.title)
          : [];
        setCurrentClient(response);
        setArrayOfCategory(idsArray);
        setArrayOfCategoryName(titlesArray);
      })
      .catch((error) => ErrorToast(error));
  }, [id]);

  // Function to handle expanding/collapsing of tree items
  const handleExpandedItems = (event: any, nodes: any) => {
    setExpandedItems(nodes);
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <MappingStepper step={"SETTING_UP"} />
          <div className="tab-content">
            <div className="tab-pane active show" id="home1" role="tabpanel">
              <div className="d-flex">
                <div className="flex-grow-1 ms-2">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className={`col-md-6 my-3`}>
                        <label htmlFor="name" className="required-field">
                          {`${t("global.name")}`}
                        </label>
                        <input
                          type="text"
                          name="name"
                          required
                          value={currentClient?.name}
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="card" style={{ minHeight: "240px" }}>
                          <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            // onNodeSelect={handleNodeSelect}
                            //@ts-ignore
                            expanded={expandedItems}
                            onNodeToggle={handleExpandedItems}
                          >
                            {!!categoryList?.length ? (
                              categoryList?.map((category1: any) => {
                                return (
                                  <TreeItem
                                    nodeId={category1?.id as unknown as string}
                                    label={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                        //@ts-ignore
                                        expanded={expandedItems}
                                        onNodeToggle={handleExpandedItems}
                                      >
                                        <Typography variant="body1">
                                          {category1.title +
                                            ` (${category1?.total})`}
                                        </Typography>
                                        <ListItemIcon>
                                          <CheckIcon
                                            style={{
                                              display: `${
                                                !!category1?.children?.length
                                                  ? category1?.children.map(
                                                      (category3: any) =>
                                                        !!category3?.children
                                                          ?.length
                                                          ? "block"
                                                          : "none"
                                                    )
                                                  : "none"
                                              }`,
                                              color: `${
                                                mainCatColor(
                                                  category1?.children?.map(
                                                    (item: any) =>
                                                      item?.children?.map(
                                                        (child: any) => child
                                                      )
                                                  )
                                                ).every((element: any) =>
                                                  arrayOfCategory.includes(
                                                    element
                                                  )
                                                )
                                                  ? "#1de60f"
                                                  : "#757575"
                                              }`,
                                            }}
                                            onClick={() =>
                                              handleMainCategoryArray(
                                                category1?.children.map(
                                                  (category3: any) =>
                                                    category3?.children.map(
                                                      (category: any) =>
                                                        category?.id
                                                    )
                                                ),
                                                category1?.children.map(
                                                  (category3: any) =>
                                                    category3?.children.map(
                                                      (category: any) =>
                                                        category?.title
                                                    )
                                                )
                                              )
                                            }
                                          />
                                          {/* )} */}
                                          {/* Replace with your desired icon */}
                                        </ListItemIcon>
                                      </div>
                                    }
                                  >
                                    {!!category1?.children?.length && (
                                      <>
                                        {category1?.children?.map(
                                          (category2: any) => {
                                            return (
                                              <TreeItem
                                                nodeId={
                                                  category2?.id as unknown as string
                                                }
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                    //@ts-ignore
                                                    expanded={expandedItems}
                                                    onNodeToggle={
                                                      handleExpandedItems
                                                    }
                                                  >
                                                    <Typography variant="body1">
                                                      {category2.title +
                                                        ` (${category2?.total})`}
                                                    </Typography>
                                                    <ListItemIcon>
                                                      <CheckIcon
                                                        style={{
                                                          marginRight: "55px",
                                                          display: `${
                                                            !!category2
                                                              ?.children?.length
                                                              ? "block"
                                                              : "none"
                                                          }`,
                                                          color: `${
                                                            category2?.children.every(
                                                              (element: any) =>
                                                                arrayOfCategory.includes(
                                                                  element?.id
                                                                )
                                                            )
                                                              ? "#1de60f"
                                                              : "#757575"
                                                          }`,
                                                        }}
                                                        onClick={() =>
                                                          handleAllCategoryArray(
                                                            category2?.children.map(
                                                              (
                                                                category3: any
                                                              ) => category3?.id
                                                            ),
                                                            category2?.children.map(
                                                              (
                                                                category3: any
                                                              ) =>
                                                                category3?.title
                                                            )
                                                          )
                                                        }
                                                      />
                                                      {/* )} */}
                                                      {/* Replace with your desired icon */}
                                                    </ListItemIcon>
                                                  </div>
                                                }
                                              >
                                                {!!category2?.children
                                                  ?.length &&
                                                  category2?.children?.map(
                                                    (category3: any) => {
                                                      return (
                                                        <TreeItem
                                                          nodeId={
                                                            category3?.id as unknown as string
                                                          }
                                                          label={
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "space-between",
                                                              }}
                                                              //@ts-ignore
                                                              expanded={
                                                                expandedItems
                                                              }
                                                              onNodeToggle={
                                                                handleExpandedItems
                                                              }
                                                            >
                                                              <Typography variant="body1">
                                                                {category3.title +
                                                                  ` (${category3?.total})`}
                                                              </Typography>
                                                              <ListItemIcon>
                                                                {/* {category3?.total === 0 && ( */}
                                                                <CheckIcon
                                                                  style={{
                                                                    color: `${
                                                                      arrayOfCategory.includes(
                                                                        category3?.id
                                                                      )
                                                                        ? "#1de60f"
                                                                        : "#757575"
                                                                    }`,
                                                                  }}
                                                                  onClick={() =>
                                                                    handleCategoryArray(
                                                                      category3?.id,
                                                                      category3?.title
                                                                    )
                                                                  }
                                                                />
                                                                {/* )} */}
                                                                {/* Replace with your desired icon */}
                                                              </ListItemIcon>
                                                            </div>
                                                          }
                                                        ></TreeItem>
                                                      );
                                                    }
                                                  )}
                                              </TreeItem>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </TreeItem>
                                );
                              })
                            ) : (
                              <TreeItem nodeId={"1"} label="Applications">
                                <EmptyState />
                              </TreeItem>
                            )}
                          </TreeView>
                        </div>
                      </div>
                      <div className="col-6">
                        {" "}
                        <textarea
                          value={arrayOfCategoryName?.join("\n")}
                          disabled={true}
                          className="w-100 dark-text-area"
                          placeholder={`${t("empty_state.empty")}...`}
                          rows={12}
                        ></textarea>
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-info me-3 d-flex align-items-center"
                            onClick={(e) => backNavigate(e)}
                          >
                            <i className="ri-arrow-left-line me-1" />
                            {t("global.cancel")}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success d-flex align-items-center"
                            // onClick={() => navigate(-1)}
                          >
                            {t("products.save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
