import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { Pagination } from "../../../shared/paginations/Paginations";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { ProductsService } from "../service";
import _debounce from "lodash/debounce";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { formatDate, formatRoles } from "../../../shared/functions/Functions";

export const ProductsHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataHistoryList, setDataHistoryList] = useState<any[]>([]);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination({
        page: 1,
        perPage: 10,
        [name]: value,
      });
    },
    200
  );
  const showAllRoles = (user: any) => {
    const roles = (user?.roles as any[]) ?? [];
    const customRolesArray =
      user?.customRoles?.map((role: any) => role?.name as string) ?? [];
    const allRoles = roles.concat(customRolesArray);
    return formatRoles(allRoles);
  };
  useEffect(() => {
    id &&
      ProductsService.getDataHistory(pagination, Number(id))
        .then((response) => {
          const { data } = response;
          if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
            setPage(response?.totalPages);
            setPagination({ ...pagination, page: response?.totalPages });
          }
          setTotalPages(Math.ceil(response?.count / response?.perPage));
          setDataHistoryList(data);
        })
        .catch((error) => ErrorToast(error));
  }, [pagination, id]);
  return (
    <div className="col-md-12">
      <button
        className="btn btn-info d-flex align-items-center mb-3"
        onClick={() => navigate(-1)}
      >
        <i className="ri-arrow-left-line" />
        {t("attributes.back")}
      </button>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">{`${t(
              "products.list_data_history"
            )}`}</h4>
          </div>
        </div>
        <div className="card-body">
          {/* <div className="d-flex justify-content-end">
            <div className="col-12 col-sm-6 col-md-4">
              <input
                className="form-control mb-3"
                name={"name"}
                onChange={changeFilterHandler}
                placeholder={`${t("brand.searchName")}...`}
                type="text"
                maxLength={30}
              />
            </div>
          </div> */}
          {dataHistoryList == undefined ? (
            <LoadingAnimation />
          ) : dataHistoryList.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("users.user")}`}</th>
                    <th scope="col">{`${t("users.roles")}`}</th>
                    <th scope="col">{`${t("users.activity")}`}</th>
                    <th scope="col">{`${t("global.updated_at")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {dataHistoryList?.map((data) => {
                    return (
                      <tr key={data?.id}>
                        <td>
                          <Link to="#" className="fw-medium">
                            #{data?.id}
                          </Link>
                        </td>
                        <td className="text-start">
                          <CustomImageHandler
                            classes="avatar-xs rounded-circle me-2"
                            path={data?.user?.profilePicture?.path}
                            alt="User logo"
                          />
                          {data?.user?.firstName} {data?.user?.lastName}
                        </td>
                        <td>{showAllRoles(data?.user)}</td>

                        <td>{`${t(`side_bar.${data?.activity}`)}`}</td>
                        <td>{formatDate(data?.updatedAt)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
