import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { GlossaryCreate } from "./components/GlossaryCreate";
import { GlossaryService } from "./service";
import { ClientService } from "../clients/service";
import { IExport } from "../exports/dto/IExports";
import { GlossaryList } from "./components/GlossaryList";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { themeSelect } from "../../shared/components/Select2";
import _debounce from "lodash/debounce";

export const Glossary = () => {
  const currentLanguage = localStorage.getItem("language");
  const { t } = useTranslation();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [activeTab, setActiveTab] = useState(currentLanguage);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentGlossary, setCurrentGlossary] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [glossaryList, setGlossaryList] = useState(undefined);

  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    perPage: 10,
    language: currentLanguage,
    client: null,
  });
  const [filterParams, setFilterParams] = useState<any>({
    word: "",
  });

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const submitHandler = () => {
    if (currentGlossary.id) {
      GlossaryService.updateGlossary(
        {
          id: currentGlossary?.id as number,
          language: currentGlossary?.language,
          alternative: currentGlossary?.alternative,
          word: currentGlossary?.word,
          client: currentGlossary?.client?.id,
        },
        // currentGlossary,
        currentGlossary.id
      )
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")}`);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentGlossary({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      GlossaryService.createGlossary({
        language: currentGlossary?.language,
        alternative: currentGlossary?.alternative,
        word: currentGlossary?.word,
        client: currentGlossary?.client?.id,
      })
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")}`);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentGlossary({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const editHandler = (glossaryId: number) => {
    setIsMenuOpened(true);
    GlossaryService.getGlossary(glossaryId)
      .then((response) => {
        setCurrentGlossary({
          id: response?.id as number,
          language: response?.language,
          alternative: response?.alternative,
          word: response?.word,
          client: {
            id: response?.client?.id,
            name: response?.client?.name,
          },
        });
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const changeActiveTab = (lng: string) => {
    setActiveTab(lng);

    setPagination({ ...pagination, language: lng, client: null, page: 1 });
    setFilterParams({ word: "" });
    // GlossaryService.getAllGlossary({ ...pagination, language: lng})
    // .then((response) => {
    //   setGlossaryList(response?.data);
    //   setTotalPages(Math.ceil(response?.count / response?.perPage));
    // })
    // .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    ClientService.getAllClients({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    // if(activeTab === currentLanguage){
    GlossaryService.getAllGlossary({
      ...pagination,
      ...filterParams,
      client: pagination.client?.id,
    })
      .then((response) => {
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setGlossaryList(response?.data);
        setPage(response?.page);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
    // }
  }, [triggerUpdate, pagination]);

  const debounced = _debounce((e) => {
    const { value, name } = e.target;
    setPagination({ ...pagination, [name]: value });
  }, 200);

  const handleChangeFilter = (e: any) => {
    const { value, name } = e.target;
    setFilterParams({ ...filterParams, [name]: value });
    debounced(e);
  };

  return (
    // <PageTemplate title={`${t("side_bar.glossary")}`}>
    <>
      {accessRights?.includes("glossary_can_create") && !isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("glossary.create_new_glossary")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <GlossaryCreate
              setIsMenuOpened={setIsMenuOpened}
              state={currentGlossary}
              setState={setCurrentGlossary}
              clientList={clientList}
            />
          </Form>
        </div>
      )}
      {/* <div className="col-12">
          <GlossaryList
            page={page}
            accessRights={accessRights}
            handlePages={handlePages}
            totalPages={totalPages}
            glossaryList={glossaryList}
            setTriggerUpdate={setTriggerUpdate}
            editHandler={editHandler}
          />
        </div> */}
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0">
              {translationLanguageEnum?.map((language, index) => {
                return (
                  <li
                    className="nav-item custom-basis"
                    key={index}
                    onClick={() => changeActiveTab(language)}
                  >
                    <a
                      className={`nav-link ${
                        activeTab === language ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href={`#${language}`}
                      // href={`?lang=${language}`}
                      role="tab"
                      aria-selected={language === currentLanguage}
                    >
                      {t(`products.content_${language}`)}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="d-flex justify-content-start align-items-center mt-4">
              <div className="col-md-3 my-2">
                <input
                  type="text"
                  name="word"
                  value={filterParams?.word!}
                  placeholder={t("glossary.word")}
                  onChange={handleChangeFilter}
                  className="form-control"
                />
              </div>
              <Select
                theme={themeSelect}
                isClearable
                placeholder={t("export_client.client")}
                className={`ms-1 react-select-field col-3`}
                value={pagination?.client}
                onChange={(e) => setPagination({ ...pagination, client: e })}
                options={clientList as IExport[]}
              />
            </div>
            <div className="tab-content">
              {translationLanguageEnum?.map((language, index) => {
                return (
                  <div
                    key={index}
                    className={`tab-pane  ${
                      activeTab === language ? "active" : ""
                    }`}
                    id={language}
                    role="tabpanel"
                  >
                    <div className="mt-3">
                      <GlossaryList
                        page={page}
                        accessRights={accessRights}
                        handlePages={handlePages}
                        totalPages={totalPages}
                        glossaryList={glossaryList}
                        setTriggerUpdate={setTriggerUpdate}
                        editHandler={editHandler}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
    // </PageTemplate>
  );
};
