import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IAttributes } from "../dto/IAttributes";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { WarningToast } from "../../../../shared/toasters/toasters";
import { LoadingAnimation } from "../../../../shared/components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";

interface IAttributesListProps {
  listOfAttributes: IAttributes[] | undefined;
  totalPages: number;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  handlePages: (page: number) => void;
}

export const AttributesList = ({
  listOfAttributes,
  handlePages,
  page,
  totalPages,
  changeFilterHandler,
}: IAttributesListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const listProducts = (idAtt: number, num: any, titleAtt: string) => {
    if (num == 0) {
      WarningToast("Number of products is 0");
    } else {
      navigate(`/admin/attributes/list/${idAtt}`, {
        state: { title: titleAtt },
      });
    }
  };
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mg-b-0">
                {`${t("attributes.listOfAttributes")}`}
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-end">
              <input
                name="title"
                type="text"
                onChange={changeFilterHandler}
                className="form-control w-25 mb-3"
                placeholder={`${t("clients.enterName")}...`}
              />
            </div>
            {listOfAttributes == undefined ? (
              <LoadingAnimation />
            ) : listOfAttributes.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.name")}`}</th>
                      <th scope="col">{`${t(
                        "attributes.number_of_products"
                      )}`}</th>
                      <th scope="col">
                        {accessRights?.includes("attribute_can_update")
                          ? `${t("global.actions")}`
                          : `${t("global.action")}`}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfAttributes?.map((attribute) => {
                      return (
                        <tr key={attribute?.id}>
                          <td>
                            <Link to="#" className="fw-medium">
                              #{attribute?.id}
                            </Link>
                          </td>
                          <td>{attribute?.title ?? "-"}</td>
                          <td>
                            <span
                              className="custom_pointer"
                              onClick={() =>
                                listProducts(
                                  attribute?.id,
                                  attribute?.numberOfProducts as any,
                                  attribute?.title
                                )
                              }
                            >
                              {attribute?.numberOfProducts ?? "-"}
                            </span>
                          </td>

                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              {accessRights?.includes(
                                "attribute_can_update"
                              ) && (
                                <button
                                  className="btn btn-sm btn-warning d-flex align-items-center "
                                  onClick={() =>
                                    navigate(
                                      `/admin/attributes/names/update/${attribute?.id!}`
                                    )
                                  }
                                >
                                  <i className="ri-file-text-line me-1" />
                                  {`${t("attributes.editTitle")}`}
                                </button>
                              )}
                              <button
                                className="btn btn-sm btn-primary d-flex align-items-center ms-2"
                                onClick={() =>
                                  navigate(
                                    `/admin/attributes/value/${attribute?.id!}`,
                                    { state: { title: attribute?.title } }
                                  )
                                }
                              >
                                <i className="ri-pencil-ruler-2-line me-1" />
                                {t("companies.editValues")}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  );
};
