import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { IAttributes } from "../dto/IAttributes";
import { IBrand } from "../../../brand/dto/IBrand";
import { IAllStates } from "../../../../store/rootReducer";
import {
  IProductVariation,
  IProductsList,
} from "../../../products/dto/IProducts";
import { SingleValue } from "react-select";
import { BrandService } from "../../../brand/service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { AttributesService } from "../../service";
import { CategoryServicee } from "../../../../store/category/categoryService";
import categorySlice from "../../../../store/category/categoryRedux";
import { Col, Table } from "react-bootstrap";
import { ProductsCardView } from "../../../products/components/ProductsCardView";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { ProductsListView } from "../../../products/components/ProductsListView";
import { Pagination } from "../../../../shared/paginations/Paginations";
import _debounce from "lodash/debounce";
import { ProductsService } from "../../../products/service";
import { ExportService } from "../../../exports/service";
import { ProductsFilters } from "../../../products/components/ProductsFilters";
import expOrAttPaginationSlice from "../../../../store/category/expOrAttPagination";
import VariationUpdateForm from "../../../products/content/components/VariationUpdateForm";
const variationInitial = {
  title: null,
  lang: "",
  bullets: "",
  introText: "",
  description: "",
  subtitle: "",
  blocks: {},
} as any;

export const ListProductsByAttribute = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state: title }: any = useLocation();
  const [isListView, setIsListView] = useState(true);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const { t } = useTranslation();
  // const [certainBlocks, setCertainBlocks] = useState([]);
  const { search } = useLocation();
  const location = useLocation();
  const [categoryFilterId, setCategoryFilterId] = useState(null);
  const [addedWayFilter, setAddedWayFilter] = useState("");
  const [termFilter, setTermFilter] = useState("");
  const mainTable = true;
  const expOrAttPagination = useSelector(
    (state: IAllStates) => state.expOrAttPagination as any
  );
  const [currentVariationTranslation, setCurrentVariationTranslation] =
    useState<IProductVariation>();
  const [currentVariation, setCurrentVariation] = useState<IProductVariation>(
    variationInitial as any
  );
  const nameOfTable = "productByAttribute";
  const [attOrExp, setAttOrExp] = useState("");
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const [brandsList, setBrandsList] = useState<IBrand[]>([]);
  const [brandId, setBrandId] = useState(null);
  const [attributeId, setAttributeId] = useState(null);
  const exportList = location?.pathname?.includes("export/list") as boolean;
  const [listAttribute, setListAttribute] = useState<IAttributes[]>([]);
  // const currentPage = Number(
  //   +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  // );
  const currentPage =
    expOrAttPagination?.page ??
    Number(search.split("=").at(-1) ? search.split("=").at(-1) : 1);
  //   const { accessRights } = useSelector((state: IAllStates) => state.auth);
  // const [pagination, setPagination] = useState({
  //   ...(expOrAttPagination as any),
  //   // page: currentPage,
  //   perPage: 12,
  // });
  const [pagination, setPagination] = useState({
    ...(expOrAttPagination as unknown as any),
    // page: currentPage,
    perPage: 12,
  });
  // const [pagination, setPagination] = useState({
  //   page: currentPage,
  //   perPage: 12,
  //   category: null,
  //   addedWay: "",
  //   brand: null,
  //   attribute: null,
  // });
  const tab = "BASE_DATA";
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);
  const [productsList, setProductsList] = useState<IProductsList[]>([]);
  // const handlePages = (updatePage: number) => {
  //   navigate(`?page=${updatePage}`);
  //   setPagination({ ...pagination, page: updatePage });
  //   setPage(updatePage);
  // };
  const handlePages = (updatePage: number) => {
    // navigate(`${location.pathname}?page=${updatePage}`, {
    //   state: {
    //     ...pagination,
    //     page: updatePage,
    //   },
    // });
    navigate(`?page=${updatePage}`);
    dispatch(
      expOrAttPaginationSlice.actions.setExpOrAttPagination({
        ...(expOrAttPagination as any),
        page: updatePage,
        headTitle: attTitle,
      })
    );
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const categories = useSelector((state: IAllStates) => state.category);
  const navigate = useNavigate();
  const attTitle = { state: title }?.state?.title ?? location?.state;
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setTermFilter(value);
      // dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination((prev: any) => ({
      //   ...prev,
      //   page: 1,
      //   [name]: value,
      // })));
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          page: 1,
          [name]: value,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        [name]: value,
      }));
    },
    200
  );
  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial as any);
  };
  // const handleShow = () => {
  //   setShowVariationModal(true);
  //   setCertainBlocks(currentProduct?.block as any);
  // };
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setCategoryFilterId(selectedSingle?.value as any);
      // showListAttribute(selectedSingle?.value as any);
      // dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination((prev: any) => ({
      //   ...prev,
      //   page: 1,
      //   category: selectedSingle?.value as number,
      //   categoryData: {
      //     label: selectedSingle?.label,
      //     value: selectedSingle?.value,
      //   },
      // })));
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          page: 1,
          category: selectedSingle?.value as number,
          categoryData: {
            label: selectedSingle?.label,
            value: selectedSingle?.value,
          },
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        category: selectedSingle?.value as number,
        categoryData: {
          label: selectedSingle?.label,
          value: selectedSingle?.value,
        },
      }));
    } else {
      setCategoryFilterId(null);
      setPagination((prev: any) => ({
        ...prev,
        category: null,
        categoryData: null,
      }));
    }
  };

  function handleSelectBrandsExpAtt(selectedSingle: any, title: string) {
    const arr = selectedSingle?.map((value: any) => value?.value);

    const brandsDefaultValue = selectedSingle;
    dispatch(
      expOrAttPaginationSlice.actions.setExpOrAttPagination({
        ...(expOrAttPagination as any),
        page: 1,
        [title]: arr,
        brandsDefaultValue: brandsDefaultValue,
      })
    );
    setPagination((prev: any) => ({
      ...prev,
      page: 1,
      [title]: arr,
      brandsDefaultValue: brandsDefaultValue,
    }));
  }
  //
  const handleSelectAllCategoryExpAtt = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (!selectedSingle) {
      // handleSelectMainCategory(null);
      // setSelectedMainCategory(null as any);
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          mainCategory: null,
          category: null,
          categoryData: null,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        mainCategory: null,
        category: null,
        categoryData: null,
      }));
      // setPagination({ ...pagination, mainCategory: null, category: null, categoryData: null });
    } else {
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          page: 1,
          mainCategory: selectedSingle,
          category: null,
          categoryData: null,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        mainCategory: selectedSingle,
        category: null,
        categoryData: null,
      }));
      // setPagination({ ...pagination, mainCategory: selectedSingle, category: null, categoryData: null });
      const selected = categories?.find(
        (category: { id: number | undefined }) =>
          category?.id === (selectedSingle?.value as number)
      )?.children;
      // setBusinessUnit(selected);
      // selected && setSelectedMainCategory(selected);
    }
  };

  const showListAttribute = (categoryId: any) => {
    //     CategoryService.getCategoryAttributesValue(categoryId)
    //       .then((response) => {
    //         setListAttribute(response);
    //       })
    //       .catch((error) => ErrorToast(error));
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setAddedWayFilter(selectedSingle?.value as string);
      // dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination((prev: any) => ({
      //   ...prev,
      //   page: 1,
      //   addedWay: selectedSingle?.value as string,
      // })));
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          page: 1,
          addedWay: selectedSingle?.value as string,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        addedWay: selectedSingle?.value as string,
      }));
    } else {
      setAddedWayFilter("");
      setPagination((prev: any) => ({
        ...prev,
        addedWay: "",
      }));
    }
  };
  const handleSelectBrand = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setBrandId(selectedSingle?.value as any);
      // dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination((prev: any) => ({
      //   ...prev,
      //   page: 1,
      //   brand: selectedSingle?.value as number,
      //   brandData: selectedSingle,
      // })));
      dispatch(
        expOrAttPaginationSlice.actions.setExpOrAttPagination({
          ...(expOrAttPagination as any),
          page: 1,
          brand: selectedSingle?.value as number,
          brandData: selectedSingle,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        brand: selectedSingle?.value as number,
        brandData: selectedSingle,
      }));
    } else {
      setBrandId(null);
      setPagination((prev: any) => ({
        ...prev,
        brand: null,
      }));
    }
  };

  const backOnTable = () => {
    if (attOrExp === "export") {
      navigate("/app/export");
    } else if (attOrExp === "attributes") {
      navigate("/admin/attributes/names");
    } else navigate("/app/export");
  };
  const deleteHandler = (product: IProductsList, variation: any) => {
    if (product && variation) {
      ExportService.removeVariationProduct(
        product?.id as number,
        variation?.id as any,
        Number(id)
      )
        .then((response) => {
          setTriggerDelete((prev: boolean) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
    } else {
      ExportService.removeExportProduct(product?.id as number, Number(id))
        .then((response) => {
          setTriggerDelete((prev: boolean) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleShow = () => {
    setShowVariationModal(true);
  };

  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/base-data/${id}`);
  };
  const editClickHandler = (e: any, id: number) => {
    if (
      (window.location.pathname?.includes("attributes") ||
        window.location.pathname?.includes("export")) &&
      e.type === "click"
    ) {
      navigate(`/app/products/base-data/${id}`);
    } else if (
      window.location.pathname?.includes("attributes") ||
      (window.location.pathname?.includes("export") && e.type === "contextmenu")
    ) {
      window.open(`/app/products/base-data/${id}`, "_blank");
    } else if (window.location.pathname?.includes("variations")) {
      ProductsService.getVariation(id)
        .then((response) => {
          handleShow();
          setCurrentVariation(response);
          setCurrentVariationTranslation &&
            setCurrentVariationTranslation(response?.translation);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const handleChangeCategory = (name: string, value: string) => {
    setPagination((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (window.location.pathname?.includes("export")) {
      ExportService.getAllProductsByExport(
        {
          perPage: pagination.perPage,
          category: (pagination as any)?.category,
          page: pagination?.page,
          addedWay: (pagination as any)?.addedWay,
          eanCode: (pagination as any)?.eanCode,
          title: (pagination as any)?.title,
          brands: (pagination as any)?.brands,
        } as any,
        id as any
      )
        .then((response) => {
          const { data } = response;
          if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
            setPage(response?.totalPages);
            setPagination({ ...pagination, page: response?.totalPages });
          }
          setProductsList(data as any);
          setTotalPages(Math.ceil(response?.count / response?.perPage));
        })
        .catch((error) => ErrorToast(error));
    } else if (window.location.pathname?.includes("attributes")) {
      AttributesService.getAllProductsByAttributes(
        {
          perPage: pagination.perPage,
          attributeValues: pagination?.attributeValues,
          category: (pagination as any)?.category,
          page: pagination?.page,
          addedWay: (pagination as any)?.addedWay,
          eanCode: (pagination as any)?.eanCode,
          title: (pagination as any)?.title,
          brands: (pagination as any)?.brands,
          id: (pagination as any)?.id,
        } as any,
        id as any
      )
        .then((response) => {
          const { data } = response;
          if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
            setPage(response?.totalPages);
            setPagination({ ...pagination, page: response?.totalPages });
          }
          setProductsList(data);
          setTotalPages(Math.ceil(response?.count / response?.perPage));
        })
        .catch((error) => ErrorToast(error));
    } else {
      ExportService.getAllVariationsByExport(
        {
          perPage: pagination.perPage,
          category: (pagination as any)?.category,
          page: pagination?.page,
          addedWay: (pagination as any)?.addedWay,
          eanCode: (pagination as any)?.eanCode,
          title: (pagination as any)?.title,
          brands: (pagination as any)?.brands,
        } as any,
        id as any
      )
        .then((response) => {
          const { data } = response;
          if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
            setPage(response?.totalPages);
            setPagination({ ...pagination, page: response?.totalPages });
          }
          setProductsList(data as any);
          setTotalPages(Math.ceil(response?.count / response?.perPage));
        })
        .catch((error) => ErrorToast(error));
    }
  }, [pagination, triggerUpdate, currentPage, triggerDelete]);
  useEffect(() => {
    if (currentPage) {
      setPagination((prev: any) => ({ ...prev, page: currentPage }));
      setPage(currentPage);
    }
  }, [currentPage]);
  useEffect(() => {
    BrandService.getAllBrands({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setBrandsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        setAttributesList(response?.data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (location.pathname?.includes("export")) {
      setAttOrExp("export");
    } else if (location.pathname?.includes("attributes")) {
      setAttOrExp("attributes");
    } else {
      setAttOrExp("variations");
    }
  }, [location.pathname]);

  return (
    // <PageTemplate title={`${t("attributes.list_of_products_by_attribute")}`}>
    <Col xs={12}>
      {/* {accessRights?.includes("product_can_create") && <ProductsForm />} */}
      {currentVariation?.id && (
        <VariationUpdateForm
          currentVariation={currentVariationTranslation}
          variationId={currentVariation?.id}
          setCurrentVariation={setCurrentVariationTranslation}
          showVariationModal={showVariationModal}
          handleClose={handleClose}
          // certainBlocks={certainBlocks}
          setTriggerUpdate={setTriggerUpdate}
        />
      )}
      <div className="d-flex justify-content-beetwen align-items-center">
        <button
          className="btn btn-info d-flex align-items-center mb-3"
          onClick={() => backOnTable()}
        >
          <i className="ri-arrow-left-line" />
          {t("attributes.back")}
        </button>
        <div className="d-flex w-100 align-items-center justify-content-end mb-3">
          <button
            className={`btn btn${!isListView ? "-soft" : ""}-info btn-sm`}
            onClick={() => setIsListView(!isListView)}
          >
            <i className="ri-list-check " />
          </button>
          <button
            className={`btn btn${isListView ? "-soft" : ""}-info btn-sm ms-2`}
            onClick={() => setIsListView(!isListView)}
          >
            <i className="ri-bank-card-line" />
          </button>
        </div>
      </div>
      {!isListView ? (
        <div className="row gy-4">
          {!!productsList?.length ? (
            productsList?.map((product) => (
              <div className="col-sm-6 col-xl-3" key={product?.id}>
                <ProductsCardView
                  nameOfTable={nameOfTable}
                  key={product?.id}
                  product={product}
                  deleteHandler={deleteHandler}
                  onEdit={editClickHandler}
                  onView={viewClickHandler}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmptyState />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="card">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mg-b-0">
                {attTitle ?? location?.state ?? expOrAttPagination?.headTitle}
              </h4>
            </div>
          </div>
          <ProductsFilters
            handleSelectBrandsExpAtt={handleSelectBrandsExpAtt}
            handleSelectAllCategoryExpAtt={handleSelectAllCategoryExpAtt}
            pagination={pagination}
            expOrAttPagination={expOrAttPagination}
            listAttribute={listAttribute}
            setTriggerUpdate={setTriggerUpdate}
            mainTable={mainTable}
            changeFilterHandler={changeFilterHandler}
            handleSelectStatus={handleSelectStatus}
            handleSelectMainCategory={handleSelectMainCategory}
            categories={categories}
            brandsList={brandsList}
            handleSelectBrand={handleSelectBrand}
            handleChangeCategory={handleChangeCategory}
            categoryFilterId={categoryFilterId as any}
            setPagination={setPagination}
          />
          <div className="card-body">
            <div className="table-responsive">
              {!!productsList?.length ? (
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.aenCode")}`}</th>
                      <th scope="col">{`${t("products.product")}`}</th>
                      {exportList && (
                        <th scope="col">{`${t("global.variation")}`}</th>
                      )}
                      <th scope="col">{`${t("products.category")}`}</th>
                      <th scope="col">{`${t("products.createdAt")}`}</th>
                      <th scope="col">{`${t("global.actions")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsList?.map((product) => (
                      <ProductsListView
                        listWithoutLinks={true}
                        headerName={attTitle}
                        attOrExp={attOrExp}
                        variationsTable={
                          window.location.pathname?.includes("variations")
                            ? true
                            : false
                        }
                        nameOfTable={
                          window.location.pathname?.includes("variations")
                            ? "variations"
                            : "productByAttribute"
                        }
                        key={product?.id}
                        deleteHandler={deleteHandler}
                        onEdit={editClickHandler}
                        onView={viewClickHandler}
                        product={
                          attOrExp === "variations"
                            ? product?.variation
                            : product
                        }
                        mainTable={mainTable}
                      />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
    </Col>
    // </PageTemplate>
  );
};
