import { useTranslation } from "react-i18next";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { themeSelect } from "../../../../shared/components/Select2";
import Select, { MultiValue, SingleValue } from "react-select";
import React, { SetStateAction, useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IPicture } from "../../../../model/shared/IPicture";
import { AttributeValueForm } from "../../../attributes/values/components/AttributeValueForm";
import { IAttributeValue } from "../../../attributes/values/dto/IAttributeValue";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { ProductsService } from "../../service";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { AttributesService } from "../../../attributes/service";
import { IAttributes } from "../../../attributes/names/dto/IAttributes";

interface IAttributesViewProps {
  currentProduct: IProductsList | IProducts;
}
export const AttributesView = ({ currentProduct }: IAttributesViewProps) => {
  const [categoryAttributes, setCategoryAttributes] = useState<IAttributes[]>(
    []
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
      category: currentProduct?.category?.id ?? currentProduct?.category,
    })
      .then((response) => {
        response.data.forEach((item) => {
          item.attributeValues
            ?.sort((a, b) => a.id - b.id)
            .push({
              title: `+ Add`,
              isAdd: true,
              id: (item?.attributeValues?.at(-1)?.id ?? 0) + 1,
            });
        });
        setCategoryAttributes(response.data);
      })
      .catch((error) => ErrorToast(error));
  }, [currentProduct]);
  return (
    <>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              {!!categoryAttributes.length ? (
                categoryAttributes?.map((attribute) => {
                  const selectedAttributeValue = currentProduct?.values?.find(
                    (value) => value.attribute === attribute?.id
                  );
                  const defaultValues = selectedAttributeValue?.value
                    ?.map((id) =>
                      attribute?.attributeValues?.find(
                        (value) => value?.id === id
                      )
                    )
                    .filter((item) => item);
                  const ids = defaultValues?.map((o) => o?.id);
                  const filtered = defaultValues?.filter(
                    (item, index) => !ids?.includes(item?.id, index + 1)
                  );
                  return (
                    <div className="col-md-3 my-3" key={attribute?.id}>
                      <label htmlFor="title">{attribute?.title ?? "-"}</label>
                      <Select
                        className="react-select-field"
                        isDisabled
                        placeholder={`${t("empty_state.empty")}...`}
                        // theme={themeSelect}
                        value={filtered?.map((value) => ({
                          label: value?.title ?? "-",
                          value: value?.id,
                        }))}
                        key={currentProduct?.id}
                      />
                    </div>
                  );
                })
              ) : (
                <>
                  <EmptyState />
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-info"
                      onClick={() => navigate(-1)}
                    >
                      {t("products.close")}
                    </button>
                  </div>
                </>
              )}
            </div>

            {!!categoryAttributes.length && (
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-info" onClick={() => navigate(-1)}>
                  {t("products.close")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
