import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ICompany } from "../../model/authentification/IRegister";
import { useEffect, useRef, useState } from "react";
import { CompaniesList } from "./components/CompaniesList";
import { ICompaniesDTO } from "./dto/ICompanies";
import { CompanyService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { CompaniesForm } from "./components/CompaniesForm";
import _debounce from "lodash/debounce";
import { multipartFormData } from "../../utils/helpers/api.services";
import { COMPANY_URL } from "../../utils/helpers/api.routes";
import { SingleValue } from "react-select";

const initialState = {
  title: "",
  email: "",
  contact: {
    phone: "",
    country: "",
    city: "",
    zip: "",
    address: "",
  },
};

export const Companies = () => {
  const fileRef = useRef<any>(null);
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [companiesList, setCompaniesList] = useState<
    ICompaniesDTO[] | undefined
  >();
  const [currentCompany, setCurrentCompany] = useState<ICompany>(initialState);
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPaginate({ ...paginate, page: updatePage });
    setPage(updatePage);
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPaginate((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  const changeCompanyHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact = false
  ) => {
    const { value, name } = event.target;
    if (isContact) {
      setCurrentCompany((prev: any) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [name]: value,
        },
      }));
    } else {
      setCurrentCompany((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const editHandler = (companyId: number) => {
    CompanyService.getCompanyById(companyId)
      .then((response) => {
        setCurrentCompany(response);
        setIsMenuOpened(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    if (currentCompany?.id) {
      multipartFormData(
        fileRef?.current?.files?.[0],
        `${COMPANY_URL}/${currentCompany?.id!}`,
        JSON.stringify(currentCompany),
        "put",
        "logo"
      )
        .then((response) => {
          SuccessToast(
            `${t("toasts.successfully_updated")} ${response?.data.title}`
          );
          setIsMenuOpened(false);
          setCurrentCompany(initialState);
        })
        .catch((error) => ErrorToast(error));
    } else {
      multipartFormData(
        fileRef?.current?.files?.[0],
        `${COMPANY_URL}`,
        JSON.stringify(currentCompany),
        "post",
        "logo"
      )
        .then((response) => {
          SuccessToast(
            `${t("toasts.successfully_created")} ${response?.data.title}`
          );
          setIsMenuOpened(false);
          setCurrentCompany(initialState);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  function handleSelectCountry(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setCurrentCompany((prev: any) => ({
      ...prev,
      contact: {
        ...prev.contact,
        country: selectedSingle?.value,
      },
    }));
  }

  useEffect(() => {
    CompanyService.getAllCompanies(paginate)
      .then((response) => {
        //omly edit action
        const { data } = response;
        setCompaniesList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [JSON.stringify(paginate), isMenuOpened, triggerUpdate]);

  return (
    <>
      <div className="col-xl-12 mb-3">
        {!isMenuOpened && (
          <div className="card ">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("companies.create_new_company")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        )}
        {isMenuOpened && (
          <Form onSubmit={submitHandler}>
            <CompaniesForm
              state={currentCompany}
              changeHandler={changeCompanyHandler}
              handleSelectCountry={handleSelectCountry}
              fileRef={fileRef}
              setIsMenuOpened={setIsMenuOpened}
            />
          </Form>
        )}
      </div>
      <div className="col-xl-12">
        <CompaniesList
          companiesList={companiesList}
          changeFilterHandler={changeFilterHandler}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
