import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { t } from "i18next";
import { Col } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";
import { CompanyService } from "../../companies/service";
import { ErrorToast } from "../../../shared/toasters/toasters";

interface IAccessFormProps {
  state: any;
  setState: React.Dispatch<SetStateAction<any | undefined>>;
  setAccessRightsList: React.Dispatch<SetStateAction<string[]>>;
  accessRightsList: string[];
  companiesList: ICompaniesDTO[];
}

export const AccessForm = ({
  state,
  setState,
  setAccessRightsList,
  accessRightsList,
  companiesList,
}: IAccessFormProps) => {
  const { accessRights } = useSelector((state: IAllStates) => state.enums);
  //   const [accessRightsList, setAccessRightsList] = useState([]);
  const selectRoleHandler = (event: any) => {
    let newItem: any[] = [];
    let newArray = [];
    if (accessRightsList.includes(event.target.value as never) === false) {
      newItem = [event.target.value];
      newArray = accessRightsList.concat(newItem as any);
      setAccessRightsList(newArray);
      setState((prev: any) => ({
        ...prev,
        rights: accessRightsList,
      }));
    } else {
      newItem = event.target.value;
      newArray = accessRightsList.filter((item) => item !== (newItem as any));
      setAccessRightsList(newArray);
      setState((prev: any) => ({
        ...prev,
        rights: accessRightsList,
      }));
    }
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setState((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  }

  const groupAccesRights = (): any => {
    const keys = Object.values(AccessRight);
    const temp: any = {};

    keys?.forEach((value: string) => {
      temp[value] = accessRights?.filter((right: string) => {
        if (right.includes("product") && right.includes("attribute")) {
          return value === "product";
        }
        if (right.includes("data_enrichment")) {
          return value === "data_enrichment";
        }
        if (right.includes("category_attribute")) {
          return value === "category_attribute";
        }
        if (right.includes("can_translate")) {
          return value === "can_translate";
        }
        if (right.includes("workflow_content")) {
          return value === "workflow_content";
        } else {
          return right.includes(value);
        }
      });
    });

    return temp;
  };

  return (
    <>
      <div className="row">
        <Col md={4} className="mb-3">
          <label htmlFor="client" className="required-field">{`${t(
            "access_rights.role_name"
          )}`}</label>
          <input
            onChange={changeHandler}
            id="client"
            className="form-control w-100"
            name="name"
            value={state?.name}
            placeholder={`${t("clients.enterName")}...`}
            type="text"
            maxLength={30}
          />
        </Col>
        <div className="col-md-4">
          <label htmlFor="title" className="required-field">{`${t(
            "clients.company"
          )}`}</label>
          <Select
            className="react-select-field"
            theme={themeSelect}
            options={(companiesList as unknown as ICompaniesDTO[])?.map(
              (company) => {
                return {
                  value: company.id,
                  label: company.title,
                };
              }
            )}
            key={state?.id}
            defaultValue={{
              label: state?.company?.title,
              value: state?.company?.id,
            }}
            onChange={(e) => {
              handleSelectSingle(e, "company");
            }}
          />
        </div>
      </div>
      <div className="row">
        {Object.keys(groupAccesRights() as any)?.map((right) => {
          return (
            <div key={right} className="col-md-3 my-1">
              {/* <h5>{right}</h5> */}
              <h4> {`${t(`access_rights.${right}`)}`}</h4>
              {((groupAccesRights() as any)[right as any] as any)?.map(
                (rights: string) => {
                  return (
                    <div className="form-check mb-2" key={rights}>
                      <input
                        className="form-check-input m-0"
                        key={rights}
                        //   id={role}
                        value={rights}
                        checked={accessRightsList?.includes(rights as never)}
                        type="checkbox"
                        onChange={selectRoleHandler}
                      />
                      <label
                        className="ms-2 form-check-label"
                        htmlFor="formCheck1"
                      >
                        {`${t(`access_rights.${rights}`)}`}
                      </label>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
        {/* {accessRights?.map((rights) => {
          return (
            <div className="col-md-3 my-1">
              <div className="form-check mb-2">
                <input
                  className="form-check-input m-0"
                  key={rights}
                  //   id={role}
                  value={rights}
                  checked={accessRightsList?.includes(rights as never)}
                  type="checkbox"
                  onChange={selectRoleHandler}
                />
                <label className="ms-2 form-check-label" htmlFor="formCheck1">
                  {`${t(`access_rights.${rights}`)}`}
                </label>
              </div>
            </div>
          );
        })} */}
      </div>
    </>
  );
};
//Dashboard
//Product

export enum AccessRight {
  //Dashboard
  DASHBOARD = "dashboard",

  // Category & attribute
  CATEGORY_ATTRIBUTE = "category_attribute",

  // Category
  CATEGORY = "category",

  // Attributes

  ATTRIBUTE = "attribute",

  //Product
  PRODUCT = "product",

  //Translate section
  TRANSLATE = "translate",

  //Glossary section
  GLOSSARY_CAN = "glossary_can",

  //Content section
  CONTENT_PAGE_CAN = "content_page_can",

  //Import files section
  IMPORT_FILE_SECTION = "import_file_section",

  //Export files section
  EXPORT_CAN = "export_can",

  //Company details section
  COMPANY_DETAILS = "company_details",

  //User section
  USER = "user",

  //Client section
  CLIENT = "client",

  //Chat gpt prompts
  CHAT_GPT = "chat_gpt",

  //Workflow prompts
  WORKFLOW = "workflow",

  //Content workflow
  WORKFLOW_CONTENT = "workflow_content",

  //Brand prompts
  BRAND = "brand",

  //Block prompts
  BLOCK = "block",

  //Overview prompts
  OVERVIEW = "overview",

  HELP = "help",

  //Google search prompt
  GOOGLE_SEARCH_PROMPT = "google_search_prompt",
  //Data enrichment prompts
  DATA_ENRICHMENT = "data_enrichment",

  // Api
  API = "api",

  WORKFLOW_TRANSLATE = "can_translate",
}
