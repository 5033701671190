import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { ContentStepper } from "./ContentStepper";
import { ContentWorkflowCreate } from "./ContentWorkflowCreate";
import { ICategoryChildren } from "../../category/values/dto/ICategory";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { ContentWorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import contentWorkflowSlice from "../../../store/contentWorkflow/contentWorkflowSlice";

export const ContentStep1 = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workflowData = useSelector(
    (state: IAllStates) => state.contentWorkflow
  );
  const [firstStap, setFirstStap] = useState<any | undefined>();
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const { workflowStatus } = useSelector((state: IAllStates) => state.enums);
  const categories = useSelector((state: IAllStates) => state.category);
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const isCopywriterUnchanged = firstStap?.copywriters?.some(
      (person: any) => !!person?.id
    );
    ContentWorkflowService.updateWorkflowById(Number(id), {
      deadlineDate: firstStap?.deadlineData,
      ...firstStap,
      client: firstStap?.client?.id ?? firstStap?.client,
      copywriters: !isCopywriterUnchanged
        ? firstStap?.copywriters
        : firstStap?.copywriters?.map((person: any) => Number(person?.id)),
      deadline: firstStap?.deadlineData
        ? (firstStap?.deadlineData as any)?.toISOString()?.split("T")[0]
        : null,
    } as any)
      .then((response) => {
        if (response?.id) {
          dispatch(contentWorkflowSlice.actions.setContentWorkflow(response));
          SuccessToast(
            "Success"
            // `${t("toasts.successfully_updated_crawler")} ${response?.website
            // }`
          );
          navigate(`/app/content-workflow/base/${response?.id}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const backNavigate = () => {
    navigate(`/app/content-workflow`);
  };
  useEffect(() => {
    if (Boolean(workflowData?.id)) {
      setFirstStap(workflowData);
    }
  }, [workflowData?.id]);
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper step={"SETTING_UP"} id={id}></ContentStepper>
          {firstStap?.id && (
            <ContentWorkflowCreate
              firstStap={firstStap}
              setFirstStap={setFirstStap}
              workflowStatus={workflowStatus}
              selectedMainCategory={selectedMainCategory}
              setSelectedMainCategory={setSelectedMainCategory}
              categories={categories}
            />
          )}
        </Card.Body>
        <Card.Footer className="border-top-0">
          <div className="col-12  d-flex justify-content-end">
            <button
              className="btn btn-info"
              onClick={() => {
                backNavigate();
                setFirstStap({} as any);
              }}
            >
              {`${t("block.back")}`}
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={(e) => submitHandler(e)}
            >
              {`${t("global.save_next")}`}
            </button>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};
