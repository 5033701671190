import { ActualFileObject } from "filepond";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, Col, Spinner, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import Select, { SingleValue } from "react-select";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { BaseDataUpdate } from "../../products/baseData/components/BaseDataUpdate";
import { ProductsListView } from "../../products/components/ProductsListView";
import { IProductsList } from "../../products/dto/IProducts";
import { IBaseData, IWorkflow, IWorkflowOtherData } from "../dto/IWorkflow";
import { WorkflowService } from "../service";
import { WorkflowStepper } from "./WorkflowStepper";
import { useDispatch, useSelector } from "react-redux";
import workflowProductIdSlice from "../../../store/workflow/workflowLangSlice";
import { IAllStates } from "../../../store/rootReducer";
import step3PaginationSlice from "../../../store/workflow/step3PaginationSlice";
import { GoogleSearchPromptService } from "../../googleSearchPrompt/service";

export const WorkflowStep3 = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const [showTranslateDialog, setShowTranslateDialog] = useState(false);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const mainTable = true;
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [visibleWorkers, setVisibleWorkers] = useState(false);
  const dispatch = useDispatch();
  const stepPagination = useSelector(
    (state: IAllStates) => state.step3Pagination as any
  );
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [baseData, setBaseData] = useState<IBaseData | undefined>(undefined);
  const [page, setPage] = useState(currentPage);
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const [updateList, setUpdateList] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [translatorList, setTranslatorList] = useState<any[]>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [originalList, setOriginalList] = useState<any[]>([]);
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const [translatorData, setTranslatorData] = useState<any>({ products: [] });
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
  });
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();

  const handlePages = (updatePage: number) => {
    navigate(`?page=${updatePage}`);
    dispatch(
      step3PaginationSlice.actions.setStep3Pagination({
        ...(stepPagination as any),
        page: updatePage,
      })
    );
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  // const maxId =
  //   productsList &&
  //   (productsList?.map((product) => product.id) as number[]).sort(
  //     (a, b) => b - a
  //   )[0] + 1;
  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/base-data/${id}`);
  };
  const toForLinkFunc = (productId: number) => {
    return `/app/workflow/step3/${productId}/${id}`;
  };
  const editClickHandler = (e: any, productId: number) => {
    if (e.type === "click") {
      navigate(`/app/workflow/step3/${productId}/${id}`);
    } else if (e.type === "contextmenu") {
      window.open(`/app/workflow/step3/${productId}/${id}`, "_blank");
    }
  };
  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(productsList?.map((product: any) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const selectTranslatorHandler = (event: any) => {
    setTranslatorData((prev: any) => ({ ...prev, user: +event?.value }));
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };
  const handlePerPage = (e: any) => {
    dispatch(
      step3PaginationSlice.actions.setStep3Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
  };

  const changeFilterHandler = (name: string, value: string) => {
    if (value === "") {
      dispatch(
        step3PaginationSlice.actions.setStep3Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
          copywriter: null,
          assigned: "",
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        copywriter: null,
        assigned: "",
      }));
    } else if (name === "assigned") {
      dispatch(
        step3PaginationSlice.actions.setStep3Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
          copywriter: null,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        copywriter: null,
      }));
    } else {
      dispatch(
        step3PaginationSlice.actions.setStep3Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
        })
      );
      setPagination((prev: any) => ({ ...prev, [name]: value }));
    }
  };
  const assignTranslatorHandler = async () => {
    id &&
      (await WorkflowService.assignTranslatorBaseData(Number(id), {
        copyWriter: translatorData?.user,
        products: checkedValues,
        // lang: activeTab,
      })
        .then((response) => {
          if (response === "") {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await WorkflowService.changeFinishedStatusBase(Number(id), {
        isDone: finishedOrUnfinished,
        products: checkedValues,
      })
        .then((response) => {
          if (response) {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.enrichProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitFillWorkflow = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.fillProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      WorkflowService.deleteWorkflowBaseData(product?.id, Number(id))
        .then((response) => {
          setTriggerDelete((prev: boolean) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };
  const handleFilterUser = (e: any) => {
    dispatch(
      step3PaginationSlice.actions.setStep3Pagination({
        ...(stepPagination as any),
        copywriter: e?.value,
        assigned: "",
        page: 1,
      })
    );
    setPagination((prev: any) => ({
      ...prev,
      copywriter: e?.value,
      assigned: "",
      page: 1,
    }));
  };
  useEffect(() => {
    if (triggerUpdate) {
      dispatch(
        step3PaginationSlice.actions.setStep3Pagination({
          page: 1,
          perPage: 12,
        })
      );
      setPagination({
        page: 1,
        perPage: 12,
      } as any);
    }
    WorkflowService.getWorkflowProducts(pagination, +id!)
      .then((response) => {
        const { data } = response;
        const { baseData, workflow, workers, assignedCopywriters } =
          (response as unknown as IWorkflowOtherData)?.otherData ?? {};
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setBaseData(baseData);
        setCurrentWorkflow(workflow as IWorkflow);
        // dispatch(workflowProductIdSlice.actions.setWorkflowProductId(0));
        setProductsList(data);
        setOriginalList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(response?.page);
        setUserList(assignedCopywriters as any);
        setTranslatorList(!!workers?.length ? workers : []);
        setVisibleWorkers((response as any)?.view === 1 ? true : false);
      })
      .catch((error) => ErrorToast(error));
  }, [
    pagination,
    triggerUpdate,
    currentPage,
    triggerDelete,
    selectedProductId,
    updateList,
  ]);
  return (
    <Card>
      <Card.Body>
        <WorkflowStepper
          userList={userList}
          handleFilterUser={handleFilterUser}
          stepPagination={stepPagination}
          step={"BASE_DATA"}
          setSelectedProductId={setSelectedProductId}
          status={pagination?.status}
          showNextButton
          precent={baseData?.percent}
          showMarkAsComplete={
            currentWorkflow?.status === "BASE_DATA" &&
            (isAdmin || isCompanyAdmin || isManager)
          }
          changeFilterHandler={changeFilterHandler}
          id={id}
          handlePerPage={handlePerPage}
          assigned={pagination?.assigned ?? ""}
          pagination={pagination}
        />
        <div className="card bg-light overflow-hidden shadow-none">
          <div className="card-body">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="mb-0">
                  {`${t("workflow.current")}`}:{" "}
                  <b className="text-success">{baseData?.percent ?? 0}%</b>
                </h6>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {baseData?.done ?? 0}/{baseData?.total ?? 0}{" "}
                  {`${t("workflow.finished")}`}
                </h6>
              </div>
            </div>
          </div>
          <div className="progress">
            {/*//@ts-ignore*/}
            <div
              className="progress-bar bg-success"
              style={{ width: `${baseData?.percent ?? 0}%` }}
              aria-valuenow={baseData?.percent as number}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <Col xs={12}>
          <div className="table-responsive">
            <div className="d-flex justify-content-between align-items-center">
              {visibleWorkers && (
                <div className="d-flex justify-content-start align-items-center mb-1">
                  {lightOrDarkMode === "dark" ? (
                    <>
                      <h6 className="m-0 p-0 me-1">
                        {t("workflow.copywriter")}:
                      </h6>
                      <Select
                        className="react-select-field me-1"
                        styles={{
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isSelected
                              ? "#007aff"
                              : "#262a2f",
                            ":hover": {
                              backgroundColor: state.isSelected
                                ? "#007aff"
                                : "black",
                            },
                          }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 250,
                            zIndex: showTranslateDialog ? 0 : 9999,
                            ":hover": {
                              backgroundColor: "black",
                            },
                          }),

                          menu: (base) => ({
                            ...base,
                            backgroundColor: "#262a2f",
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: showTranslateDialog ? 0 : 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        onChange={selectTranslatorHandler}
                        isClearable={true}
                        options={translatorList?.map((translator) => ({
                          label: `${translator?.firstName} ${
                            translator?.lastName
                          } (${translator.assignment}  ${t(
                            "products.products"
                          ).toLowerCase()})`,
                          value: translator.id,
                        }))}
                      />
                    </>
                  ) : (
                    <>
                      <h6 className="m-0 p-0 me-1">
                        {t("workflow.copywriter")}:
                      </h6>{" "}
                      <Select
                        className="react-select-field me-1"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 250,
                            zIndex: showTranslateDialog ? 0 : 9999,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: showTranslateDialog ? 0 : 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        onChange={selectTranslatorHandler}
                        isClearable={true}
                        options={translatorList?.map((translator) => ({
                          label: `${translator?.firstName} ${
                            translator?.lastName
                          } (${translator.assignment}  ${t(
                            "products.products"
                          ).toLowerCase()})`,
                          value: translator.id,
                        }))}
                      />
                    </>
                  )}
                  {visibleWorkers && (
                    <button
                      onClick={assignTranslatorHandler}
                      className="btn  btn-secondary d-flex align-items-center me-2"
                      disabled={!translatorData?.user || !checkedValues?.length}
                    >
                      <i className="ri-share-box-fill me-1" />
                      {t("global.assign")}
                    </button>
                  )}
                  <button
                    onClick={() => changeFinishHandler(true)}
                    className="btn  btn-success d-flex align-items-center me-2"
                    disabled={
                      // translatorData?.user ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.finished")}
                  </button>
                  <button
                    onClick={() => changeFinishHandler(false)}
                    className="btn  btn-danger d-flex align-items-center me-2"
                    disabled={
                      // translatorData?.user ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.unfinished")}
                  </button>
                </div>
              )}

              <div className="d-flex mb-1">
                {accessRights?.includes(
                  "data_enrichment_workflow_all_products"
                ) && (
                  <>
                    <Select
                      className="react-select-field w-250"
                      placeholder={`${t("side_bar.google_search_prompt")}`}
                      onChange={selectGoogleSearchHandler}
                      // isClearable={true}
                      options={googleSearchList.map((exportItem) => ({
                        label: exportItem.title,
                        value: exportItem.id,
                      }))}
                    />
                    <button
                      style={{ minWidth: "141px" }}
                      className="btn btn-success ms-1 me-2"
                      disabled={googleSearchPrompt === 0}
                      onClick={(e: any) => submitGoogleSearch(e)}
                    >
                      <span> {t("data_enrichment.enrichment")}</span>
                    </button>
                    <button
                      className="btn btn-info ms-1 me-2"
                      onClick={(e: any) => submitFillWorkflow(e)}
                    >
                      <span> {t("global.fill")}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
            {!!productsList?.length ? (
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    {checkForMainRoles() && (
                      <th scope="col">
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          checked={
                            checkedValues.length === productsList?.length
                          }
                          type="checkbox"
                          onChange={checkAll}
                        />
                      </th>
                    )}

                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.aenCode")}`}</th>
                    <th scope="col">{t("global.finished")}</th>
                    <th scope="col">{`${t("global.assign")}`}</th>
                    <th scope="col">{`${t("products.product")}`}</th>
                    <th scope="col">{`${t("products.brand")}`}</th>
                    <th scope="col">{`${t("products.category")}`}</th>
                    <th scope="col">{`${t("products.createdAt")}`}</th>
                    <th scope="col">{`${t("global.actions")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {productsList?.map((product: any) => (
                    <ProductsListView
                      toForLinkFunc={toForLinkFunc}
                      workflow={true}
                      nameOfTable="baseMainWorkflow"
                      showViewButton={false}
                      key={product?.id}
                      showAssignColumn={true}
                      handleCheck={checkboxHandler}
                      onEdit={editClickHandler}
                      onView={viewClickHandler}
                      product={product}
                      mainTable={mainTable}
                      deleteHandler={deleteHandler}
                      checkedValues={checkedValues}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState />
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
};
