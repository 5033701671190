import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { Card, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { CategoryService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ICategoryCreate } from "./dto/ICategory";
import { CategoryList } from "./components/CategoryList";
import { CategoryForm } from "./components/CategoryForm";
import _debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import categorySlice from "../../../store/category/categoryRedux";
import { CategoryServicee } from "../../../store/category/categoryService";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { IAttributes } from "../../attributes/names/dto/IAttributes";
import { AttributesService } from "../../attributes/service";
import { useNavigate } from "react-router";

const initialState = {
  title: "",
  id: undefined,
};

export const Category = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState<ICategoryCreate>();
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const [filterParams, setFilterParams] = useState({
    title: "",
    attributes: null,
  });
  const dispatch = useDispatch();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [attributeId, setAttributeId] = useState(null);
  const [titleFilter, setTitleFilter] = useState("");
  const categoryList = useSelector((state: IAllStates) => state.category);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentCategory((prev: any) => ({ ...prev, [name]: value }));
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setTitleFilter(value);
      setFilterParams((prev) => ({
        ...prev,
        [name]: value,
        attributes: attributeId,
      }));
    },
    200
  );

  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    const { name, value } = event.target;
    setCurrentCategory((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
      parent: 0,
    }));
  };
  const handleSelectAttribute = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setAttributeId(selectedSingle?.value as any);
      setFilterParams({
        title: titleFilter,
        attributes: selectedSingle?.value as number,
      } as any);
    } else {
      setAttributeId(null);
      setFilterParams({
        title: titleFilter,
        attributes: null,
      } as any);
    }
  };
  const handleCreate = () => {
    CategoryService.addCategory(currentCategory!)
      .then((response) => {
        setIsMenuOpened(false);
        setTriggerUpdate((prev) => !prev);
        dispatch(categorySlice.actions.setCategory(response));
        //@ts-ignore
        setCurrentCategory(initialState);
        SuccessToast(
          `${t("toasts.successfully_created")} ${
            response[response.length - 1].title
          }`
        );
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    CategoryServicee.getAllCategories(filterParams as any)
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, [filterParams]);

  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        setAttributesList(response?.data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    // <PageTemplate title={`${t("side_bar.category")}`}>
    <Col xs={12}>
      <CategoryForm
        currentCategory={currentCategory as ICategoryCreate}
        setCurrentCategory={setCurrentCategory as any}
        changeHandler={changeHandler}
        changeTranslationHandler={changeTranslationHandler}
        handleCreate={handleCreate}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
      />
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-end">
            <div className="col-md-2 my-2 me-2">
              <input
                type="text"
                placeholder={`${t("companies.searchTitle")}`}
                onChange={changeFilterHandler}
                className="form-control"
                name="title"
              />
            </div>
            <div className="col-md-2 my-2">
              <Select
                className="react-select-field"
                theme={themeSelect}
                isClearable={true}
                placeholder={t("attributes.select_attribute")}
                options={attributesList?.map((attribute) => ({
                  label: attribute?.title,
                  value: attribute?.id,
                }))}
                onChange={(e) => {
                  handleSelectAttribute(
                    e as SingleValue<{ label: string; value: number }>
                  );
                }}
              />
            </div>
          </div>
          <CategoryList
            categoryList={categoryList}
            setTriggerUpdate={setTriggerUpdate}
            initialState={initialState as ICategoryCreate}
            currentCategory={currentCategory}
            changeHandler={changeHandler}
            changeTranslationHandler={changeTranslationHandler}
            setCurrentCategory={setCurrentCategory}
          />
        </Card.Body>
      </Card>
    </Col>
    // </PageTemplate>
  );
};
