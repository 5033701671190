import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import approveAll from "../icons/approveAll.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { appVersionSlice } from "../../utils/general/appVersionSlice";

export const Confirm505Err = ({}: any) => {
  const { openRefreshModal } = useSelector(
    (state: IAllStates) => state.appVersion
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reloadVersion = () => {
    window.location.reload();
    dispatch(appVersionSlice.actions.setReloadModal(false));
  };
  return (
    <Modal size="sm" centered show={openRefreshModal}>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={approveAll} loop={true} style={{ width: 100 }} />
        <h5 style={{ fontSize: "14px" }}>
          {t("modals.version_not_supported")}
        </h5>
        <p className="text-center text-muted">
          {t("modals.please_update_version")}
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("global.update")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
