import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { Link } from "react-router-dom";
import { IWorkflow } from "../dto/IWorkflow";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import {
  correctFormatTable,
  formatDate,
  formatDateWorkflow,
  formatDateWorkflowDead,
  getCorrectStatus,
} from "../../../shared/functions/Functions";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { WorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import DatePicker from "react-datepicker";
import { ClientService } from "../../clients/service";
import { IExport } from "../../exports/dto/IExports";
import LockedReasonModal from "./LockedReasonModal";
import { ConfirmUnlock } from "../../../shared/components/ConfirmUnlock";
interface IWorkflowListProps {
  completeTab?: boolean;
  setCompleteTab?: any;
  isAdmin?: boolean;
  isCompanyAdmin?: boolean;
  isManager?: boolean;
  name?: any;
  accessRights: string[];
  list?: IWorkflow[] | undefined;
  // changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  proceedHandler: (
    workflowId: number,
    status: string,
    tabs: any,
    e: any
  ) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page?: number;
  tableName?: string;
  havePagination?: boolean;
  totalPages?: number;
  handlePaginationClient?: any;
  isDashboard?: boolean;
  handlePages?: (updateNumber: number) => void;
  setPagination?: React.Dispatch<SetStateAction<any>>;
  handlePaginationStatus?: any;
  haveFilters?: boolean;
  haveTabs?: boolean;
  handlePaginationToDate?: any;
  completedList?: IWorkflow[] | undefined;
  handlePaginationFromDate?: any;
  pagination?: {
    perPage: number;
    page: number;
    workflowStatus?: any;
    client?: number | null;
    fromDeadline?: Date | null;
    toDeadline?: Date | null;
  };
  pageCompleted?: number;
  completeName?: any;
  setPaginationCompleted?: React.Dispatch<SetStateAction<any>>;
  handlePaginationName?: any;
  totalPagesCompleted?: number;
  handlePagesCompleted?: (updateNumber: number) => void;
  paginationCompleted?: {
    perPage: number;
    page: number;
    workflowStatus?: any;
    client?: number | null;
    fromDeadline?: Date | null;
    toDeadline?: Date | null;
  };
}

export const WorkflowList = ({
  completeTab,
  setCompleteTab,
  isAdmin,
  isCompanyAdmin,
  isManager,
  accessRights,
  completeName,
  list,
  // changeFilterHandler,
  proceedHandler,
  havePagination,
  isDashboard,
  page,
  totalPages,
  haveTabs,
  handlePages,
  paginationCompleted,
  setTriggerUpdate,
  handlePaginationStatus,
  setPagination,
  pagination,
  completedList,
  handlePaginationClient,
  handlePaginationFromDate,
  tableName,
  handlePaginationToDate,
  haveFilters,
  totalPagesCompleted,
  handlePagesCompleted,
  pageCompleted,
  setPaginationCompleted,
  handlePaginationName,
  name,
}: IWorkflowListProps) => {
  const { t } = useTranslation();
  const [selectedWorkflow, setselectedWorkflow] = useState<IWorkflow>();
  const [show, setShow] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);
  const [force, setForce] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowUnlock = () => setShowUnlock(true);
  const { workflowStatus } = useSelector((state: IAllStates) => state.enums);
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [currentWorkflowId, setCurrentWorkflowId] = useState<number>(0);
  const [modalShow, setModalShow] = React.useState(false);
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const deleteHandler = () => {
    selectedWorkflow?.id &&
      WorkflowService.deleteWorkflowById(selectedWorkflow?.id)
        .then((response) => {
          if (response === "") {
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const unlockHandler = () => {
    selectedWorkflow?.id &&
      WorkflowService.unlockWorkflow(selectedWorkflow?.id, {
        force: force,
      })
        .then((response) => {
          setTriggerUpdate((prev) => !prev);
          setForce(false);
          SuccessToast(response?.message);
        })
        .catch((error) => ErrorToast(error));
  };
  const statusColor = (status: string) => {
    switch (status) {
      case "SETTING_UP":
        return ["#ffc4e8", "#870B56", "1px solid #870B56"];
      case "IMPORT":
        return ["#F5F19F", "#9E9E34", "1px solid #9E9E34"];
      case "BASE_DATA":
        return ["#F5CA9F", "#994409", "1px solid #994409"];
      case "ATTRIBUTES":
        return ["#6AEEFF", "#266C6C", "1px solid #266C6C"];
      case "COPY_WRITING":
        return ["#BFF5AD", "#1B7405", "1px solid #1B7405"];
      case "TRANSLATION":
        return ["#ADE6F5", "#0C516F", "1px solid #0C516F"];
      case "OVERVIEW":
        return ["#ebc8fa", "#651387", "1px solid #651387"];
      case "EXPORT":
        return ["#EE9898", "#7C1616", "1px solid #7C1616"];
      default:
        return ["#c2eff0", "#0B6D6F", "1px solid #0B6D6F"];
    }
  };

  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const EditorWithContent = useCallback(() => {
    return (
      <MainWorkflowFilters
        handlePaginationName={handlePaginationName}
        workflowStatus={workflowStatus}
        handlePaginationStatus={handlePaginationStatus}
        clientList={clientList}
        handlePaginationClient={handlePaginationClient}
        t={t}
        pagination={pagination}
        completeTab={completeTab}
        paginationCompleted={paginationCompleted}
        handlePaginationFromDate={handlePaginationFromDate}
        handlePaginationToDate={handlePaginationToDate}
        completeName={completeName}
        name={name}
      />
    );
  }, [
    completeTab,
    clientList,
    pagination,
    workflowStatus,
    paginationCompleted,
  ]);
  function timeUntilDeadline(deadline: any) {
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);

    const timeDifference = (deadlineDate as any) - (currentDate as any);
    if (timeDifference <= 0) {
      return "danger";
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `success`;
  }
  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-start">
            <h4 className="card-title mg-b-0">
              {tableName ? tableName + " workflows" : t("workflow.workflows")}
            </h4>
          </div>
        </div>
        <div className="card-body">
          <>
            {haveFilters && <EditorWithContent />}
            {list == undefined ? (
              <LoadingAnimation />
            ) : (
              <div className="card">
                <div className="card-body">
                  {/* Nav tabs */}
                  {!haveTabs && (
                    <p className="mb-0">
                      <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">{`${t("workflow.name")}`}</th>
                              <th scope="col">{`${t("global.manager")}`}</th>
                              <th scope="col">{`${t("global.created_at")}`}</th>
                              <th scope="col">{`${t("workflow.deadline")}`}</th>
                              <th scope="col">{`${t("users.status")}`}</th>
                              <th scope="col">{`${t(
                                "global.currentStep"
                              )}`}</th>
                              {true && (
                                <th scope="col">{`${t("global.actions")}`}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {list?.map((workflow: any) => {
                              return (
                                <tr>
                                  <td>
                                    <span
                                      className="cursor-pointer"
                                      onClick={(e: any) => {
                                        proceedHandler(
                                          workflow?.id,
                                          workflow?.status,
                                          workflow?.tabs,
                                          e
                                        );
                                      }}
                                      onContextMenu={(e) =>
                                        proceedHandler(
                                          workflow?.id,
                                          workflow?.status,
                                          workflow?.tabs,
                                          e
                                        )
                                      }
                                    >
                                      #{workflow?.id}
                                    </span>
                                  </td>
                                  <td className="text-start">
                                    {workflow?.name}
                                  </td>
                                  <td>
                                    {tableName === "Translator" ||
                                    tableName === "Copywriter"
                                      ? workflow?.workflowCopywriter
                                          ?.map((writer: any) =>
                                            writer.user
                                              ? writer.user.firstName +
                                                `${" "}` +
                                                writer.user.lastName
                                              : "-"
                                          )
                                          .join(", ")
                                      : workflow?.copywriters
                                          ?.map((writer: any) =>
                                            writer.user
                                              ? writer.user.firstName +
                                                `${" "}` +
                                                writer.user.lastName
                                              : "-"
                                          )
                                          .join(", ")}
                                  </td>
                                  <td>{formatDate(workflow?.createdAt)}</td>
                                  <td>
                                    <span className="text-danger">
                                      {workflow?.deadlineData
                                        ? formatDateWorkflowDead(
                                            workflow?.deadlineData
                                          )
                                        : null}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="badge badge-outline-warning badge-soft-warning"
                                      style={{
                                        backgroundColor: statusColor(
                                          workflow?.status
                                        )[0],
                                        color: statusColor(workflow?.status)[1],
                                        border: statusColor(
                                          workflow?.status
                                        )[2],
                                      }}
                                    >
                                      {getCorrectStatus(workflow?.status, t)}
                                    </span>
                                  </td>
                                  <td>
                                    {workflow?.currentStepProgress ?? "-"}
                                  </td>
                                  {true && (
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        {true && (
                                          <button
                                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                            onClick={(e) =>
                                              proceedHandler(
                                                workflow?.id,
                                                workflow?.status,
                                                workflow?.tabs,
                                                e
                                              )
                                            }
                                            onContextMenu={(e) =>
                                              proceedHandler(
                                                workflow?.id,
                                                workflow?.status,
                                                workflow?.tabs,
                                                e
                                              )
                                            }
                                          >
                                            {t("workflow.proceed")}
                                          </button>
                                        )}
                                        {accessRights?.includes(
                                          "workflow_can_delete"
                                        ) &&
                                          !isDashboard && (
                                            <button
                                              className="btn btn-sm btn-danger d-flex align-items-center"
                                              onClick={() => {
                                                handleShow();
                                                setselectedWorkflow(workflow);
                                                console.log(
                                                  "delete in progress"
                                                );
                                              }}
                                            >
                                              <i className="ri-delete-bin-fill me-1" />
                                              {`${t("global.delete")}`}
                                            </button>
                                          )}
                                      </div>
                                    </td>
                                  )}
                                  {/* 8 actions */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </p>
                  )}
                  {haveTabs && (
                    <>
                      <ul className="nav nav-tabs mb-3" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-selected="true"
                            tabIndex={-1}
                            onClick={() => setCompleteTab(false)}
                          >
                            {t("global.active")}
                          </a>
                        </li>
                        {(isAdmin || isCompanyAdmin || isManager) && (
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#product1"
                              role="tab"
                              aria-selected="false"
                              onClick={() => setCompleteTab(true)}
                            >
                              {t("global.completed")}
                            </a>
                          </li>
                        )}
                      </ul>
                      {/* Tab panes */}
                      <div className="tab-content  text-muted">
                        <div
                          className="tab-pane active"
                          id="home"
                          role="tabpanel"
                        >
                          {/* {list?.length === 0 ? (
                            <EmptyState />
                          ) : ( */}
                          {true && (
                            <>
                              <p className="mb-0">
                                <div className="table-responsive">
                                  <Table className="align-middle table-nowrap mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th scope="col">
                                          {`${t("global.id")}`}
                                        </th>
                                        <th scope="col">{`${t(
                                          "workflow.name"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "global.manager"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "global.created_at"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "workflow.deadline"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "users.status"
                                        )}`}</th>
                                        {/* {`${t(
                                          "users.status"
                                        )}`} */}
                                        <th scope="col">{`${t(
                                          "enrichment_status.enrichment_status"
                                        )}`}</th>
                                        {/* <th scope="col">{`${t(
                                          "workflow.current_user_step"
                                        )}`}</th> */}
                                        <th scope="col">{`${t(
                                          "global.currentStep"
                                        )}`}</th>
                                        {(accessRights?.includes(
                                          "workflow_can_update"
                                        ) ||
                                          accessRights?.includes(
                                            "workflow_can_delete"
                                          )) && (
                                          <th scope="col">{`${t(
                                            "global.actions"
                                          )}`}</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {list?.map((workflow: any) => {
                                        return (
                                          <tr
                                            style={{
                                              backgroundColor: `${
                                                workflow?.isLocked
                                                  ? `${
                                                      lightOrDarkMode === "dark"
                                                        ? "black"
                                                        : "#eff2f7"
                                                    }`
                                                  : `${
                                                      lightOrDarkMode === "dark"
                                                        ? "#212529"
                                                        : "#ffffff"
                                                    }`
                                              }`,
                                            }}
                                          >
                                            <td>
                                              <span
                                                className="cursor-pointer"
                                                onContextMenu={(e) =>
                                                  proceedHandler(
                                                    workflow?.id,
                                                    workflow?.status,
                                                    workflow?.tabs,
                                                    e
                                                  )
                                                }
                                                onClick={(e) => {
                                                  // (accessRights?.includes(
                                                  //   "workflow_can_update"
                                                  // ) || isDashboard === true)
                                                  true &&
                                                    proceedHandler(
                                                      workflow?.id,
                                                      workflow?.status,
                                                      workflow?.tabs,
                                                      e
                                                    );
                                                }}
                                              >
                                                #{workflow?.id}
                                              </span>
                                            </td>
                                            {/* 1 id */}
                                            <td className="text-start">
                                              {workflow?.name}
                                              {workflow?.isLocked && (
                                                <span className="text-danger">
                                                  {" "}
                                                  {workflow?.lockedReason}
                                                </span>
                                              )}
                                            </td>
                                            {/* 2 name */}

                                            <td>
                                              {workflow?.workflowCopywriter
                                                ?.map((writer: any) =>
                                                  writer?.user?.firstName +
                                                  writer.user.lastName
                                                    ? writer.user.lastName
                                                    : ""
                                                )
                                                .join(", ")}
                                            </td>
                                            {/* 3 copywriter */}
                                            <td>
                                              {formatDate(workflow?.createdAt)}
                                            </td>
                                            {/* 4 createdAt */}
                                            <td>
                                              <span
                                                className={`text-${timeUntilDeadline(
                                                  workflow?.deadlineData
                                                )}`}
                                              >
                                                {workflow?.deadlineData
                                                  ? correctFormatTable(
                                                      workflow?.deadlineData
                                                    )
                                                  : null}
                                              </span>
                                            </td>
                                            {/* 5 deadlineData */}
                                            <td>
                                              <span
                                                className="badge badge-outline-warning badge-soft-warning"
                                                style={{
                                                  backgroundColor: statusColor(
                                                    workflow?.status
                                                  )[0],
                                                  color: statusColor(
                                                    workflow?.status
                                                  )[1],
                                                  border: statusColor(
                                                    workflow?.status
                                                  )[2],
                                                }}
                                              >
                                                {getCorrectStatus(
                                                  workflow?.status,
                                                  t
                                                )}
                                              </span>
                                            </td>
                                            <td>
                                              {t(
                                                `enrichment_status.${workflow?.enrichmentStatus}`
                                              )}
                                              {/* {workflow?.enrichmentStatus} */}
                                            </td>
                                            {/* <td>backend</td> */}
                                            {/* 6 status */}
                                            <td>
                                              {workflow?.currentStepProgress}
                                            </td>
                                            {/* 7 current step */}
                                            {(accessRights?.includes(
                                              "workflow_can_update"
                                            ) ||
                                              accessRights?.includes(
                                                "workflow_can_delete"
                                                //fali i za lock access rights kao uslov
                                              )) && (
                                              <td>
                                                {workflow?.isLocked ? (
                                                  <span className="d-flex justify-content-center">
                                                    {(isManager ||
                                                      isAdmin ||
                                                      isCompanyAdmin) &&
                                                      !isDashboard && (
                                                        <>
                                                          <Button
                                                            className="btn btn-sm btn-success d-flex align-items-center me-2"
                                                            onClick={() => {
                                                              handleShowUnlock();
                                                              setselectedWorkflow(
                                                                workflow
                                                              );
                                                            }}
                                                          >
                                                            <i className="ri-lock-unlock-line me-1" />
                                                            {`${t(
                                                              "global.unlock"
                                                            )}`}
                                                          </Button>
                                                        </>
                                                      )}
                                                  </span>
                                                ) : (
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    {(isManager ||
                                                      isAdmin ||
                                                      isCompanyAdmin) &&
                                                      !isDashboard && (
                                                        <>
                                                          <Button
                                                            className="btn btn-sm btn-primary d-flex align-items-center me-2"
                                                            onClick={() => {
                                                              setModalShow(
                                                                true
                                                              );
                                                              setCurrentWorkflowId(
                                                                workflow?.id
                                                              );
                                                            }}
                                                          >
                                                            <i className="ri-lock-line me-1" />
                                                            {`${t(
                                                              "global.lock"
                                                            )}`}
                                                          </Button>
                                                        </>
                                                        // <button
                                                        //   className="btn btn-sm btn-primary d-flex align-items-center me-2"
                                                        //   onClick={() => {
                                                        //     handleShow();
                                                        //     setselectedWorkflow(
                                                        //       workflow
                                                        //     );
                                                        //   }}
                                                        // >
                                                        //   <i className="ri-git-repository-private-line me-1" />
                                                        //   {`${t("global.lock")}`}
                                                        // </button>
                                                      )}
                                                    {
                                                      // (accessRights?.includes(
                                                      //   "workflow_can_update"
                                                      // ) || isDashboard === true)
                                                      true && (
                                                        <button
                                                          className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                          onClick={(e) =>
                                                            proceedHandler(
                                                              workflow?.id,
                                                              workflow?.status,
                                                              workflow?.tabs,
                                                              e
                                                            )
                                                          }
                                                          onContextMenu={(e) =>
                                                            proceedHandler(
                                                              workflow?.id,
                                                              workflow?.status,
                                                              workflow?.tabs,
                                                              e
                                                            )
                                                          }
                                                        >
                                                          {t(
                                                            "workflow.proceed"
                                                          )}
                                                        </button>
                                                      )
                                                    }
                                                    {accessRights?.includes(
                                                      "workflow_can_delete"
                                                    ) &&
                                                      !isDashboard && (
                                                        <button
                                                          className="btn btn-sm btn-danger d-flex align-items-center"
                                                          onClick={() => {
                                                            handleShow();
                                                            setselectedWorkflow(
                                                              workflow
                                                            );
                                                          }}
                                                        >
                                                          <i className="ri-delete-bin-fill me-1" />
                                                          {`${t(
                                                            "global.delete"
                                                          )}`}
                                                        </button>
                                                      )}
                                                  </div>
                                                )}
                                              </td>
                                            )}
                                            {/* 8 actions */}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </p>
                              {havePagination && (
                                <div className="d-flex justify-content-end ">
                                  {totalPages! <= 1 ? (
                                    ""
                                  ) : (
                                    <Pagination
                                      page={page!}
                                      totalPages={totalPages!}
                                      handlePagination={handlePages!}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className="tab-pane "
                          id="product1"
                          role="tabpanel"
                        >
                          {completedList?.length === 0 ? (
                            <EmptyState />
                          ) : (
                            <>
                              <p className="mb-0">
                                <div className="table-responsive">
                                  <Table className="align-middle table-nowrap mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th scope="col">
                                          {`${t("global.id")}`}
                                        </th>
                                        <th scope="col">{`${t(
                                          "workflow.name"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "global.manager"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "global.created_at"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "workflow.deadline"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "users.status"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "workflow.content_completed"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "workflow.translation_completed"
                                        )}`}</th>
                                        <th scope="col">{`${t(
                                          "workflow.completed"
                                        )}`}</th>
                                        {(accessRights?.includes(
                                          "workflow_can_update"
                                        ) ||
                                          accessRights?.includes(
                                            "workflow_can_delete"
                                          )) && (
                                          <th scope="col">{`${t(
                                            "global.actions"
                                          )}`}</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {completedList?.map((workflow: any) => {
                                        return (
                                          <tr>
                                            <td>
                                              <span
                                                className="cursor-pointer"
                                                onContextMenu={(e) =>
                                                  proceedHandler(
                                                    workflow?.id,
                                                    workflow?.status,
                                                    workflow?.tabs,
                                                    e
                                                  )
                                                }
                                                onClick={(e) => {
                                                  // (accessRights?.includes(
                                                  //   "workflow_can_update"
                                                  // ) || isDashboard === true)
                                                  true &&
                                                    proceedHandler(
                                                      workflow?.id,
                                                      workflow?.status,
                                                      workflow?.tabs,
                                                      e
                                                    );
                                                }}
                                              >
                                                #{workflow?.id}
                                              </span>
                                            </td>
                                            {/* 1 id */}
                                            <td className="text-start">
                                              {workflow?.name}
                                            </td>
                                            {/* 2 name */}

                                            <td>
                                              {workflow?.workflowCopywriter
                                                ?.map((writer: any) =>
                                                  writer.user.firstName +
                                                  writer.user.lastName
                                                    ? writer.user.lastName
                                                    : ""
                                                )
                                                .join(", ")}
                                            </td>
                                            {/* 3 copywriter */}
                                            <td>
                                              {formatDate(workflow?.createdAt)}
                                            </td>
                                            {/* 4 createdAt */}
                                            <td>
                                              <span
                                                className={`text-${timeUntilDeadline(
                                                  formatDateWorkflowDead(
                                                    workflow?.deadlineData
                                                  )
                                                )}`}
                                              >
                                                {workflow?.deadlineData
                                                  ? formatDateWorkflowDead(
                                                      workflow?.deadlineData
                                                    )
                                                  : null}
                                              </span>
                                            </td>
                                            {/* 5 deadlineData */}
                                            <td>
                                              <span className="badge badge-outline-success badge-soft-success">
                                                {getCorrectStatus(
                                                  workflow?.status,
                                                  t
                                                )}
                                              </span>
                                            </td>
                                            {/* 6 status */}
                                            <td>
                                              {
                                                workflow?.completedWorkflowStats
                                                  ?.totalProducts
                                              }
                                            </td>
                                            <td>
                                              {
                                                workflow?.completedWorkflowStats
                                                  ?.translationData
                                              }
                                            </td>
                                            <td>
                                              {" "}
                                              {workflow?.completedAt
                                                ? formatDate(
                                                    workflow?.completedAt
                                                  )
                                                : null}
                                            </td>
                                            {/* 7 current step */}
                                            {(accessRights?.includes(
                                              "workflow_can_update"
                                            ) ||
                                              accessRights?.includes(
                                                "workflow_can_delete"
                                              )) && (
                                              <td>
                                                <div className="d-flex align-items-center justify-content-center">
                                                  {
                                                    // (accessRights?.includes(
                                                    //   "workflow_can_update"
                                                    // ) || isDashboard === true)
                                                    true && (
                                                      <button
                                                        className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                        onClick={(e) =>
                                                          proceedHandler(
                                                            workflow?.id,
                                                            workflow?.status,
                                                            workflow?.tabs,
                                                            e
                                                          )
                                                        }
                                                        onContextMenu={(e) =>
                                                          proceedHandler(
                                                            workflow?.id,
                                                            workflow?.status,
                                                            workflow?.tabs,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {t("workflow.proceed")}
                                                      </button>
                                                    )
                                                  }
                                                  {accessRights?.includes(
                                                    "workflow_can_delete"
                                                  ) &&
                                                    !isDashboard && (
                                                      <button
                                                        className="btn btn-sm btn-danger d-flex align-items-center"
                                                        onClick={() => {
                                                          handleShow();
                                                          setselectedWorkflow(
                                                            workflow
                                                          );
                                                          console.log(
                                                            "delete in progress"
                                                          );
                                                        }}
                                                      >
                                                        <i className="ri-delete-bin-fill me-1" />
                                                        {`${t(
                                                          "global.delete"
                                                        )}`}
                                                      </button>
                                                    )}
                                                </div>
                                              </td>
                                            )}
                                            {/* 8 actions */}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </p>
                              {havePagination && (
                                <div className="d-flex justify-content-end ">
                                  {totalPagesCompleted! <= 1 ? (
                                    ""
                                  ) : (
                                    <Pagination
                                      page={pageCompleted!}
                                      totalPages={totalPagesCompleted!}
                                      handlePagination={handlePagesCompleted!}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
          <ConfirmDelete
            show={show}
            setShow={setShow}
            itemName={selectedWorkflow?.name}
            deleteHandler={deleteHandler}
            product={selectedWorkflow}
            selectedProduct={selectedWorkflow}
          />
          <ConfirmUnlock
            show={showUnlock}
            setShow={setShowUnlock}
            itemName={selectedWorkflow?.name}
            deleteHandler={unlockHandler}
            product={selectedWorkflow}
            selectedProduct={selectedWorkflow}
            setForce={setForce}
            force={force}
          />
          <LockedReasonModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            currentWorkflowId={currentWorkflowId}
            setTriggerUpdate={setTriggerUpdate}
          />
        </div>
      </div>
    </>
  );
};

const MainWorkflowFilters = ({
  t,
  pagination,
  workflowStatus,
  handlePaginationStatus,
  handlePaginationFromDate,
  handlePaginationToDate,
  clientList,
  handlePaginationClient,
  completeTab,
  paginationCompleted,
  handlePaginationName,
  completeName,
  name,
}: any) => {
  return (
    <>
      <div className="row">
        <div className="col-md-3  my-2">
          <input
            type="text"
            className="form-control"
            placeholder={t("global.name")}
            onChange={(event) => {
              handlePaginationName(event);
            }}
            value={completeTab ? completeName?.value : name?.value}
          />
        </div>
        <div className="col-md-3  my-2">
          <Select
            className="react-select-field"
            isClearable={true}
            placeholder={t("products.status")}
            isDisabled={completeTab}
            theme={themeSelect}
            defaultValue={
              !completeTab
                ? pagination?.workflowStatus && {
                    label: pagination?.workflowStatus ?? null,
                    value: pagination?.workflowStatus ?? null,
                  }
                : { label: "COMPLETED", value: "COMPLETED" }
            }
            options={
              workflowStatus
                ?.filter((status: string) => status !== "COMPLETED")
                .map((status: string) => ({
                  label: status.replace("_", " "),
                  value: status,
                })) as any
            }
            onChange={(e) => {
              handlePaginationStatus(
                e as SingleValue<{ label: string; value: string }>
              );
            }}
          />
        </div>
        <div className="col-md-2 my-2">
          <Select
            className="react-select-field"
            isClearable={true}
            placeholder={t("global.client")}
            theme={themeSelect}
            defaultValue={
              !completeTab
                ? pagination?.client && {
                    label: pagination?.client?.name,
                    value: pagination?.client?.id,
                  }
                : paginationCompleted?.client && {
                    label: paginationCompleted?.client?.name,
                    value: paginationCompleted?.client?.id,
                  }
            }
            options={clientList?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            onChange={(e) => {
              handlePaginationClient(
                e as SingleValue<{ label: string; value: string }>
              );
            }}
          />
        </div>
        <div className="col-md-2 my-2">
          <div className="datepicker-container">
            <DatePicker
              id="fromDeadline"
              placeholderText="Date from"
              autoComplete="off"
              className="date-picker"
              name="fromDeadline"
              isClearable
              selected={
                !completeTab
                  ? pagination?.fromDeadline
                  : paginationCompleted?.fromDeadline
              }
              onChange={(date) => handlePaginationFromDate(date)}
            />
          </div>
        </div>
        <div className="col-md-2 my-2">
          <div className="datepicker-container">
            <DatePicker
              id="toDeadline"
              placeholderText="Date to"
              autoComplete="off"
              className="date-picker"
              name="toDeadline"
              isClearable
              selected={
                !completeTab
                  ? pagination?.toDeadline
                  : paginationCompleted?.toDeadline
              }
              onChange={(date) => handlePaginationToDate(date)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
