import React, { useEffect, useState } from "react";
import { ClientMappingService } from "../service";
import { useNavigate, useParams } from "react-router";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../../shared/paginations/Paginations";
import Select, { SingleValue } from "react-select";
import { Button, Modal } from "react-bootstrap";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { EmptyState } from "../../../shared/components/EmptyState";
import _debounce from "lodash/debounce";
import { ProductsService } from "../../products/service";
import { MappingStepper } from "./MappingStepper";
export const FieldStep = () => {
  const { id } = useParams();
  const clientId = useSelector((state: IAllStates) => state.clientId);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [attributeModal, setAttributeModal] = useState(false);
  const [idForEditMapping, setIdForEditMapping] = useState(0);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const navigate = useNavigate();
  const [sourceModalLanguage, setSourceModalLanguage] = useState("");
  const [fieldString, setFieldString] = useState("");
  const [fieldArray, setFieldArray] = useState<any[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
  });
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceModalLanguage(selectedSingle?.value as string);
  }
  const [formality, setFormality] = useState(null);
  const { t } = useTranslation();
  const [arrayCategories, setArrayCategories] = useState<any[]>([]);
  const [objectFields, setObjectFields] = useState({});
  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    const { value } = event.target;
    setCurrentCategory((prev: any) => ({
      ...prev,
      [language]: value,
    }));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceModalLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentCategory?.[sourceModalLanguage],
        sourceLanguage: sourceModalLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentCategory((prev: any) => ({
            ...prev,
            [langArr[i]]: result,
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const getCategoryById = (fieldString: string) => {
    // setIdForEditMapping(categoryId);
    setFieldString(fieldString);
    ClientMappingService.getMappingFieldById(Number(id), fieldString)
      .then((response) => {
        setCurrentCategory(response);
      })
      .catch((error) => ErrorToast(error));
  };
  const getBlockById = (blockId: number) => {
    setAttributeModal(true);
    setIdForEditMapping(blockId);
    // setFieldString(fieldString);
    ClientMappingService.getMappingBlockById(Number(id), Number(blockId))
      .then((response) => {
        setCurrentCategory(response?.mapping);
      })
      .catch((error) => ErrorToast(error));
  };
  const backNavigate = (e: any) => {
    e.preventDefault();
    navigate(`/app/clients/mapping-list/${clientId}`);
  };
  const updateCategoryById = () => {
    if (!attributeModal) {
      currentCategory &&
        ClientMappingService.updateMappingFieldById(
          Number(id),
          fieldString,
          currentCategory
        )
          .then((response) => {
            setShowForm(false);
            //@ts-ignore
            SuccessToast(`${t("toasts.successfully_updated")}`);
            //   dispatch(categorySlice.actions.setCategory(response));
            setCurrentCategory({});
            setTriggerUpdate((prev) => !prev);
            setAttributeModal(false);
          })
          .catch((error) => ErrorToast(error));
    } else {
      currentCategory &&
        ClientMappingService.updateMappingBlockById(
          Number(id),
          idForEditMapping,
          currentCategory
        )
          .then((response) => {
            setShowForm(false);
            //@ts-ignore
            SuccessToast(`${t("toasts.successfully_updated")}`);
            //   dispatch(categorySlice.actions.setCategory(response));
            setCurrentCategory({});
            setAttributeModal(false);
            setTriggerUpdate((prev) => !prev);
            setIdForEditMapping(0);
          })
          .catch((error) => ErrorToast(error));
    }
  };

  // const changeFilterHandler = _debounce(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target;
  //     setPagination({ ...pagination, page: 1, perPage: 10, termFields: value });
  //   },
  //   200
  // );
  const changeFilterHandlerBlocks = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPagination({ ...pagination, page: 1, perPage: 10, term: value });
    },
    200
  );
  useEffect(() => {
    ClientMappingService.getClientMappingFieldById(Number(id), pagination)
      .then((response) => {
        setArrayCategories(response?.blocks);
        setObjectFields(response?.fields);
        const arrFields = Object.entries(response?.fields) ?? [];
        setFieldArray(arrFields);
      })
      .catch((error) => ErrorToast(error));
  }, [id, pagination, triggerUpdate]);
  return (
    <>
      <div className="card">
        <div className="card-body">
          <MappingStepper step={"FIELD"} />
          <div className="card-header pb-0">
            <div className="card-header p-0">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mg-b-0 text-center">
                  {`${t("mapping.list_of_fields")}`}
                </h4>
                {/* <div className="d-flex justify-content-end">
                  <input
                    className="form-control mb-1"
                    name={"name"}
                    onChange={changeFilterHandler}
                    placeholder={`Search fields...`}
                    type="text"
                    maxLength={30}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      {/* <th scope="col" rowSpan={2}>
                        ID
                      </th> */}
                      {/* <th colSpan={4} rowSpan={1}>
                        Category
                      </th> */}
                      <th rowSpan={2} className="border-mapping">
                        {t("global.name")}
                      </th>
                      <th colSpan={4} rowSpan={1}>
                        {t("mapping.mapping")}
                      </th>
                      <th scope="col" rowSpan={2}>
                        {t("global.action")}
                      </th>
                    </tr>

                    <tr>
                      {/* <th>German</th>
                      <th>French</th>
                      <th>Italian</th> */}
                      <th>{t("products.content_en")}</th>
                      <th> {t("products.content_de")}</th>
                      <th> {t("products.content_fr")}</th>
                      <th>{t("products.content_it")}</th>
                    </tr>
                  </thead>
                  <tbody id="rows">
                    {fieldArray?.map((item: any) => {
                      return (
                        <tr>
                          {/* <td>{item?.id}</td> */}
                          <td className="border-mapping">
                            {t(`mapping.${item[0]}`)}
                          </td>
                          {/* <td>{item?.category?.de ?? "-"}</td>
                          <td>{item?.category?.fr ?? "-"}</td>
                          <td>{item?.category?.it ?? "-"}</td> */}
                          <td>{item[1]?.en ?? "-"}</td>
                          <td>{item[1]?.de ?? "-"}</td>
                          <td>{item[1]?.fr ?? "-"}</td>
                          <td>{item[1]?.it ?? "-"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-sm btn-warning mx-auto"
                              onClick={() => {
                                setShowForm(true);
                                getCategoryById(item[0]);
                              }}
                            >
                              <i className="ri-edit-2-fill me-1" />
                              {`${t("global.edit")}`}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Modal
            show={showForm}
            onHide={() => setShowForm(false)}
            size="xl"
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="h3 mb-0">
                  {/* {currentCategory?.hasOwnProperty("allowChildren")
                ? `${t("category.update")}`
                : `${t("category.create_new_category")}`} */}
                </p>
                <div className="d-flex">
                  <Select
                    className="react-select-field mx-2"
                    name="content"
                    theme={themeSelect}
                    placeholder={`${t("formality.select_formality")}`}
                    options={deeplFormality?.map((formality) => ({
                      value: formality,
                      label: getCorrectFormality(formality, t),
                    }))}
                    // key={state?.id}
                    onChange={(e) => {
                      handleSelectSingleFormality(e as any);
                    }}
                  />
                  <Select
                    className="custom_zindex react-select-field me-2"
                    theme={themeSelect}
                    placeholder={`${t("workflow.translate_from")}`}
                    isClearable={true}
                    options={translationLanguageEnum?.map((lang) => ({
                      value: lang,
                      label: getCorrectLanguage(lang, t),
                    }))}
                    //   key={state?.id}
                    onChange={(e) => {
                      handleSelectSingle(e as any);
                    }}
                  />
                  <button
                    className="btn btn-secondary"
                    onClick={handleTranslate}
                  >
                    {t("global.translate")}
                  </button>
                </div>
              </div>
              <div className="row">
                {translationLanguageEnum?.map((language) => (
                  <div className="col-md-6 my-1" key={language}>
                    <label htmlFor="title">
                      {t("category.enterTitle")}{" "}
                      {getCorrectLanguage(language, t)}
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={(currentCategory as any)?.[language] ?? ""}
                      onChange={(e) =>
                        changeTranslationHandler(e, language as string)
                      }
                      className="form-control"
                    />
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={() => setShowForm(false)}>
                {t("modals.close")}
              </Button>
              <Button variant="primary" onClick={() => updateCategoryById()}>
                {/* {currentCategory?.hasOwnProperty("allowChildren")
              ? `${t("global.update")}`
              : `${t("category.save")}`} */}
                {t("category.save")}
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex align-items-center justify-content-start">
            <button
              className="btn btn-info me-3 d-flex align-items-center"
              onClick={(e) => backNavigate(e)}
            >
              <i className="ri-arrow-left-line me-1" />
              {t("global.cancel")}
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="card-title mg-b-0 text-center">
                {t("block.list_of_blocks")}
              </h4>
              <div className="d-flex justify-content-end">
                <input
                  className="form-control mb-1"
                  name={"name"}
                  onChange={changeFilterHandlerBlocks}
                  placeholder={t("mapping.search_blocks")}
                  type="text"
                  maxLength={30}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                {arrayCategories?.length > 0 ? (
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col" className="border-mapping" rowSpan={2}>
                          {t("global.id")}
                        </th>
                        <th colSpan={4} className="border-mapping" rowSpan={1}>
                          {t("side_bar.block")}
                        </th>
                        <th colSpan={4} rowSpan={1}>
                          {t("mapping.mapping")}
                        </th>
                        <th scope="col" rowSpan={2}>
                          {t("global.action")}
                        </th>
                      </tr>

                      <tr>
                        {/* {translationLanguageEnum?.map((langauge: string) => {
                      <th className="border-mapping">{langauge}</th>
                     } )} */}
                        <th className="border-mapping">
                          {t("products.content_en")}
                        </th>
                        <th className="border-mapping">
                          {t("products.content_de")}
                        </th>
                        <th className="border-mapping">
                          {t("products.content_fr")}
                        </th>
                        <th className="border-mapping">
                          {t("products.content_it")}
                        </th>
                        <th>{t("products.content_en")}</th>
                        <th> {t("products.content_de")}</th>
                        <th> {t("products.content_fr")}</th>
                        <th>{t("products.content_it")}</th>
                      </tr>
                    </thead>
                    <tbody id="rows">
                      {arrayCategories?.map((item: any) => {
                        return (
                          <tr>
                            <td className="border-mapping">{item?.id}</td>
                            <td className="border-mapping">
                              {item?.block?.en ?? "-"}
                            </td>
                            <td className="border-mapping">
                              {item?.block?.de ?? "-"}
                            </td>
                            <td className="border-mapping">
                              {item?.block?.fr ?? "-"}
                            </td>
                            <td className="border-mapping">
                              {item?.block?.it ?? "-"}
                            </td>
                            <td>{item?.mapping?.en ?? "-"}</td>
                            <td>{item?.mapping?.de ?? "-"}</td>
                            <td>{item?.mapping?.fr ?? "-"}</td>
                            <td>{item?.mapping?.it ?? "-"}</td>
                            <td>
                              {" "}
                              <button
                                className="btn btn-sm btn-warning mx-auto"
                                onClick={() => {
                                  setShowForm(true);
                                  getBlockById(item?.id as number);
                                }}
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <EmptyState />
                )}
              </div>
            </div>
          </div>
          <Modal
            show={showForm}
            onHide={() => setShowForm(false)}
            size="xl"
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="h3 mb-0">
                  {/* {currentCategory?.hasOwnProperty("allowChildren")
                ? `${t("category.update")}`
                : `${t("category.create_new_category")}`} */}
                </p>
                <div className="d-flex">
                  <Select
                    className="react-select-field mx-2"
                    name="content"
                    theme={themeSelect}
                    placeholder={`${t("formality.select_formality")}`}
                    options={deeplFormality?.map((formality) => ({
                      value: formality,
                      label: getCorrectFormality(formality, t),
                    }))}
                    // key={state?.id}
                    onChange={(e) => {
                      handleSelectSingleFormality(e as any);
                    }}
                  />
                  <Select
                    className="custom_zindex react-select-field me-2"
                    theme={themeSelect}
                    placeholder={`${t("workflow.translate_from")}`}
                    isClearable={true}
                    options={translationLanguageEnum?.map((lang) => ({
                      value: lang,
                      label: getCorrectLanguage(lang, t),
                    }))}
                    //   key={state?.id}
                    onChange={(e) => {
                      handleSelectSingle(e as any);
                    }}
                  />
                  <button
                    className="btn btn-secondary"
                    onClick={handleTranslate}
                  >
                    {t("global.translate")}
                  </button>
                </div>
              </div>
              <div className="row">
                {translationLanguageEnum?.map((language) => (
                  <div className="col-md-6 my-1" key={language}>
                    <label htmlFor="title">
                      {t("category.enterTitle")}{" "}
                      {getCorrectLanguage(language, t)}
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={(currentCategory as any)?.[language] ?? ""}
                      onChange={(e) =>
                        changeTranslationHandler(e, language as string)
                      }
                      className="form-control"
                    />
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={() => setShowForm(false)}>
                {t("modals.close")}
              </Button>
              <Button variant="primary" onClick={() => updateCategoryById()}>
                {/* {currentCategory?.hasOwnProperty("allowChildren")
              ? `${t("global.update")}`
              : `${t("category.save")}`} */}
                {t("category.save")}
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex align-items-center justify-content-start">
            <button
              className="btn btn-info me-3 d-flex align-items-center"
              onClick={(e) => backNavigate(e)}
            >
              <i className="ri-arrow-left-line me-1" />
              {t("global.cancel")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
