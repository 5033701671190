import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { IBrand } from "./dto/IBrand";
import { BrandService } from "./service";
import { Form } from "react-bootstrap";
import { BrandForm } from "./components/BrandForm";
import _debounce from "lodash/debounce";
import { BrandList } from "./components/BrandList";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";

const initialState = {
  name: "",
};

export const Brand = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [brandsList, setBrandsList] = useState<IBrand[] | undefined>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<IBrand | undefined>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination({
        page: 1,
        perPage: 10,
        [name]: value,
      });
    },
    200
  );

  const closeHandler = () => {
    setIsMenuOpened(false);
    setCurrentBrand(initialState as IBrand);
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentBrand?.id) {
      BrandService.updateBrandById(currentBrand?.id, currentBrand)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")} ${response?.name}`);
          setCurrentBrand(initialState as IBrand);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      BrandService.createBrand(currentBrand as IBrand)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")} ${response?.name}`);
          setCurrentBrand(initialState as IBrand);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const editHandler = (brandId: number) => {
    BrandService.getBrandById(brandId)
      .then((response) => {
        setCurrentBrand(response);
        setIsMenuOpened(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    BrandService.getAllBrands(pagination)
      .then((response) => {
        const { data } = response;
        if (pagination?.page > 1 && pagination?.page > response?.totalPages) {
          setPage(response?.totalPages);
          setPagination({ ...pagination, page: response?.totalPages });
        }
        setBrandsList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, isMenuOpened]);
  return (
    <>
      <div className="col-xl-12 mb-3">
        {accessRights?.includes("brand_can_create") && !isMenuOpened && (
          <div className="col-12">
            <div className="card">
              <div className="card-body d-flex align-items-center justify-content-between">
                <h4 className="card-title mg-b-0">
                  {t("brand.create_new_brand")}
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setIsMenuOpened(true)}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        )}
        {isMenuOpened && (
          <Form onSubmit={submitHandler}>
            <BrandForm
              state={currentBrand}
              setState={setCurrentBrand}
              closeHandler={closeHandler}
            />
          </Form>
        )}
      </div>
      <div className="col-xl-12">
        <BrandList
          accessRights={accessRights}
          brandsList={brandsList}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
          changeFilterHandler={changeFilterHandler}
          state={currentBrand}
          setState={setCurrentBrand}
        />
      </div>
    </>
  );
};
