import moment from "moment";

export const formatDate = (date: string) => {
  return moment(date).format("DD.MM.YYYY - HH:mm");
};
export const formatDateWorkflow = (date: string) => {
  return moment(date).format("DD.MM.YYYY");
};
export const formatDateWorkflowDead = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};
export const correctFormatTable = (date: any) => {
  if (date) {
    const datum = new Date(date);
    return datum.toLocaleDateString("sr-RS");
  } else {
    return null;
  }
};
export function clearHtmlFromString(html: any) {
  return (
    new DOMParser()
      .parseFromString(html, "text/html")
      .body.textContent?.trim() || ""
  );
}

export const formatRole = (role: string) => {
  // if(role?.includes('ROLE_')){
  return role?.trim().replace("ROLE_", " ").replace("_", " ");
  // }else{
  //     return role
  // }
};

export const formatRoles = (roles: string[]) => {
  const formatter = new (Intl as any).ListFormat("en", {
    style: "long",
    type: "conjunction",
  });
  const clearedArray: string[] = [];
  roles?.forEach((role) => {
    clearedArray.push(formatRole(role).replace("_", " ").trim());
  });
  return formatter.format(clearedArray);
};

export const getCorrectLanguage = (language: string, t: any) => {
  switch (language) {
    case "de":
      return `${t("languages.de")}`;
    case "fr":
      return `${t("languages.fr")}`;
    case "en":
      return `${t("languages.en")}`;
    case "it":
      return `${t("languages.it")}`;
    case "at":
      return `${t("languages.at")}`;
    case "ch":
      return `${t("languages.ch")}`;
    default:
      return `NULL - ${language}`;
  }
};
export const getCorrectField = (field: string, t: any) => {
  switch (field) {
    case "articleNumber":
      return `${t("products.articleNumber")}`;
    case "ean":
      return `${t("products.eanCode")}`;
    case "sapId":
      return `${t("products.sapId")}`;
    case "photo":
      return `${t("products.photo")}`;
    case "video":
      return `${t("products.video")}`;
    case "link":
      return `${t("products.link")}`;
    case "sourceUrl":
      return `${t("products.sourceUrl")}`;
    case "pharmaCode":
      return `${t("products.pharmaCode")}`;
    case "subtitle":
      return `${t("mapping.subtitle")}`;
    case "title":
      return `${t("global.title")}`;
    default:
      return `NULL - ${field}`;
  }
};
export const getCorrectFieldContent = (field: string, t: any) => {
  switch (field) {
    case "intro":
      return `${t("mapping.intro")}`;
    case "bullets":
      return `${t("mapping.bullets")}`;
    case "description":
      return `${t("mapping.description")}`;
    default:
      return `NULL - ${field}`;
  }
};
export const getCorrectStatusWorkflow = (status: string, t: any) => {
  switch (status) {
    case "DONE":
      return `${t(`status_workflow.${status}`)}`;
    case "IN_PROGRESS":
      return `${t(`status_workflow.${status}`)}`;
    case "PENDING":
      return `${t(`status_workflow.${status}`)}`;
    default:
      return `NULL - ${status}`;
  }
};
export const getCorrectFormality = (formality: string, t: any) => {
  switch (formality) {
    case "default":
      return `${t("formality.default")}`;
    case "prefer_more":
      return `${t("formality.prefer_more")}`;
    case "prefer_less":
      return `${t("formality.prefer_less")}`;
    default:
      return `NULL - ${formality}`;
  }
};
export const getCorrectStatus = (status: string, t: any) => {
  switch (status) {
    case "SETTING_UP":
      return t(`content_workflow.${status}`);
    case "BASE":
      return t(`content_workflow.${status}`);
    case "BASE_DATA":
      return t(`content_workflow.${status}`);
    case "ATTRIBUTES":
      return t(`content_workflow.${status}`);
    case "COPY_WRITING":
      return t(`content_workflow.${status}`);
    case "TRANSLATION":
      return t(`content_workflow.${status}`);
    case "OVERVIEW":
      return t(`content_workflow.${status}`);
    case "EXPORT":
      return t(`content_workflow.${status}`);
    default:
      return t(`content_workflow.${status}`);
  }
};

export const getWebsiteTranslation = (name: string, t: any) => {
  switch (name) {
    case "digitec_ch":
      return t(`websiteEnum.${name}`);
    case "microspot_ch":
      return t(`websiteEnum.${name}`);
    default:
      return `NULL - ${name}`;
  }
};
