import { ICategoryCreate, ICategoryDTO } from "../dto/ICategory";
import React, { SetStateAction, useState } from "react";
import { CategoryService } from "../../service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { Button, Modal } from "react-bootstrap";
import categorySlice from "../../../../store/category/categoryRedux";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { useNavigate } from "react-router";
import parentCategoryIdSlice from "../../../../store/category/parentCategoryIdRedux";
import Select, { MultiValue, SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { ProductsService } from "../../../products/service";

interface ICategoryListProps {
  categoryList: ICategoryDTO[];
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  currentCategory: ICategoryCreate | undefined;
  setCurrentCategory: React.Dispatch<
    SetStateAction<ICategoryCreate | undefined>
  >;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeTranslationHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => void;
  initialState: ICategoryCreate;
}

export const CategoryList = ({
  categoryList,
  setTriggerUpdate,
  currentCategory,
  setCurrentCategory,
  changeHandler,
  changeTranslationHandler,
  initialState,
}: ICategoryListProps) => {
  const [selectedCategory, setSelectedCategory] = useState<ICategoryDTO>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const [selectedId, setSelectedId] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [sourceLanguage, setSourceLanguage] = useState("");

  const handleClose = () => {
    setShowForm(false);
    setCurrentCategory(undefined);
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }

  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentCategory?.translation?.[sourceLanguage]?.title,
        sourceLanguage: sourceLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentCategory((prev: any) => ({
            ...prev,
            translation: {
              ...prev?.translation,
              [langArr[i]]: {
                ...prev?.translation?.[langArr[i]],
                title: result,
              },
            },
            parent: 0,
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }
  const handleSelectCategory = (categoryId: number, action: string) => {
    if (action === "delete") {
      deleteCategoryHandler(categoryId);
    } else if (action === "add") {
      setSelectedId(categoryId);
      setShowForm(true);
    } else {
      setShowForm(true);
      getCategoryById(categoryId);
    }
  };

  const getCategoryById = (categoryId: number) => {
    CategoryService.getCategoryById(categoryId)
      .then((response) => {
        setCurrentCategory(response);
      })
      .catch((error) => ErrorToast(error));
  };

  const updateCategoryById = () => {
    currentCategory &&
      CategoryService.updateCategoryById({
        ...currentCategory,
        title: currentCategory?.title
          ? currentCategory?.title
          : (currentCategory as any)?.category?.title,
      })
        .then((response) => {
          setShowForm(false);
          //@ts-ignore
          SuccessToast(
            `${t("toasts.successfully_updated")} ${
              currentCategory?.translation?.en
            }`
          );
          dispatch(categorySlice.actions.setCategory(response));
          setCurrentCategory(initialState);
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
  };

  const addCategoryHandler = () => {
    CategoryService.addCategory({
      ...(currentCategory as any),
      parent: selectedId,
    })
      .then((response) => {
        //@ts-ignore
        dispatch(categorySlice.actions.setCategory(response));
        SuccessToast(
          `${t("toasts.successfully_created")} ${
            currentCategory?.translation?.en?.title
          }`
        );
        setCurrentCategory(initialState);
        setShowForm(false);
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) => ErrorToast(error));
  };

  const deleteCategoryHandler = (categoryId: number) => {
    CategoryService.deleteCategoryById(categoryId)
      .then((response) => {
        setTriggerUpdate((prev) => !prev);
        dispatch(categorySlice.actions.setCategory(response));
        SuccessToast(`${t("toasts.successfully_deleted")} ${categoryId}`);
      })
      .catch((error) => ErrorToast(error));
  };

  const handleSubmit = () => {
    if ((currentCategory as any)?.category?.id) {
      updateCategoryById();
    } else {
      addCategoryHandler();
    }
  };

  const handleEdit = (id: Number) => {
    if (id) {
      navigate(`/admin/category/values/edit/${id}`);
    }
  };
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const handleCreate = (parentId: Number) => {
    dispatch(parentCategoryIdSlice.actions.setParentCategoryId(parentId));
    navigate(`/admin/category/values/create`);
  };
  return (
    <>
      <Modal show={showForm} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="h3 mb-0">
              {currentCategory?.hasOwnProperty("allowChildren")
                ? `${t("category.update")}`
                : `${t("category.create_new_category")}`}
            </p>
            <div className="d-flex">
              <Select
                className="react-select-field mx-2"
                name="content"
                theme={themeSelect}
                placeholder={`${t("formality.select_formality")}`}
                options={deeplFormality?.map((formality) => ({
                  value: formality,
                  label: getCorrectFormality(formality, t),
                }))}
                // key={state?.id}
                onChange={(e) => {
                  handleSelectSingleFormality(e as any);
                }}
              />
              <Select
                className="custom_zindex react-select-field me-2"
                theme={themeSelect}
                placeholder={`${t("workflow.translate_from")}`}
                isClearable={true}
                options={translationLanguageEnum?.map((lang) => ({
                  value: lang,
                  label: getCorrectLanguage(lang, t),
                }))}
                //   key={state?.id}
                onChange={(e) => {
                  handleSelectSingle(e as any);
                }}
              />
              <button className="btn btn-secondary" onClick={handleTranslate}>
                {t("global.translate")}
              </button>
            </div>
          </div>
          <div className="row">
            {translationLanguageEnum?.map((language) => (
              <div className="col-md-6 my-1" key={language}>
                <label htmlFor="title">
                  {t("category.enterTitle")} {getCorrectLanguage(language, t)}
                </label>
                <input
                  type="text"
                  name="title"
                  value={
                    (currentCategory as any)?.translation?.[language]?.title ??
                    ""
                  }
                  onChange={(e) =>
                    changeTranslationHandler(e, language as string)
                  }
                  className="form-control"
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            {t("modals.close")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {currentCategory?.hasOwnProperty("allowChildren")
              ? `${t("global.update")}`
              : `${t("category.save")}`}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="list-group col nested-list nested-sortable">
        {!!categoryList?.length ? (
          categoryList?.map((category1) => {
            return (
              <div key={category1?.id} className="list-group-item nested-1">
                <div className="d-flex align-items-center justify-content-between fw-bolder">
                  <span
                    onClick={() => handleSelectCategory(category1?.id, "edit")}
                    className="text-dark cursor-pointer"
                  >
                    {category1?.title ?? "-"}
                  </span>
                  <div className="text-center align-center">
                    <button
                      className="btn btn-sm btn-soft-warning mb-1"
                      onClick={() =>
                        handleSelectCategory(category1?.id, "edit")
                      }
                    >
                      <i className="ri-edit-2-fill me-1" />
                      {t("global.edit")}
                    </button>
                    <button
                      className="btn btn-sm btn-soft-danger mb-1 mx-1"
                      onClick={() => {
                        setSelectedCategory(category1 as any);
                        handleShow();
                      }}
                    >
                      <i className="ri-delete-bin-fill me-1" />
                      {t("global.delete")}
                    </button>
                    <button
                      className="btn btn-sm btn-soft-success mb-1"
                      onClick={() => handleSelectCategory(category1?.id, "add")}
                    >
                      <i className="ri-add-circle-fill me-1" />
                      {t("global.add")}
                    </button>
                  </div>
                </div>
                {!!category1?.children?.length && (
                  <div className="list-group nested-list nested-sortable">
                    {category1?.children?.map((category2) => {
                      return (
                        <div
                          className="list-group-item nested-2"
                          key={category2?.id}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <span
                              onClick={() =>
                                handleSelectCategory(category2?.id, "edit")
                              }
                              className="text-dark cursor-pointer"
                            >
                              {category2?.title ?? "-"}
                            </span>
                            <div className="text-center align-center">
                              <button
                                className="btn btn-sm btn-soft-warning mb-1"
                                onClick={() =>
                                  handleSelectCategory(category2?.id, "edit")
                                }
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {t("global.edit")}
                              </button>
                              <button
                                className="btn btn-sm btn-soft-danger mb-1 mx-1"
                                onClick={() => {
                                  setSelectedCategory(category2 as any);
                                  handleShow();
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {t("global.delete")}
                              </button>
                              <button
                                className="btn btn-sm btn-soft-success mb-1"
                                onClick={() => handleCreate(category2?.id)}
                              >
                                <i className="ri-add-circle-fill me-1" />
                                {t("global.add")}
                              </button>
                            </div>
                          </div>

                          {!!category2?.children?.length &&
                            category2?.children?.map((category3) => {
                              return (
                                <div
                                  className="list-group nested-list nested-sortable"
                                  key={category3?.id}
                                >
                                  <div className="list-group-item nested-3 d-flex align-items-center justify-content-between fw-light">
                                    <span
                                      onClick={() =>
                                        handleEdit(category3?.id as number)
                                      }
                                      className="text-dark cursor-pointer"
                                    >
                                      {category3?.title ?? "-"}
                                    </span>
                                    <div className="text-center align-center">
                                      <button
                                        className="btn btn-sm btn-soft-warning mx-1 my-1"
                                        onClick={() =>
                                          handleEdit(category3?.id as number)
                                        }
                                      >
                                        <i className="ri-edit-2-fill me-1" />
                                        {t("global.edit")}
                                      </button>
                                      <button
                                        className="btn btn-sm btn-soft-danger mx-1 my-1"
                                        onClick={() => {
                                          setSelectedCategory(category3 as any);
                                          handleShow();
                                        }}
                                      >
                                        <i className="ri-delete-bin-fill  me-1" />
                                        {t("global.delete")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <EmptyState />
        )}
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedCategory?.title}
          deleteHandler={() =>
            handleSelectCategory(selectedCategory?.id as number, "delete")
          }
          selectedProduct={selectedCategory}
        />
      </div>
    </>
  );
};
