import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { IChatGptPrompt } from "../dto/IChatGptPrompt";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";

interface IExportFormProps {
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  state: IChatGptPrompt | undefined;
  setState: React.Dispatch<SetStateAction<IChatGptPrompt | undefined>>;
  // clientList: IClient[];
  // changeClientHandler: (value: string) => void;
}

export const PromptCreate = ({
  setIsMenuOpened,
  state,
  setState,
}: IExportFormProps) => {
  const { t } = useTranslation();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  function handleSelectSingle(
    selectedSingle: SingleValue<IChatGptPrompt | null>
  ) {
    setState((prev: any) => ({
      ...prev,
      lang: selectedSingle?.value as string,
    }));
  }
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {`${t("global.title")}`}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={state?.title ?? ""}
              required
              onChange={(e) => changeHandler(e as any)}
            />
          </Col>
          <Col md={4} className="mb-2">
            <label htmlFor="client" className="required-field">
              {`${t("global.language")}`}
            </label>
            <Select
              className="react-select-field"
              theme={themeSelect}
              key={state?.id}
              defaultValue={
                state?.lang && {
                  label: getCorrectLanguage(state?.lang, t),
                  value: state?.lang,
                }
              }
              onChange={(e) => {
                handleSelectSingle(e as any);
              }}
              // isMulti={true}
              options={(translationLanguageEnum as any)?.map(
                (language: string) => ({
                  value: language,
                  label: getCorrectLanguage(language, t),
                })
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <label htmlFor="text" className="required-field">
              {`${t("chatGpt.text")}`}
            </label>
            <textarea
              name="text"
              onChange={(e) => changeHandler(e as any)}
              value={state?.text ?? ""}
              className="w-100 dark-text-area"
              placeholder={`${t("mapping.type")}...`}
              rows={4}
            />
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
